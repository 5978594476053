import { CancelTokenSource } from "axios";

import * as base from "./base";
import CreateProjectResponse from "./entities/CreateProjectResponse";

import ViewProjectResponse from "./entities/ViewProjectResponse";
import ViewProjectItem from "./entities/ViewProjectItem";
import ProjectDetailsResponse from "./entities/ProjectDetailsResponse";
import DownloadedFilePathResponse from "./entities/DownloadedFilePathResponse";
import ProjectDetails from "./entities/ProjectDetails";
import toDate from "src/utils/toDate";
import RoleDropdownResponse from "./entities/roles/RoleDropdownResponse";
import ViewRolesResponse from "./entities/roles/ViewRolesResponse";
import ViewRoleTableItem from "./entities/roles/ViewRoleTableItem";
import ViewRoleWithDetailsTableItem from "./entities/roles/ViewRoleWithDetailsTableItem";
import AgencySearchResponse from "./entities/AgencySearchResponse";
import Agency from "./entities/Agency";
import Agent from "./entities/Agent";
import AgencyListsResponse from "./entities/AgencyListsResponse";
import RoleAgenciesResponse from "./entities/roles/RoleAgenciesResponse";
import RoleDetails from "./entities/roles/RoleDetails";
import RoleFile from "./entities/roles/RoleFile";
import RoleAgency from "./entities/roles/RoleAgency";
import PublishCastingBriefsResponse from "./entities/roles/PublishCastingBriefsResponse";
import UserDetailsResponse from "./entities/UserDetailsResponse";
import { ColleagueConfirmation } from "src/machines/registerCastingDirectorMachine";
import AdminApprovalsResponse from "./entities/admin/AdminApprovalsResponse";
import InviteAgencyArtistsResponse from "./entities/InviteAgencyArtistsResponse";
import SubmissionsResponse from "./entities/SubmissionsResponse";
import SubmissionUpdate from "./entities/roles/SubmissionUpdate";
import CastingDirectorOrAgentAccountDetails from "./entities/CastingDirectorOrAgentAccountDetails";
import { ChangePassword } from "src/machines/accountDetailsMachine";
import TeamMembersResponse from "./entities/TeamMembersResponse";
import CastingDirectorOrAgentCompanyDetails from "./entities/CastingDirectorOrAgentCompanyDetails";
import ArtistPersonalDetails from "./entities/ArtistPersonalDetails";
import toTeamMemberItem from "src/machines/createProjectMachine/utils/toTeamMemberItem";
import AgencyRepresentationResponse from "./entities/AgencyRepresentationResponse";
import PaymentStatus from "./entities/PaymentStatus";
import ArtistProfile from "./entities/ArtistProfile";
import Biography from "./entities/artistProfile/Biography";
import Credit from "./entities/artistProfile/Credit";
import MaterialVideo from "./entities/artistProfile/MaterialVideo";
import Training from "./entities/artistProfile/training";
import Award from "./entities/artistProfile/Award";
import Skill from "./entities/artistProfile/Skill";
import SecondaryAgency from "./entities/artistProfile/SecondaryAgency";
import DownloadedFile from "./entities/DownloadedFile";
import DocumentType from "./entities/artistProfile/DocumentType";
import CastingDirectorCompany from "./entities/admin/CastingDirectorCompany";
import MaterialVideoThumbnails from "./entities/artistProfile/MaterialVideoThumbnails";
import AgencyRosterProfile from "./entities/AgencyRosterProfile";
import ViewCastingBriefItem from "./entities/ViewCastingBriefItem";
import { ForgotPasswordProps } from "src/validations/forgotPassword";
import { ResetPasswordProps } from "src/validations/resetPassword";
import CastingBriefProjectDetails from "./entities/castingBriefs/CastingBriefProjectDetails";
import CastingBriefRoleItem from "./entities/castingBriefs/CastingBriefRoleItem";
import ArtistProfileImage from "./entities/artistProfile/ArtistProfileImage";
import DirectoryArtistProfile, {
  DirectoryArtistGridDetails,
} from "./entities/DirectoryArtistProfile";
import { ArtistFilters } from "src/components/ArtistFilter";
import CastingBriefRoleDetails from "./entities/castingBriefs/CastingBriefRoleDetails";
import ArtistCastingBriefProfile from "./entities/castingBriefs/ArtistCastingBriefProfile";
import DirectoryAgencyProfile, {
  AgentProfile,
} from "./entities/DirectoryAgencyProfile";
import { AgencyFilters } from "src/components/AgencyFilter";
import AgencyBasicDetails from "./entities/AgencyBasicDetails";
import { CastingOfficeFilters } from "src/components/CastingOfficeFilter";
import CastingOfficeBasicInfo from "./entities/CastingOfficeBasicInfo";
import CastingOfficeDetails, {
  CastingMemberProfile,
} from "./entities/CastingOfficeDetails";
import ProjectSelectedArtist from "./entities/sessions/ProjectSelectedArtist";
import MessagingUser from "./entities/MessagingUser";
import AgencyLists from "./entities/lists/AgencyLists";
import ArtistLists from "./entities/lists/ArtistLists";
import NewListResponse from "./entities/lists/NewListsResponse";
import AgencyListEntry from "./entities/lists/AgencyListEntry";
import ArtistListEntry from "./entities/lists/ArtistListEntry";
import ProjectSession from "./entities/sessions/ProjectSession";
import StatusReportItem from "./entities/statusReports/StatusReportItem";
import StatusReportDetails from "./entities/statusReports/StatusReportDetails";
import StatusReportSession from "./entities/statusReports/StatusReportSession";
import ArtistListDetails from "./entities/lists/ArtistListDetails";
import DeleteListResponse from "./entities/lists/DeleteListResponse";
import MessageListResponse from "./entities/lists/MessageListResponse";
import DuplicateListResponse from "./entities/lists/DuplicateListResponse";
import ListResponse from "./entities/lists/ListResponse";
import ListArtistProfile from "./entities/lists/ListArtistProfile";
import NonAltaiArtistRequest from "./entities/lists/NonAltaiArtistRequest";
import NonAltaiArtistDetails, {
  NonAltaiArtistContactsItem,
} from "./entities/lists/NonAltaiArtistDetails";
import AgencyListDetails from "./entities/lists/AgencyListDetails";
import ListAgencyProfile from "./entities/lists/ListAgencyProfile";
import DownloadMaterialResponse from "./entities/artistProfile/DownloadMaterialResponse";
import { UserType } from "src/machines/appMachine";
import StatusReportBadgeCounts from "./entities/statusReports/StatusReportBadgeCounts";
import StatusReportDocument from "./entities/statusReports/StatusReportDocument";
import ApiResponse from "./entities/ApiResponse";
import MessageAgents from "./entities/MessageAgents";
import ProjectSessionDetails from "./entities/sessions/ProjectSessionDetails";
import SessionTimelineItem from "./entities/sessions/SessionTimelineItem";
import StatusReportWardrobeDetails from "./entities/statusReports/StatusReportWardrobeDetails";
import WardrobeLatestSubmissionInfo from "./entities/wardrobeForms/WardrobeLatestSubmissionInfo";
import PricingTableConfig from "./entities/Subscription/PricingTableConfig";
import CastingOfficeCalendar from "./entities/calendar/CastingOfficeCalendar";
import AgencyCalendar from "./entities/calendar/AgencyCalendar";
import CompletedWardrobeFormOverview from "./entities/statusReports/CompletedWardrobeFormOverview";
import CompletedWardrobeFormDetails from "./entities/statusReports/CompletedWardrobeFormDetails";
import CompletedWardrobeQuestion from "./entities/statusReports/CompletedWardrobeQuestion";
import UploadedWardrobeFormFile from "./entities/statusReports/UploadedWardrobeFormFile";
import ArtistCalendar from "./entities/calendar/ArtistCalendar";
import SessionTimeSlotDetails from "./entities/sessions/SessionTimeSlotDetails";
import SessionStatus from "./entities/sessions/SessionStatus";
import SelfTapeArtistProfile from "./entities/sessions/SelfTapeArtistProfile";
import SelfTapeRequestStatus from "./entities/sessions/SelfTapeRequestStatus";
import SelfTapeRequest from "./entities/sessions/SelfTapeRequest";
import SelfTapeRequestDetails from "./entities/sessions/SelfTapeRequestDetails";
import SelfTapeRequestArtistDetails, {
  SelfTapeArtistSession,
} from "./entities/sessions/SelfTapeRequestArtistDetails";
import SelfTapeRequestBasicDetails from "./entities/sessions/SelfTapeRequestBasicDetails";
import ChannelInfo from "./entities/channels/ChannelInfo";
import ChannelRequest from "./entities/channels/ChannelRequest";
import ProjectBasicDetails from "./entities/channels/ProjectBasicDetails";
import ChannelListArtistCommentRequest from "./entities/channels/ChannelListArtistCommentRequest";
import ChannelArtistList from "./entities/channels/ChannelArtistList";
import ChannelArtistListDetails from "./entities/channels/ChannelArtistListDetails";
import ChannelListArtist from "./entities/channels/ChannelListArtist";
import ChannelListArtistComment from "./entities/channels/ChannelListArtistComment";
import ChannelSessionInfo from "./entities/channels/ChannelSessionInfo";
import AgencyUserHomePageDetails from "./entities/homePage/AgencyUserHomePageDetails";
import ArtistHomePageDetails from "./entities/homePage/ArtistHomePageDetails";
import CdHomePageDetails from "./entities/homePage/CdHomePageDetails";
import {
  StatusReportAuditionStatus,
  StatusReportThumbnailsRow,
  TapeProfileImages,
} from "src/machines/statusReportMachine/context";
import TapeResponse from "./entities/statusReports/TapeResponse";
import RecordingUser from "./entities/sessions/RecordingUser";
import UploadVideoResponse from "./entities/statusReports/UploadVideoResponse";
import SelfTapeClipDetails from "./entities/statusReports/SelfTapeClipDetails";
import SelfTapeClipReorder from "./entities/statusReports/SelfTapeClipReorder";
import SelfTapeClipStatus from "./entities/statusReports/SelfTapeClipStatus";
import { CommentItem } from "src/machines/statusReportTapesMachine/context";
import RoleStatusRequestType from "./entities/statusReports/RoleStatusRequestType";
import RoleStatusUpdateResponse from "./entities/statusReports/RoleStatusUpdateResponse";
import UploadedFile from "./entities/UploadedFile";
import ChannelTapeFolderRequest from "./entities/channels/ChannelTapeFolderRequest";
import AddTapesToFolderRequest from "./entities/channels/AddTapesToFolderRequest";
import ChannelFolderInfo from "./entities/channels/ChannelFolderInfo";
import ChannelFolderDetail from "./entities/channels/ChannelFolderDetail";
import ChannelBasicInfo from "./entities/channels/ChannelBasicInfo";
import StatusReportManageStatusRole from "./entities/statusReports/StatusReportManageStatusRole";
import StatusReportProfileArtist from "./entities/statusReports/StatusReportProfileArtist";
import AuditionMessageHistory from "./entities/statusReports/AuditionMessageHistory";
import AuditionMessageHistoryGroup from "./entities/statusReports/AuditionMessageHistoryGroup";
import TapeClipReorder from "./entities/statusReports/TapeClipReorder";
import RoleWardrobeForm from "./entities/roles/RoleWardrobeForm";
import WardrobeForm from "./entities/roles/WardrobeForm";
import HideArtistInChannelRequest from "./entities/channels/HideArtistInChannelRequest";
import ManualTapeCollectionDetails from "./entities/statusReports/ManualTapeCollectionDetails";
import toAutocompleteItem from "src/utils/toAutocompleteItem";
import ProjectArtist from "./entities/statusReports/ProjectArtist";
import RoleStatusDetails from "./entities/statusReports/RoleStatusDetails";
import RoleAndArtistToRelease from "./entities/RoleAndArtistToRelease";
import RegistrationDataTableItem from "./entities/RegistrationDataTableItem";
import EmailEveryoneRequest from "./entities/admin/EmailEveryoneRequest";
import AgencyInvitationTableItem from "./entities/AgencyInvitationTableItem";
import toAutocompleteItemPreserved from "src/utils/toAutocompleteItemPreserved";
import BulkAgencyListEntry from "./entities/lists/BulkAgencyListEntry";
import MessageAgencyRequest from "./entities/MessageAgencyRequest";
import WardrobeRoleDetail from "./entities/channels/WardrobeRoleDetail";
import WardrobeSubmissionDetail from "./entities/channels/WardrobeSubmissionDetail";
import WardrobeFormFilterOptions from "./entities/channels/WardrobeFormFilterOptions";
import { ChannelWardrobeFilters } from "src/components/ChannelWardrobeFilter";
import WardrobeBulkActionResult from "./entities/channels/WardrobeBulkActionResult";
import WardrobeToPdfDetails from "./entities/wardrobeForms/WardrobeToPdfDetails";
import ManualArtistEditResponse from "./entities/statusReports/ManualArtistEditResponse";
import CommunityCastingBriefRequest from "./entities/ccBrief/CommunityCastingBriefRequest";
import ProjectCcBriefInfo from "./entities/ccBrief/ProjectCcBriefInfo";
import CcBriefFormDetails from "./entities/ccBrief/CcBriefFormDetails";
import CcBriefFormQuestion from "./entities/ccBrief/CcBriefFormQuestion";
import CcBriefFormFile from "./entities/ccBrief/CcBriefFormFile";
import EditCcBriefFormRequest from "./entities/ccBrief/EditCcBriefFormRequest";
import CcBriefProjectAndRoleDetails from "./entities/ccBrief/CcBriefProjectAndRoleDetails";
import CcBriefFormSubmissionRequest from "./entities/ccBrief/CcBriefFormSubmissionRequest";
import CcBriefSubmissionNote from "./entities/ccBrief/CcBriefSubmissionNote";
import CcBriefSubmissionDetail from "./entities/ccBrief/CcBriefSubmissionDetail";
import ClipDownloadDetails from "./entities/statusReports/ClipDownloadDetails";
import CcBriefSubmissionPdfDetail from "./entities/ccBrief/CcBriefSubmissionPdfDetail";
import {
  WaitingRoomItem,
  WaitingRoomUser,
} from "src/components/WaitingRoomDropDownItem";
import StatusReportTapeVideoDetails from "./entities/sessions/StatusReportTapeVideoDetails";
import ChannelFolderNames from "./entities/channels/ChannelFolderNames";
import CastingBriefRoleFiles from "./entities/castingBriefs/CastingBriefRoleFiles";
import CastingBriefRoleSubmissionsResponse from "./entities/castingBriefs/CastingBriefRoleSubmissionsResponse";
import EditedArtistProfileInfo from "./entities/statusReports/EditedArtistProfileInfo";
import EditedArtistKeyVital from "./entities/statusReports/EditedArtistKeyVital";
import AgencyForDropdown from "./entities/AgencyForDropdown";
import { HeadShotOrder } from "./entities/artistProfile/Headshots";
import InviteArtistsToActivateAccountRequest from "./entities/InviteArtistsToActivateAccountRequest";
import ViewRolesWithDetailsResponse from "./entities/roles/ViewRolesWithDetailsResponse";
import BulkImportJobDetail from "./entities/BulkImport/BulkImportJobDetail";
import BulkImportJobValidationMessage from "./entities/BulkImport/BulkImportJobValidationMessage";
import BulkImportHeadshotDetail from "./entities/BulkImport/BulkImportHeadshotDetail";
import SelfTapeArtistProfileWithRoles from "./entities/sessions/SelfTapeArtistProfileWithRoles";
import BulkImportCreditResponse from "./entities/artistProfile/BulkImportCreditResponse";
import RegisterArtist from "./entities/RegisterArtist";
import { FilterValue } from "src/components/StatusReportFilter";
import ProjectSessionTimelineSnapshot from "./entities/sessions/ProjectSessionTimelineSnapshot";
import StatusReportGetTapePanelNavigation from "./entities/statusReports/StatusReportTapePanelNavigation";
import StatusReportTapePanelProfile from "./entities/statusReports/StatusReportTapePanelProfile";
import SubmissionInFolder from "./entities/statusReports/SubmissionInFolder";
import ProfileSharedChannel from "./entities/statusReports/ProfileSharedChannel";
import StatusReportTapePanelDetailsResponse from "./entities/statusReports/StatusReportTapePanelDetailsResponse";
import ProjectSessionHeaderDetails from "./entities/sessions/ProjectSessionHeaderDetails";
import AltaiArtistDetails from "./entities/lists/AltaiArtistDetails";
import CcBriefProjectAndRoleName from "./entities/ccBrief/CcBriefProjectAndRoleName";
import { selectedChannelForm } from "src/machines/channelDetailsMachine";
import SubmissionBadgeCountResponse from "./entities/SubmissionBadgeCountResponse";
import FilterData from "./entities/submissions/FilterData";
import AddTapesToMediaFolderRequest from "./entities/statusReports/AddTapesToMediaFolderRequest";
import StatusReportTableItem from "./entities/statusReports/StatusReportTableItem";
import ForwardMultipleStatusReportItemsToArtistRequest from "./entities/statusReports/ForwardMultipleStatusReportItemsToArtistRequest";
import {
  RunningListFilter,
  RunningListPdfData,
} from "src/machines/statusReportMachine";
import AgencyArtistForDropdown from "./entities/artistSubmissionReport/AgencyArtistForDropdown";
import ArtistSubmission from "./entities/artistSubmissionReport/ArtistSubmission";
import ArtistSubmissionReport from "./entities/artistSubmissionReport/ArtistSubmissionReport";
import formatSelftapeDueDate from "src/machines/createSessionMachine/utils/formatSelftapeDueDate";
import formatSelftapeDueTime from "src/machines/createSessionMachine/utils/formatSelftapeDueTime";
import moment from "moment";
import WardrobeTemplateResponse from "./entities/wardrobeForms/WardrobeTemplateResponse";
import AuditionClipsDetail from "./entities/artistProfile/AuditionClipsDetail";
import { ProfileSelectedForUpdateRoleStatus } from "src/machines/statusReportMachine";
import CcBriefSaveExtensionResponse from "./entities/ccBrief/CcBriefSaveExtensionResponse";
import WardrobeformTemplateByIdResponse from "./entities/wardrobeForms/WardrobeformTemplateByIdResponse";
import WardrobeFormTemplate from "./entities/wardrobeForms/WardrobeFormTemplate";
import City from "./entities/ccBrief/City";
import {
  ArtistInfo,
  HeaderInformation,
  InformationItem,
  ListArtistProfilePdf,
} from "src/components/PDFExports/PDFExportRunningList";
import { SubmissionProfileTrait } from "./entities/SubmissionProfile";
import { SessionRunningListPdfData } from "src/machines/manageLiveSessionMachine";
import CcFollowupTemporaryClip from "./entities/ccBrief/CcFollowupTemporaryClip";
import CcSubmissionFollowUpMessage from "./entities/ccBrief/CcSubmissionFollowUpMessage";
import {
  SelfTapeDeadLineExtendRequest,
  ViewSelfTapeMessageFilter,
} from "src/machines/viewSelfTapeRequestMachine";
import {
  SelfTapeMessageFilter,
  SelfTapePanelAsgnedArtist,
} from "src/machines/projectSessionsMachine";
import AssignArtistProfile from "./entities/AssignArtistProfile";
import CcAuditionRequest from "./entities/ccBrief/CcAuditionRequest";
import { CcAuditionRequestForm } from "src/machines/manageStatusListMachine/actors/listItemActor";
import DirectoryArtistSelftapeStatus from "src/services/altai/entities/sessions/DirectoryArtistSelftapeStatus";
import ArtistToReleaseDto from "./entities/RoleAndArtistToRelease";
import ProjectRoleArchiveRequest from "./entities/ProjectRoleArchiveRequest";
import ArtistsListFilters from "./entities/lists/ArtistsListFilters";
import availabilities from "src/constants/availabilities";
import EditCommentForm from "./entities/lists/EditCommentForm";
import ArtistListComment from "./entities/lists/ArtistListComment";
import { v4 as uuidv4 } from "uuid";
import CdCommentNotification from "./entities/homePage/CdCommentNotification";
import AddArtistsToProjectForm from "./entities/lists/AddArtistsToProjectForm";
import NewList from "./entities/lists/NewLists";

/********************************************** */
// Helpers
/********************************************** */
const toResponse = (data: any): CreateProjectResponse => ({
  id: data?.result ?? null,
  success: data?.success ?? false,
  message: data?.message ?? null,
});

const toUploadVideoResponse = (data: any): UploadVideoResponse => ({
  id: data?.result?.id ?? null,
  src: data?.result?.src ?? null,
  videoId: data?.result?.videoId ?? null,
  success: data?.success ?? false,
  message: data?.message ?? null,
});

const toApiResponse = (data: any): ApiResponse => ({
  result: data?.result ?? null,
  success: data?.success ?? false,
  message: data?.message ?? null,
});

const toDownloadMaterialResponse = (data: any): DownloadMaterialResponse => ({
  result: data?.result ?? null,
  success: data?.success ?? false,
  message: data?.message ?? null,
});

const unpackResponseArray = (data: any): CreateProjectResponse => ({
  id: data?.result != null && data.result.length > 0 ? data.result[0] : null,
  success: data?.success ?? false,
  message: data?.message ?? null,
});

const toPublishCastingBriefsResponse = (
  data: any
): PublishCastingBriefsResponse => ({
  success: data?.success ?? false,
  message: data?.message ?? null,
  result: data?.result ?? null,
});

const toRoleDropdownResponse = (data: any): RoleDropdownResponse => ({
  success: data?.success ?? false,
  message: data?.message ?? null,
  values: data?.result ?? null,
});

const toAgencyListsListsResponse = (data: any): AgencyListsResponse => ({
  success: data?.success ?? false,
  message: data?.message ?? null,
  values: data?.result ?? null,
});

const toRoleAgenciesResponse = (data: any): RoleAgenciesResponse => ({
  success: data?.success ?? false,
  message: data?.message ?? null,
  values: data?.result ?? null,
});

const parseProjectViewItem = (data: any): ViewProjectItem => ({
  id: data?.id ?? null,
  name: data?.name ?? null,
  status: data?.status ?? null,
  type: data?.type ?? null,
  format: data?.format ?? null,
  shootDateStart: toDate(data?.shootDateStart),
  shootDateEnd: toDate(data?.shootDateEnd),
});

const parseRoleViewItem = (data: any): ViewRoleTableItem => ({
  id: data?.id ?? null,
  name: data?.name ?? null,
  status: data?.status ?? null,
  ageRange: data?.ageRange ?? null,
  roleType: data?.roleType ?? null,
  unviewedsubmissionCount: data?.unviewedsubmissionCount ?? 0,
});

const parseRunningListPdfData = (data: any): RunningListPdfData => ({
  headerInformation: mapHeaderInformation(data),
  artists: data.artists.map(mapArtisData) ?? [],
});

const mapHeaderInformation = (data: any): HeaderInformation => ({
  agencyName: data?.projectName ?? null,
  brand: data?.folderName ?? null,
  projectName: data?.projectName ?? null,
  agencyImageUrl: data?.castingOfficeFormLogoUrl ?? undefined,
});

const mapArtisData = (runningListArtistInfoDTO: any): ArtistInfo => ({
  artist: mapArtisProfileData(runningListArtistInfoDTO),
  additionalInformation:
    runningListArtistInfoDTO.formQuestionAndAnswers === null
      ? []
      : runningListArtistInfoDTO.formQuestionAndAnswers.map(
          mapadditionalInformation
        ),
});

const mapSessionRunningListPdfData = (
  sessionrunningListDTO: any
): SessionRunningListPdfData => ({
  artists: sessionrunningListDTO.artistInfoList.map(mapArtisData) ?? [],
  headerInformation: mapSessionRunningListHeaderInformationPdfData(
    sessionrunningListDTO
  ),
});

const mapSessionRunningListHeaderInformationPdfData = (
  sessionrunningListDTO: any
): HeaderInformation => ({
  agencyImageUrl: sessionrunningListDTO.castingOfficeFormLogoUrl,
  projectName: "",
  brand: "",
  agencyName: "",
});

const mapTraits = (data: any): SubmissionProfileTrait[] => {
  return Object.keys(data).map((key) => ({
    id: "location",
    label: key.charAt(0).toUpperCase() + key.slice(1),
    value: data[key],
  }));
};

const mapArtisProfileData = (
  runningListArtistInfoDTO: any
): ListArtistProfilePdf => ({
  traits:
    runningListArtistInfoDTO?.artistKeyVitals === null
      ? []
      : mapTraits(runningListArtistInfoDTO?.artistKeyVitals),
  comments: runningListArtistInfoDTO?.commentItems ?? [],
  artistId: "",
  underEighteen: false,
  roles: runningListArtistInfoDTO?.roles,
  agent: null,
  messageHistories: [],
  id: "",
  name: runningListArtistInfoDTO.name,
  rating: null,
  image: runningListArtistInfoDTO.headShot,
  note: null,
  castingNote: null,
  conflicts: [],
  profileUrl: "",
  status: "",
  assigned: null,
  nationality: [],
  isScheduledForAudition: false,
  cdAgentCommunicationEnabled: null,
  cdArtistCommunicationEnabled: null,
  isFreelanceArtist: null,
  locationAbbreviation: "",
  timeSlotId: runningListArtistInfoDTO.timeSlotId ?? null,
});

const mapadditionalInformation = (
  formQuestionAndAnswerDTO: any
): InformationItem => ({
  id: "",
  label: formQuestionAndAnswerDTO?.questionText ?? "",
  detail: formQuestionAndAnswerDTO?.answerText ?? "",
});

const parseRoleWithDetailsViewItem = (
  data: any
): ViewRoleWithDetailsTableItem => ({
  id: data?.id ?? null,
  name: data?.name ?? null,
  status: data?.status ?? null,
  ageRange: data?.ageRange ?? null,
  roleType: data?.roleType ?? null,
  unviewedsubmissionCount: data?.unviewedsubmissionCount ?? 0,
  submissionCount: data?.submissionCount ?? 0,
  fee: data?.fee ?? null,
  gender: data?.gender ?? null,
  culturalBackground: data?.culturalBackground ?? null,
  roleDescription: data?.roleDescription ?? null,
  preferredArtistBaseLocation: data?.preferredArtistBaseLocation ?? null,
  details: null,
});

const parseRoleWithDetailsPdfViewItem = (
  data: any
): ViewRoleWithDetailsTableItem => ({
  id: data?.id ?? null,
  name: data?.name ?? null,
  status: data?.status ?? null,
  ageRange: data?.ageMin + " - " + data?.ageMax ?? null,
  roleType: data?.roleType ?? null,
  unviewedsubmissionCount: 0,
  submissionCount: 0,
  fee: data?.fee ?? null,
  gender: data?.gender ?? null,
  culturalBackground: data?.culturalBackground ?? null,
  roleDescription: data?.roleDescription ?? null,
  preferredArtistBaseLocation: data?.preferredArtistBaseLocation ?? null,
  details: parseRoleDetails(data),
});

const toProjectResponse = (data: any): ViewProjectResponse => ({
  result: data?.result?.map(parseProjectViewItem) ?? [],
  success: data?.success ?? false,
  message: data?.message ?? null,
});

const toSelfTapeClipStatus = (data: any): SelfTapeClipStatus => ({
  clipId: data?.result ?? "",
  isReady: data?.success ?? false,
});

const toRolesResponse = (data: any): ViewRolesResponse => ({
  result: data?.result?.map(parseRoleViewItem) ?? [],
  success: data?.success ?? false,
  message: data?.message ?? null,
});

const toRolesWithDetailsResponse = (
  data: any
): ViewRolesWithDetailsResponse => ({
  result: data?.result?.map(parseRoleWithDetailsViewItem) ?? [],
  success: data?.success ?? false,
  message: data?.message ?? null,
});

const toRolesWithDetailsPdfResponse = (
  data: any
): ViewRolesWithDetailsResponse => ({
  result: data?.result?.map(parseRoleWithDetailsPdfViewItem) ?? [],
  success: data?.success ?? false,
  message: data?.message ?? null,
});

const parseProjectDetails = (data: any): ProjectDetails => ({
  id: data?.id ?? null,
  additionalProvisions: data?.additionalProvisions ?? null,
  advertisingAgency: data?.advertisingAgency ?? null,
  advertisingContactEmail: data?.advertisingContactEmail ?? null,
  advertisingProducer: data?.advertisingProducer ?? null,
  auditionDateEnd: toDate(data?.auditionDateEnd),
  auditionDateStart: toDate(data?.auditionDateStart),
  auditionLocations: data?.auditionLocations ?? null,
  callbackDateEnd: toDate(data?.callbackDateEnd),
  callbackDateStart: toDate(data?.callbackDateStart),
  castingNotes: data?.castingNotes ?? null,
  castingOfficeAddress: data?.castingOfficeAddress ?? null,
  castingTeam: data?.castingTeam?.map(toTeamMemberItem) ?? [],
  castingTeam2: data?.castingTeam ?? [],
  companyName: data?.companyName ?? null,
  conflictType: data?.conflictType ?? [],
  conflicts: data?.conflicts ?? null,
  director: data?.director ?? null,
  emailAddress: data?.emailAddress ?? null,
  executiveProducer: data?.executiveProducer ?? null,
  format: data?.format ?? null,
  ndaFile: data?.ndaFile ?? null,
  projectFiles: data?.projectFiles ?? null,
  network: data?.network ?? null,
  producer: data?.producer ?? null,
  productionCompany: data?.productionCompany ?? null,
  referenceNumber: data?.referenceNumber ?? null,
  rehearsalDateEnd: toDate(data?.rehearsalDateEnd),
  rehearsalDateStart: toDate(data?.rehearsalDateStart),
  shootDateEnd: toDate(data?.shootDateEnd),
  shootDateStart: toDate(data?.shootDateStart),
  shootLocations: data?.shootLocations ?? null,
  status: data?.status ?? null,
  studio: data?.studio ?? null,
  synopsis: data?.synopsis ?? null,
  title: data?.title ?? null,
  type: data?.type ?? null,
  usageOrRun: data?.usageOrRun ?? null,
  wardrobeDateEnd: toDate(data?.wardrobeDateEnd),
  wardrobeDateStart: toDate(data?.wardrobeDateStart),
  weatherHoldDateEnd: toDate(data?.weatherHoldDateEnd),
  weatherHoldDateStart: toDate(data?.weatherHoldDateStart),
  writer: data?.writer ?? null,
  isNonConsecutiveShootDates: data?.isNonConsecutiveShootDates
    ? "true"
    : "false" ?? "false", // radio button group works with strings
  isNonConsecutiveAuditionDates: data?.isNonConsecutiveAuditionDates
    ? "true"
    : "false" ?? "false", // radio button group works with strings
  nonConsecutiveAuditionDates:
    data?.nonConsecutiveAuditionDates?.map((d: string) => toDate(d)) ?? [],
  nonConsecutiveShootDates:
    data?.nonConsecutiveShootDates?.map((d: string) => toDate(d)) ?? [],
});

const parseCastingBriefRoleItem = (data: any): CastingBriefRoleItem => ({
  id: data?.id ?? "",
  ageRange: data?.ageRange ?? "",
  underEighteen: data?.underEighteen ?? false,
  culturalBackground: data?.culturalBackground ?? "",
  fee: data?.fee ?? "",
  gender: data?.gender ?? "",
  isNew: data?.isNew ?? false,
  name: data?.name ?? "",
  status: data?.status ?? "",
  submissionDeadline: toDate(data?.submissionDeadline),
  type: data?.type ?? "",
  timezone: data?.timezone ?? "",
  timezoneAbbreviation: data?.timezoneAbbreviation ?? "",
});

const parseCastingBriefProjectDetails = (
  data: any
): CastingBriefProjectDetails => ({
  id: data?.id ?? null,
  castingOffice: data?.castingOffice ?? "",
  additionalProvisions: data?.additionalProvisions ?? null,
  advertisingAgency: data?.advertisingAgency ?? null,
  advertisingContactEmail: data?.advertisingContactEmail ?? null,
  advertisingProducer: data?.advertisingProducer ?? null,
  auditionDateEnd: toDate(data?.auditionDateEnd),
  auditionDateStart: toDate(data?.auditionDateStart),
  auditionLocations: data?.auditionLocations ?? null,
  callbackDateEnd: toDate(data?.callbackDateEnd),
  callbackDateStart: toDate(data?.callbackDateStart),
  castingNotes: data?.castingNotes ?? null,
  castingTeam: data?.castingTeam?.map(toTeamMemberItem) ?? [],
  castingTeam2: data?.castingTeam ?? [],
  companyName: data?.companyName ?? null,
  conflictType: data?.conflictType ?? [],
  conflicts: data?.conflicts ?? null,
  director: data?.director ?? null,
  emailAddress: data?.emailAddress ?? null,
  executiveProducer: data?.executiveProducer ?? null,
  format: data?.format ?? null,
  ndaFile: data?.ndaFile ?? null,
  projectFiles: data?.projectFiles ?? null,
  network: data?.network ?? null,
  producer: data?.producer ?? null,
  productionCompany: data?.productionCompany ?? null,
  referenceNumber: data?.referenceNumber ?? null,
  rehearsalDateEnd: toDate(data?.rehearsalDateEnd),
  rehearsalDateStart: toDate(data?.rehearsalDateStart),
  shootDateEnd: toDate(data?.shootDateEnd),
  shootDateStart: toDate(data?.shootDateStart),
  shootLocations: data?.shootLocations ?? null,
  status: data?.status ?? null,
  studio: data?.studio ?? null,
  synopsis: data?.synopsis ?? null,
  title: data?.title ?? null,
  type: data?.type ?? null,
  usageOrRun: data?.usageOrRun ?? null,
  wardrobeDateEnd: toDate(data?.wardrobeDateEnd),
  wardrobeDateStart: toDate(data?.wardrobeDateStart),
  weatherHoldDateEnd: toDate(data?.weatherHoldDateEnd),
  weatherHoldDateStart: toDate(data?.weatherHoldDateStart),
  writer: data?.writer ?? null,
  roles: data?.roles?.map(parseCastingBriefRole) ?? [],
  castingTeamEmails: data?.castingTeam?.map((c: any) => c.email) ?? [],
  isNonConsecutiveShootDates: data?.isNonConsecutiveShootDates
    ? "true"
    : "false" ?? "false", // radio button group works with strings
  isNonConsecutiveAuditionDates: data?.isNonConsecutiveAuditionDates
    ? "true"
    : "false" ?? "false", // radio button group works with strings
  nonConsecutiveAuditionDates:
    data?.nonConsecutiveAuditionDates?.map((d: string) => toDate(d)) ?? [],
  nonConsecutiveShootDates:
    data?.nonConsecutiveShootDates?.map((d: string) => toDate(d)) ?? [],
  castingOfficeAddress: data?.castingOfficeAddress ?? null,
});

const parseRoleDetails = (data: any): RoleDetails => ({
  id: data.id,
  projectId: data.projectId,
  projectName: data.projectName,
  name: data.name,
  status: data.status,
  roleType: data.roleType,
  gender: data.gender ?? [],
  otherGender: data.otherGender,
  isAgeMonths: data.isAgeMonths,
  ageMin: data.ageMin,
  ageMax: data.ageMax,
  ageMonthsMin: data.ageMonthsMin,
  ageMonthsMax: data.ageMonthsMax,
  culturalBackground: data.culturalBackground ?? [],
  eyeColor: data.eyeColor ?? [],
  additionalLanguagesRequired: data.additionalLanguagesRequired ?? [],
  accent: data.accent ?? [],
  nudityOrSexualSituations: data.nudityOrSexualSituations,
  fee: data.fee,
  additionalFeeInformation: data.additionalFeeInformation,
  commonRoleSkills: data.commonRoleSkills ?? [],
  additionalSkillsRequired: data.additionalSkillsRequired,
  conflicts: data.conflicts ?? [],
  conflictDetails: data.conflictDetails,
  roleDescription: data.roleDescription,
  shootLocation: data.shootLocation,
  shootStartDate: toDate(data.shootStartDate),
  shootEndDate: toDate(data.shootEndDate),
  additionalShootNotes: data.additionalShootNotes,
  preferredArtistBaseLocation: data.preferredArtistBaseLocation,
  auditionLocation: data.auditionLocation,
  auditionDateStart: toDate(data.auditionDateStart),
  auditionDateEnd: toDate(data.auditionDateEnd),
  callbackDateStart: toDate(data.callbackDateStart),
  callbackDateEnd: toDate(data.callbackDateEnd),
  wardrobeNotes: data.wardrobeNotes,
  ndaRequired: data.ndaRequired,
  selfTapeGuideLink: data.selfTapeGuideLink,
  agencies: data.agencies?.map(parseRoleAgencies) ?? [],
  wardrobeForm: parseWarDrobeForm(data),
  roleFiles: data.roleFiles?.map(parseRoleFile) ?? [],
  projectFiles: data.projectFiles?.map(parseProjectFile) ?? [],
  referenceFile: parseReferenceFile(data.referenceFile),
  guideFile: parseReferenceFile(data.guideFile),
  projectNda: parseProjectFile(data.projectNda),
  isNonConsecutiveShootDates: data?.isNonConsecutiveShootDates
    ? "true"
    : "false" ?? "false", // radio button group works with strings
  isNonConsecutiveAuditionDates: data?.isNonConsecutiveAuditionDates
    ? "true"
    : "false" ?? "false", // radio button group works with strings
  isNonConsecutiveCallbackDates: data?.isNonConsecutiveCallbackDates
    ? "true"
    : "false" ?? "false", // radio button group works with strings
  nonConsecutiveAuditionDates:
    data?.nonConsecutiveAuditionDates?.map((d: string) => toDate(d)) ?? [],
  nonConsecutiveShootDates:
    data?.nonConsecutiveShootDates?.map((d: string) => toDate(d)) ?? [],
  nonConsecutiveCallbackDates:
    data?.nonConsecutiveCallbackDates?.map((d: string) => toDate(d)) ?? [],
});

const parseCompletedWardrobeFromOverviews = (
  data: any
): CompletedWardrobeFormOverview => ({
  id: data?.id ?? "",
  label: data?.label ?? "",
  completedDate: toDate(data?.completedDate),
});
const toRoleSubmissionsForRoleArtist = (
  data: any
): DirectoryArtistSelftapeStatus => ({
  artisProfileId: data.artistProfileId,
  roleId: data.roleId,
  sessionType: data.sessionType,
  submissionId: data.submissionId,
  selfTapeRequestId: data.selfTapeSessionId
});

const toStatusReportWardrobeDetails = (
  data: any
): StatusReportWardrobeDetails => ({
  id: data?.result?.id ?? "",
  agencyName: data?.result?.agencyName ?? "",
  agencyPhone: data?.result?.agencyPhone ?? "",
  artistName: data?.result?.artistName ?? "",
  projectName: data?.result?.projectName ?? "",
  projectId: data?.result?.projectId ?? "",
  roleName: data?.result?.roleName ?? "",
  wardrobeForm: parseWarDrobeForm(data?.result)!,
  preFilledArtistFields: data?.result?.preFilledArtistFields ?? [],
  completedWardrobeForms:
    data?.result?.completedWardrobeForms?.map(
      parseCompletedWardrobeFromOverviews
    ) ?? [],
});

const parseUploadedFile = (data: any): UploadedWardrobeFormFile => ({
  id: data?.uploadedFileId,
  name: data?.fileName,
  fileUrl: data?.fileUrl,
});

const parseCompletedWardrobeFromQuestion = (
  data: any
): CompletedWardrobeQuestion => ({
  id: data?.id ?? "",
  answer: data?.answer ?? "",
  commonRequest: data?.commonRequest ?? false,
  fileUpload: data?.fileUpload ?? false,
  formalAcknowledgement: data?.formalAcknowledgement ?? false,
  questionText: data?.questionText ?? "",
  uploadedFiles: data?.uploadedFiles?.map(parseUploadedFile) ?? [],
  selfieRequest: data?.selfieRequest ?? false,
  required: data?.required ?? false,
  signatureRequest: data?.signatureRequest ?? false,
});

const toCompletedWardrobeFormDetails = (
  data: any
): CompletedWardrobeFormDetails => ({
  ...parseCompletedWardrobeFromOverviews(data?.result),
  agencyName: data?.result?.agencyName ?? "",
  agencyPhone: data?.result?.agencyPhone ?? "",
  artistName: data?.result?.artistName ?? "",
  projectName: data?.result?.projectName ?? "",
  roleName: data?.result?.roleName ?? "",
  preFilledArtistFields: data?.result?.preFilledArtistFields ?? [],
  completedBy: data?.result?.completedBy ?? "",
  completedByAgency: data?.result?.completedByAgency,
  answers: data?.result?.answers?.map(parseCompletedWardrobeFromQuestion) ?? [],
  sessionName: data?.result?.sessionName ?? "",
});

const parseCompletedWardrobeFormDetailsFromOverview = (
  data: any
): CompletedWardrobeFormDetails => ({
  ...parseCompletedWardrobeFromOverviews(data),
  agencyName: data?.agencyName ?? "",
  agencyPhone: data?.agencyPhone ?? "",
  artistName: data?.artistName ?? "",
  projectName: data?.projectName ?? "",
  roleName: data?.roleName ?? "",
  preFilledArtistFields: data?.preFilledArtistFields ?? [],
  completedBy: data?.completedBy ?? "",
  completedByAgency: data?.completedByAgency,
  answers: data?.answers?.map(parseCompletedWardrobeFromQuestion) ?? [],
  sessionName: data?.sessionName ?? "",
});

const parseCastingBriefRole = (data: any): CastingBriefRoleDetails | null => {
  if (data == null) {
    return null;
  }
  return {
    ...parseRoleDetails(data),
    submissionDeadline: toDate(data?.submissionDeadline),
    timezone: data?.timezone ?? "",
    timezoneAbbreviation: data?.timezoneAbbreviation ?? "",
    submissionNotes: data?.submissionNotes ?? "",
    submissionInstructions: data?.submissionInstructions ?? "",
    isNew: data?.isNew ?? false,
    ageRange: data?.ageRange ?? "",
  };
};

const parseCastingBriefRoleFiles = (data: any): CastingBriefRoleFiles => ({
  id: data?.result?.roleId ?? "",
  roleFiles: data.result?.roleFiles?.map(parseRoleFile) ?? [],
  projectFiles: data.result?.projectFiles?.map(parseProjectFile) ?? [],
  referenceFile: parseReferenceFile(data.result?.referenceFile),
  projectNda: parseProjectFile(data?.result?.projectNda),
});

const toCastingBriefRole = (data: any): CastingBriefRoleDetails => ({
  ...parseRoleDetails(data?.result),
  submissionDeadline: toDate(data?.result?.submissionDeadline),
  timezone: data?.result?.timezone ?? "",
  timezoneAbbreviation: data?.result?.timezoneAbbreviation ?? "",
  submissionNotes: data?.result?.submissionNotes ?? "",
  submissionInstructions: data?.result?.submissionInstructions ?? "",
  isNew: data?.result?.isNew ?? false,
  ageRange: data?.result?.ageRange ?? "",
});

const parseStatusReportSession = (data: any): StatusReportSession => ({
  id: data?.id ?? "",
  projectSessionId: data?.projectSessionId,
  version: data?.version ?? 1,
  additionalNotes: data?.additionalNotes ?? "",
  auditionStatusStage: data?.auditionStatusStage ?? "",
  date: toDate(data?.date),
  format: data?.format ?? "",
  forwardedToArtist: data?.forwardedToArtist ?? false,
  location: data?.location ?? "",
  timezone: data?.timezone ?? "",
  type: data?.type ?? "",
  unsolicited: data?.unsolicited ?? false,
  tapeManagementType: data?.tapeManagementType ?? "",
  messageHistories: data?.messageHistories ?? [],
  videoCallLink: data?.videoCallLink ?? "",
});

const parseRoleStatusDetails = (data: any): RoleStatusDetails => ({
  id: data?.id ?? "",
  statusStage: data?.statusStage ?? false,
  forwardedToArtist: data?.forwardedToArtist ?? false,
  isDeclined: data?.isDeclined ?? false,
  isRequest: data?.isRequest ?? false,
  files:
    data?.files?.map((f: any) => ({
      id: f.uploadedFileId ?? "",
      name: f.fileName ?? "",
    })) ?? [],
  messageHistories: data?.messageHistories ?? [],
  statusKeyword: data?.statusKeyword ?? "",
});

const toStatusReportDetails = (data: any): StatusReportDetails => ({
  id: data?.result?.id ?? "",
  artist: data?.result?.artist ?? "",
  artistFirstName: data?.result?.artistFirstName ?? "",
  artistAgentName: data?.result?.artistAgentName ?? "",
  agentTeamEmails: data?.result?.agentTeamEmails ?? [],
  artistId: data?.result?.artistId ?? "",
  castingOffice: data?.result?.castingOffice ?? "",
  hasNda: data?.result?.hasNda ?? false,
  hasWardrobeForm: data?.result?.hasWardrobeForm ?? false,
  project: data?.result?.project ?? "",
  role: data?.result?.role ?? "",
  unsolicited: data?.result?.unsolicited ?? false,
  isPremiumArtist: data?.result?.isPremiumArtist ?? false,
  isLapsedpayment: data?.result?.isLapsedpayment ?? false,
  isArtistInformed: data?.result?.isArtistInformed ?? false,
  isUserHavingActiveLogin: data?.result?.isUserHavingActiveLogin ?? false,
  contactEmail: data?.result?.contactEmail ?? "",
  contactPhone: data?.result?.contactPhone ?? "",
  projectDetails: parseProjectDetails(data?.result?.projectDetails),
  roleDetails: parseRoleDetails(data?.result?.roleDetails),
  sessions: data?.result?.sessions?.map(parseStatusReportSession) ?? [],
  uploadedNdas: data?.result?.uploadedNdas ?? [],
  roleStatus:
    data?.result?.roleStatus != null
      ? parseRoleStatusDetails(data!.result!.roleStatus)
      : null,
  generalMessageHistories: data?.result?.generalMessageHistories ?? [],
});

const parseRoleAgencies = (data: any): RoleAgency | null =>
  data == null
    ? null
    : {
        id: data.id,
        name: data.name,
        submissionDeadline: toDate(data.submissionDeadline),
        status: data.status,
      };

const parseRoleFile = (data: any): RoleFile | null =>
  data == null
    ? null
    : {
        id: data.roleFileId,
        uploadedFileId: data.uploadedFileId,
        fileName: data.fileName,
        releaseStage: data.releaseStage,
        watermarkArtistName: data.watermarkWithArtistName,
        preventAgentsFromSeeing: data.preventAgentsFromSeeing,
      };

const parseProjectFile = (data: any): RoleFile | null =>
  data == null
    ? null
    : {
        id: data.projectFileId,
        uploadedFileId: data.uploadedFileId,
        fileName: data.fileName,
        releaseStage: data.releaseStage,
        watermarkArtistName: data.watermarkWithArtistName,
        preventAgentsFromSeeing: data.preventAgentsFromSeeing,
      };

const parseReferenceFile = (data: any): RoleFile | null =>
  data == null
    ? null
    : {
        id: data.roleFileId,
        uploadedFileId: data.uploadedFileId,
        fileName: data.fileName,
        releaseStage: 0,
        watermarkArtistName: false,
        preventAgentsFromSeeing: false,
      };

const parseWarDrobeForm = (data: any): WardrobeForm | null =>
  data.wardrobeForm == null
    ? null
    : {
        ...data.wardrobeForm,
        uploadedWardrobeFiles: data.wardrobeForm.roleWardrobeFiles?.map(
          (doc: any) => ({
            id: doc.roleFileId,
            uploadedFileId: doc.uploadedFileId,
            fileName: doc.fileName,
            releaseStage: 0,
            watermarkArtistName: false,
            preventAgentsFromSeeing: false,
          })
        ),
      };
const parseWarDrobeTemplate = (data: any): WardrobeFormTemplate | null =>
  data.wardrobeForm == null
    ? null
    : {
        ...data.wardrobeForm,
        uploadedWardrobeFiles: data.wardrobeForm.roleWardrobeFiles?.map(
          (doc: any) => ({
            id: doc.roleFileId,
            uploadedFileId: doc.uploadedFileId,
            fileName: doc.fileName,
            releaseStage: 0,
            watermarkArtistName: false,
            preventAgentsFromSeeing: false,
          })
        ),
      };

const toProjectDetailsResponse = (data: any): ProjectDetailsResponse => ({
  result: parseProjectDetails(data?.result ?? {}),
  success: data?.success ?? false,
  message: data?.message ?? null,
});

const toDownloadedFilePathResponse = (
  data: any
): DownloadedFilePathResponse => ({
  success: data?.success ?? false,
  message: data?.message ?? null,
  result: data?.result ?? null,
});

const parseAgentItem = (data: any): Agent => ({
  id: data?.id ?? null,
  firstName: data?.firstName ?? null,
  lastName: data?.lastName ?? null,
  email: data?.email ?? null,
  phoneNumber: data?.phoneNumber ?? null,
});

const parseAgencyItem = (data: any): Agency => ({
  id: data?.id ?? null,
  companyName: data?.companyName ?? null,
  agents: data?.agents.map(parseAgentItem) ?? [],
  artistCount: data?.artistCount ?? null,
});

const toAgencyResponse = (data: any): AgencySearchResponse => ({
  success: data?.success ?? false,
  message: data?.message ?? null,
  result: data?.result?.map(parseAgencyItem) ?? [],
});

const parseCountryResponse = (data: any): string => {
  return data?.country ?? null;
};

const parseApprovalsResponse = (data: any): AdminApprovalsResponse => ({
  castingDirectors: data?.result?.castingDirectors ?? [],
  agencies: data?.result?.agencies ?? [],
});

const toCastingDirectorCompany = (data: any): CastingDirectorCompany => ({
  id: data?.result?.id ?? "",
  address1: data?.result?.address1 ?? "",
  address2: data?.result?.address2 ?? "",
  admin: {
    id: data?.result?.admin?.id ?? "",
    email: data?.result?.admin?.email ?? "",
    firstName: data?.result.admin?.firstName ?? "",
    lastName: data?.result?.admin?.lastName ?? "",
    jobTitle: data?.result?.admin?.jobTitle ?? "",
    imdbUrl: data?.result?.admin?.imdbUrl ?? "",
    mobile: data?.result?.admin?.mobile ?? "",
    emailsAllowed: data?.result?.admin?.emailsAllowed ?? false,
  },
  companyName: data?.result?.companyName ?? "",
  country: data?.result?.country ?? "",
  phone: data?.result?.phone ?? "",
  postcode: data?.result?.postcode ?? "",
  state: data?.result?.state ?? "",
  suburb: data?.result?.suburb ?? "",
  taxNumber: data?.result?.taxNumber ?? "",
  teammates: data?.result?.teammates ?? [],
  websiteUrl: data?.result?.websiteUrl ?? "",
  referenceFile:
    data?.result?.referenceFile != null
      ? {
          id: data?.result?.referenceFile?.uploadedFileId ?? "",
          uploadedFileId: data?.result?.referenceFile?.uploadedFileId ?? "",
          fileName: data?.result?.referenceFile?.fileName ?? "",
        }
      : null,
});

const toUserDetailsResponse = (data: any): UserDetailsResponse => {
  return {
    success: data?.success ?? false,
    message: data?.message ?? null,
    user: data?.result ?? null,
  };
};

const toAgencyInvitationTableItems = (data: any): AgencyInvitationTableItem[] =>
  data?.result?.map((i: any) => ({
    id: i?.id ?? "",
    email: i?.email ?? "",
    status: i?.status ?? "",
    invitationDate: toDate(i?.invitationDate),
  })) ?? [];

const toInviteAgencyArtistsResponse = (
  data: any
): InviteAgencyArtistsResponse => {
  return {
    success: data?.success ?? false,
    message: data?.message ?? null,
    alreadyInvitedEmails: data?.result?.alreadyInvitedEmails ?? [],
    alreadyRepresented: data?.result?.alreadyRepresented ?? [],
  };
};

const toProjectSelectedArtists = (data: any): ProjectSelectedArtist[] => {
  return data?.result ?? [];
};
const toProjectSelfTapeRequestArtist = (data: any): AssignArtistProfile => {
  return {
    id: data.id,
    artistId: data.artistId,
    name: data.name,
    agent: data.agent,
    image: data.image,
    traits: [
      {
        id: "location",
        label: "Location",
        value: data.location,
      },
      {
        id: "agent",
        label: "Agent",
        value: `${data.agent} ${data.agentEmail} ${data.agentPhone}`,
      },
    ],
    assigned: "",
    castingNote: "",
    cdAgentCommunicationEnabled: null,
    cdArtistCommunicationEnabled: null,
    isFreelanceArtist: null,
    conflicts: [],
    isScheduledForAudition: false,
    locationAbbreviation: "",
    messageHistories: [],
    nationality: [],
    note: "",
    profileUrl: "",
    rating: null,
    roles: [],
    status: "",
    underEighteen: false,
  };
};

const toSubmissionsFilterDataResponse = (data: any): FilterData => {
  return {
    baseLocations: data?.result?.baseLocations ?? [],
    culturalBackgrounds: data?.result?.culturalBackgrounds ?? [],
    genders: data?.result?.genders ?? [],
    nationalities: data?.result?.nationalities ?? [],
    maxAge: data?.result?.maxAge ?? 120,
    maxHeight: data?.result?.maxHeight ?? 250,
    minAge: data?.result?.minAge ?? 0,
    minHeight: data?.result?.minHeight ?? 10,
  };
};

const toSubmissionsResponse = (data: any): SubmissionsResponse => {
  return {
    success: data?.success ?? false,
    message: data?.message ?? null,
    roleSubmissionStatus: data?.result?.roleSubmissionStatus ?? null,
    submissions: data?.result?.submissions ?? [],
    totalSubmissionCount: data?.result?.totalSubmissionCount ?? 0,
  };
};

const toSubmissionBadgeCountResponse = (
  data: any
): SubmissionBadgeCountResponse => {
  return {
    success: data?.success ?? false,
    message: data?.message ?? null,
    result: {
      inReview: data?.result?.inReview ?? 0,
      reviewed: data?.result?.reviewed ?? 0,
      selected: data?.result?.selected ?? 0,
    },
  };
};

const toCastingOrAgentDetails = (
  data: any
): CastingDirectorOrAgentAccountDetails => ({
  id: data?.result?.id ?? "",
  firstName: data?.result?.firstName ?? "",
  lastName: data?.result?.lastName ?? "",
  emailAddress: data?.result?.emailAddress ?? "",
  phoneNumber: data?.result?.phoneNumber ?? "",
  jobTitle: data?.result?.jobTitle ?? "",
  imdbUrl: data?.result?.imdbUrl ?? "",
  communicationsEnabled: data?.result?.communicationsEnabled ?? false,
  projectCommunications: data?.result?.projectCommunications ?? false,
});

const toTeamMembersResponse = (data: any): TeamMembersResponse => ({
  success: data?.success ?? false,
  message: data?.message ?? null,
  members: data?.result ?? null,
});
const toWarDrobeTemplateResponse = (data: any): WardrobeTemplateResponse => ({
  success: data?.success ?? false,
  message: data?.message ?? null,
  templates: data?.result ?? null,
});
const toWarDrobeTemplateByIdResponse = (
  data: any
): WardrobeformTemplateByIdResponse => ({
  success: data?.success ?? false,
  message: data?.message ?? null,
  wardrobeTemplate: data?.result ?? null,
});

const toCastingOrAgentCompanyDetails = (
  data: any
): CastingDirectorOrAgentCompanyDetails => ({
  id: data?.result?.id ?? null,
  address1: data?.result?.address1 ?? "",
  address2: data?.result?.address2 ?? "",
  companyName: data?.result?.companyName ?? "",
  companyEmail: data?.result?.companyEmail ?? "",
  country: data?.result?.country ?? "",
  directoryLocation: data?.result?.directoryLocation ?? "",
  artistDirectoryLocation:
    data?.result?.artistDirectoryLocation?.map(toAutocompleteItemPreserved) ??
    [],
  phoneNumber: data?.result?.phoneNumber ?? "",
  postcode: data?.result?.postcode ?? "",
  state: data?.result?.state ?? "",
  suburb: data?.result?.suburb ?? "",
  taxNumber: data?.result?.taxNumber ?? "",
  websiteUrl: data?.result?.websiteUrl ?? "",
  stateDropdown: null,
  channelLogoFileName: data?.result?.channelLogoFileName ?? "",
  channelLogoUrl: data?.result?.channelLogoUrl ?? "",
  wardrobeLogoFileName: data?.result?.wardrobeLogoFileName ?? "",
  wardrobeLogoUrl: data?.result?.wardrobeLogoUrl ?? "",
});

const toAgencyRepresentationResponse = (
  data: any
): AgencyRepresentationResponse => ({
  success: data?.success ?? false,
  message: data?.message ?? null,
  agency:
    data?.result != null
      ? {
          agencyId: data?.result?.primaryRepresentation?.id ?? "",
          address1: data?.result?.primaryRepresentation?.address1 ?? "",
          address2: data?.result?.primaryRepresentation?.address2 ?? "",
          companyName: data?.result?.primaryRepresentation?.companyName ?? "",
          country: data?.result?.primaryRepresentation?.country ?? "",
          directoryLocation:
            data?.result?.primaryRepresentation?.directoryLocation ?? [],
          phoneNumber: data?.result?.primaryRepresentation?.phoneNumber ?? "",
          postcode: data?.result?.primaryRepresentation?.postcode ?? "",
          state: data?.result?.primaryRepresentation?.state ?? "",
          suburb: data?.result?.primaryRepresentation?.suburb ?? "",
          taxNumber: data?.result?.primaryRepresentation?.taxNumber ?? "",
          websiteUrl: data?.result?.primaryRepresentation?.websiteUrl ?? "",
        }
      : null,
  secondaryAgency:
    data?.result != null
      ? {
          secondaryAgencyName:
            data?.result?.secondaryAgency?.secondaryAgencyName ?? "",
          secondaryAgencyEmail:
            data?.result?.secondaryAgency?.secondaryAgencyEmail ?? "",
          secondaryAgencyPhone:
            data?.result?.secondaryAgency?.secondaryAgencyPhone ?? "",
          secondaryAgencyType:
            data?.result?.secondaryAgency?.secondaryAgencyType ?? "",
        }
      : null,
});

const toArtistPersonalDetails = (data: any): ArtistPersonalDetails => ({
  id: data?.result?.id ?? "",
  firstName: data?.result?.firstName ?? "",
  lastName: data?.result?.lastName ?? "",
  emailAddress: data?.result?.emailAddress ?? "",
  phoneNumber: data?.result?.phoneNumber ?? "",
  communicationsEnabled: data?.result?.communicationsEnabled ?? false,
  gender: data?.result?.gender ?? "",
  dateOfBirth: toDate(data?.result?.dateOfBirth),
  address1: data?.result?.address1 ?? "",
  address2: data?.result?.address2 ?? "",
  country: data?.result?.country ?? "",
  postcode: data?.result?.postcode ?? "",
  state: data?.result?.state ?? "",
  suburb: data?.result?.suburb ?? "",
  stateDropdown: null,
  showContactDetailsIfFreelanceArtist:
    data?.result?.showContactDetailsIfFreelanceArtist ?? false,
  subscribedForPaymentReminder:
    data?.result?.subscribedForPaymentReminder ?? false,
  subscriptionSchedule: data?.result?.subscriptionSchedule,
});

const toPaymentStatus = (data: any): PaymentStatus => ({
  isActive: data?.result?.isActive ?? false,
  isTrial: data?.result?.isTrial ?? false,
  isPaymentDetailsCollectionInitiated:
    data?.result?.isPaymentDetailsCollectionInitiated ?? false,
  paymentExempt: data?.result?.paymentExempt ?? false,
  daysLeftInTrial: data?.result?.daysLeftInTrial ?? null,
  nextBillingDate: data?.result?.nextBillingDate ?? null,
  adminEmailAddress: data?.result?.adminEmailAddress ?? null,
  subscriptionCancelAt: data?.result?.subscriptionCancelAt ?? null,
  isSubscribedForPayment: data?.result?.isSubscribedForPayment ?? false,
  isFirstLoginToLiteTier: data?.result?.isFirstLoginToLiteTier ?? false,
});

const parseProfileDocument = (data: any): DownloadedFile | null => {
  if (data == null) return null;
  return {
    id: data.uploadedFileId,
    uploadedFileId: data.uploadedFileId,
    fileName: data.fileName,
  };
};

const parseAgencyRosterProfile = (data: any): AgencyRosterProfile => ({
  id: data?.id ?? "",
  name: data?.name ?? "",
  gender: data?.gender ?? null,
  location: data?.location ?? null,
  height: data?.height ?? null,
  culturalBackground: data?.culturalBackground ?? null,
  ageRange: data?.ageRange ?? null,
  underEighteen: data?.underEighteen ?? false,
  image: data?.image ?? null,
  profileUrl: data?.profileUrl ?? null,
  contactAddress: data?.contactAddress ?? null,
  contactEmail: data?.contactEmail ?? null,
  contactPhone: data?.contactPhone ?? null,
  secondaryAgencyEmail: data?.secondaryAgencyEmail ?? null,
  secondaryAgencyName: data?.secondaryAgencyName ?? null,
  secondaryAgencyPhone: data?.secondaryAgencyPhone ?? null,
  profileComplete: data?.profileComplete ?? false,
  isPremiumArtist: data?.isPremiumArtist ?? false,
  isLapsedpayment: data?.isLapsedpayment ?? false,
  isUserInvited: data?.isUserInvited ?? false,
  isUserHavingActiveLogin: data?.isUserHavingActiveLogin ?? false,
  isPaymentHandledByAgency: data?.isPaymentHandledByAgency ?? false,
});

const parseArtistCastingBriefProfile = (
  data: any
): ArtistCastingBriefProfile => ({
  id: data?.id ?? "",
  name: data?.name ?? "",
  gender: data?.gender ?? null,
  location: data?.location ?? null,
  height: data?.height ?? null,
  culturalBackground: data?.culturalBackground ?? null,
  ageRange: data?.ageRange ?? null,
  underEighteen: data?.underEighteen ?? false,
  image: data?.image ?? null,
  profileUrl: data?.profileUrl ?? null,
  notes: data?.notes ?? null,
  submittedForRole: data?.submittedForRole ?? false,
  otherRoleSubmissions: data?.otherRoleSubmissions ?? [],
  conflicts: data?.conflicts ?? [],
  isPremiumArtist: data?.isPremiumArtist ?? false,
  isLapsedpayment: data?.isLapsedpayment ?? false,
  phone: data?.phone ?? null,
  email: data?.email ?? null,
});

const toRosterArtists = (data: any): AgencyRosterProfile[] =>
  data?.result?.map(parseAgencyRosterProfile) ?? [];

const toArtistSubmissionReport = (data: any): ArtistSubmissionReport => ({
  artistName: data?.result?.artistName,
  agencyName: data?.result?.agencyName,
  submissions: data?.result?.submissions.map(parseArtistSubmission),
});

const parseArtistSubmission = (data: any): ArtistSubmission => ({
  id: data?.id,
  submissionDate: data?.submissionDate ?? null,
  castingOfficeName: data?.castingOfficeName,
  projectName: data?.projectName,
  roleName: data?.roleName,
  status: data?.status,
});

const toArtistCastingBriefs = (data: any): ArtistCastingBriefProfile[] =>
  data?.result?.map(parseArtistCastingBriefProfile) ?? [];

const parseCastingBriefItem = (data: any): ViewCastingBriefItem => ({
  id: data?.id,
  castingOffice: data?.castingOffice,
  newRoles: data?.newRoles,
  project: data?.name,
  shootLocation: data?.shootLocation,
  type: data?.type,
});

const toCastingBriefProjects = (data: any): ViewCastingBriefItem[] =>
  data?.result?.map(parseCastingBriefItem) ?? [];

const parseThumbnail = (data: any): MaterialVideoThumbnails => ({
  videoId: data?.videoId,
  thumbnails: data?.thumbnails,
});

const toThumbnails = (data: any): MaterialVideoThumbnails[] =>
  data?.result?.map(parseThumbnail) ?? [];

const parseArtistProfileImage = (data: any): ArtistProfileImage | null => {
  if (data == null) return null;
  return {
    id: data.uploadedFileId,
    order: data.order,
    fileName: data.fileName,
    imageUrl: data.imageUrl,
    imageDownloadUrl: data.imageDownloadUrl,
    uploadedFileId: "",
    primaryHeadshot: data.primaryHeadshot ?? false,
  };
};

const toRegisterArtistProfile = (data: any): RegisterArtist => ({
  firstName: data?.result?.firstName ?? "",
  lastName: data?.result?.lastName ?? "",
  country: data?.result?.country ?? "",
  dateOfBirth: toDate(data?.result?.dateOfBirth),
  gender: data?.result?.gender ?? "",
  email: data?.result?.email,
  mobile: data?.result?.mobile,
  address1: data?.result?.address1,
  address2: data?.result?.address2,
  suburb: data?.result?.suburb,
  stateDropdown: data?.result?.stateDropdown,
  state: data?.result?.state,
  postcode: data?.result?.postcode,
  password: data?.result?.password,
  confirmPassword: data?.result?.confirmPassword,
  emailsAllowed: data?.result?.emailsAllowed,
  showContactDetailsIfFreelanceArtist:
    data?.result?.showContactDetailsIfFreelanceArtist,
  ccBriefsubmissionId: data?.result?.ccBriefsubmissionId,
});

const toArtistProfile = (data: any): ArtistProfile => ({
  id: data?.result?.id ?? "",
  profileCode: data?.result?.profileCode ?? "",
  firstName: data?.result?.firstName ?? "",
  lastName: data?.result?.lastName ?? "",
  profileUrl: data?.result?.profileUrl ?? "",
  country: data?.result?.country ?? "",
  dateOfBirth: toDate(data?.result?.dateOfBirth),
  age: data?.result?.age ?? 0,
  gender: data?.result?.gender ?? "",
  ageRangeMax: data?.result?.ageRangeMax ?? 0,
  ageRangeMin: data?.result?.ageRangeMin ?? 0,
  culturalBackground: data?.result?.culturalBackground ?? [],
  baseLocation: data?.result?.baseLocation ?? "",
  otherLocation: data?.result?.otherLocation ?? "",
  height: data?.result?.height ?? 0,
  biography: data?.result?.biography ?? "",
  imdbUrl: data?.result?.imdbUrl ?? "",
  pressKitUrl: data?.result?.pressKitUrl ?? "",
  twitterUrl: data?.result?.twitterUrl ?? "",
  instagramUrl: data?.result?.instagramUrl ?? "",
  tikTokUrl: data?.result?.tikTokUrl ?? "",
  secondaryAgencyName: data?.result?.secondaryAgencyName ?? "",
  secondaryAgencyType: data?.result?.secondaryAgencyType ?? "",
  secondaryAgencyEmail: data?.result?.secondaryAgencyEmail ?? "",
  secondaryAgencyPhone: data?.result?.secondaryAgencyPhone ?? "",
  credits: data?.result?.credits ?? [],
  materials: data?.result?.materials ?? [],
  training: data?.result?.training ?? [],
  awards: data?.result?.awards ?? [],
  skills: data?.result?.skills ?? [],
  waistMeasurement: data?.result?.waistMeasurement ?? 0,
  chestAndBustMeasurement: data?.result?.chestAndBustMeasurement ?? 0,
  hipMeasurement: data?.result?.hipMeasurement ?? 0,
  clothingSize: data?.result?.clothingSize ?? "",
  dressSize: data?.result?.dressSize ?? "",
  suitSize: data?.result?.suitSize ?? "",
  pantSize: data?.result?.pantSize ?? "",
  shoeSize: data?.result?.shoeSize ?? 0,
  nationality: data?.result?.nationality ?? [],
  languages: data?.result?.languages ?? [],
  accents: data?.result?.accents ?? [],
  physicalFeatures: data?.result?.physicalFeatures ?? "",
  hairColor: data?.result?.hairColor ?? "",
  eyeColor: data?.result?.eyeColor ?? "",
  dietaryRequirements: data?.result?.dietaryRequirements ?? "",
  disability: data?.result?.disability ?? [],
  memberships: data?.result?.memberships ?? "",
  licenses: data?.result?.licenses ?? [],
  airlineLoyaltyInfo: data?.result?.airlineLoyaltyInfo ?? "",
  bankAccountBranchNumber: data?.result?.bankAccountBranchNumber ?? "",
  bankAccountName: data?.result?.bankAccountName ?? "",
  bankAccountNumber: data?.result?.bankAccountNumber ?? "",
  bankName: data?.result?.bankName ?? "",
  businessIdentifier: data?.result?.businessIdentifier ?? "",
  citizenship: data?.result?.citizenship ?? "",
  emergencyContactName: data?.result?.emergencyContactName ?? "",
  emergencyContactPhone: data?.result?.emergencyContactPhone ?? "",
  passportExpiry: toDate(data?.result?.passportExpiry),
  passportNumber: data?.result?.passportNumber ?? "",
  superFundAccountNumber: data?.result?.superFundAccountNumber ?? "",
  superFundName: data?.result?.superFundName ?? "",
  taxFileNumber: data?.result?.taxFileNumber ?? "",
  agencyEmail: data?.result?.agencyEmail ?? "",
  agencyName: data?.result?.agencyName ?? "",
  agencyPhone: data?.result?.agencyPhone ?? "",
  email: data?.result?.email ?? "",
  phone: data?.result?.phone ?? "",

  superChoiceDeclarationForm: parseProfileDocument(
    data?.result?.superChoiceDeclarationForm
  ),
  taxFileDeclarationForm: parseProfileDocument(
    data?.result?.taxFileDeclarationForm
  ),
  vaccinationCertificate: parseProfileDocument(
    data?.result?.vaccinationCertificate
  ),
  coverPhoto: parseArtistProfileImage(data?.result?.coverPhoto),
  headshots: data?.result?.headshots?.map(parseArtistProfileImage) ?? [],

  keyAttributesComplete: data?.result?.keyAttributesComplete ?? false,
  biographyComplete: data?.result?.biographyComplete ?? false,
  creditsComplete: data?.result?.creditsComplete ?? false,
  imagesComplete: data?.result?.imagesComplete ?? false,
  profileComplete: data?.result?.profileComplete ?? false,
  materialsComplete: data?.result?.materialsComplete ?? false,
  trainingComplete: data?.result?.trainingComplete ?? false,
  awardsComplete: data?.result?.awardsComplete ?? false,
  skillsComplete: data?.result?.skillsComplete ?? false,
  secondaryAgencyComplete: data?.result?.secondaryAgencyComplete ?? false,
  measurementsAndAttributesComplete:
    data?.result?.measurementsAndAttributesComplete ?? false,
  additionalInformationComplete:
    data?.result?.additionalInformationComplete ?? false,
  canViewMeasurements: data?.result?.canViewMeasurements ?? false,
  showContactDetailsIfFreelanceArtist:
    data?.result?.showContactDetailsIfFreelanceArtist ?? false,
  includedArtistLists: toArtistLists(data?.result?.includedArtistLists),
  isLiteTierArtist: data?.result?.isLiteTierArtist ?? false,
  pronouns: data?.result?.pronouns ?? "",
  isLapsedpayment: data?.result?.isLapsedpayment ?? false,
  isArtistInformed: data?.result?.isArtistInformed ?? false,
  isProfileRestrictedForExternalAccess:
    data?.result?.isProfileRestrictedForExternalAccess ?? false,
  measurementsAndAttributesLastEditedTime: toDate(
    data?.result?.measurementsAndAttributesLastEditedTime
  ),
});

const parseDirectoryArtistProfile = (data: any): DirectoryArtistProfile => ({
  id: data?.id ?? "",
  name: data?.name ?? "",
  gender: data?.gender ?? null,
  location: data?.location ?? null,
  height: data?.height ?? null,
  culturalBackground: data?.culturalBackground ?? null,
  ageRange: data?.ageRange ?? null,
  underEighteen: data?.underEighteen ?? false,
  image: data?.image ?? null,
  profileUrl: data?.profileUrl ?? null,
  contactAddress: data?.contactAddress ?? null,
  contactEmail: data?.contactEmail ?? null,
  contactPhone: data?.contactPhone ?? null,
  secondaryAgencyEmail: data?.secondaryAgencyEmail ?? null,
  secondaryAgencyName: data?.secondaryAgencyName ?? null,
  secondaryAgencyPhone: data?.secondaryAgencyPhone ?? null,
  profileComplete: data?.profileComplete ?? false,
  agent: data?.agent ?? null,
  agencyPhone: data?.agencyPhone ?? null,
  agencyEmail: data?.agencyEmail ?? null,
  includedArtistLists: toArtistLists(data?.includedArtistLists),
  isPremiumArtist: data?.isPremiumArtist ?? false,
  isLapsedpayment: data?.isLapsedpayment ?? false,
  locationAbbreviation: data?.locationAbbreviation ?? null,
});

const toDirectoryArtistProfiles = (data: any): DirectoryArtistProfile[] =>
  data?.map(parseDirectoryArtistProfile) ?? [];

const parseDirectoryAgentProfile = (data: any): AgentProfile => ({
  id: data?.id ?? "",
  firstName: data?.firstName ?? null,
  lastName: data?.lastName ?? null,
  email: data?.email ?? null,
  phone: data?.phone ?? null,
  jobTitle: data?.jobTitle ?? null,
});

const parseDirectoryAgencyProfile = (data: any): DirectoryAgencyProfile => ({
  id: data?.id ?? "",
  name: data?.name ?? "",
  location: data?.location ?? [],
  addressLine1: data?.addressLine1 ?? null,
  addressLine2: data?.addressLine2 ?? null,
  country: data?.country ?? null,
  phone: data?.phone ?? null,
  email: data?.email ?? null,
  suburb: data?.suburb ?? null,
  territory: data?.territory ?? null,
  webSiteUrl: data?.webSiteUrl ?? null,
  agents: data?.agents.map(parseDirectoryAgentProfile) ?? [],
  artists: data?.artists.map(parseDirectoryArtistProfile) ?? [],
  includedAgentLists: toAgencyLists(data?.includedAgentLists),
});

const parseDirectoryAgencyBasicProfile = (data: any): AgencyBasicDetails => ({
  id: data?.id ?? "",
  name: data?.name ?? "",
  location: data?.location ?? null,
  country: data?.country ?? null,
  territory: data?.territory ?? null,
  adminEmail: data?.adminEmail ?? null,
  phoneNumber: data?.phoneNumber ?? null,
});

const toDirectoryAgencyBasicProfiles = (data: any): AgencyBasicDetails[] =>
  data?.map(parseDirectoryAgencyBasicProfile) ?? [];

const parseDirectoryCastingOfficeMember = (
  data: any
): CastingMemberProfile => ({
  id: data?.id ?? "",
  firstName: data?.firstName ?? null,
  lastName: data?.lastName ?? null,
  email: data?.email ?? null,
  phone: data?.phone ?? null,
  jobTitle: data?.jobTitle ?? null,
});

const parseDirectoryCastingOfficeDetails = (
  data: any
): CastingOfficeDetails => ({
  id: data?.id ?? "",
  name: data?.name ?? "",
  location: data?.location ?? null,
  addressLine1: data?.addressLine1 ?? null,
  addressLine2: data?.addressLine2 ?? null,
  country: data?.country ?? null,
  phone: data?.phone ?? null,
  email: data?.email ?? null,
  suburb: data?.suburb ?? null,
  territory: data?.territory ?? null,
  webSiteUrl: data?.webSiteUrl ?? null,
  castingMembers:
    data?.castingMembers.map(parseDirectoryCastingOfficeMember) ?? [],
});

const parseDirectoryCastingOfficeBasicInfo = (
  data: any
): CastingOfficeBasicInfo => ({
  id: data?.id ?? "",
  name: data?.name ?? "",
  location: data?.location ?? null,
  country: data?.country ?? null,
  territory: data?.territory ?? null,
  adminEmail: data?.adminEmail ?? null,
});

const toDirectoryCastingOfficeBasicInfos = (
  data: any
): CastingOfficeBasicInfo[] =>
  data?.map(parseDirectoryCastingOfficeBasicInfo) ?? [];

const parseMessagingUser = (data: any): MessagingUser => ({
  id: data?.id ?? "",
  name: data?.name ?? "",
  image: data?.image,
});

const parseAgencyList = (data: any): AgencyLists => ({
  id: data?.id ?? "",
  name: data?.name ?? "",
});

const toAgencyLists = (data: any): AgencyLists[] =>
  data?.map(parseAgencyList) ?? [];

const parseArtistList = (data: any): ArtistLists => ({
  id: data?.id ?? "",
  name: data?.name ?? "",
  comms: data?.comms,
});

const toArtistLists = (data: any): ArtistLists[] =>
  data?.map(parseArtistList) ?? [];

const toNewListsResponse = (data: any): NewListResponse => ({
  id: data?.result ?? null,
  success: data?.success ?? false,
  message: data?.message ?? null,
});

const parseProjectSession = (data: any): ProjectSession => ({
  id: data?.id ?? "",
  date: toDate(data?.date),
  sessionDate: data?.result?.sessionDate ?? "",
  format: data?.format ?? "",
  name: data?.name ?? "",
  time: data?.time ?? "",
  sessionStartTimeUtc: data?.sessionStartTimeUtc ?? "",
  sessionEndTimeUtc: data?.sessionEndTimeUtc ?? "",
  timezone: data?.timezone ?? "",
  complete: data?.complete ?? false,
  location: data?.location ?? "",
});

const toProjectSessions = (data: any): ProjectSession[] =>
  data?.result?.map(parseProjectSession) ?? [];

const parseSessionTimelineItem = (data: any): SessionTimelineItem => ({
  id: data?.id ?? "",
  dateAndTime: toDate(data?.dateAndTime),
  artists:
    data?.artists?.map((a: any) => ({
      ...a,
      manualArtist: a.isManualArtist ?? false,
    })) ?? [],
  durationInMinutes: data?.durationInMinutes ?? 30,
  isBreak: data?.isBreak ?? false,
});

const parseProjectSessionDetails = (data: any): ProjectSessionDetails => ({
  id: data?.result?.id ?? "",
  version: data?.result?.version ?? 1,
  projectId: data?.result?.projectId ?? "",
  projectName: data?.result?.projectName ?? "",
  date: toDate(data?.result?.date),
  sessionDate: data?.result?.sessionDate ?? "",
  format: data?.result?.format ?? "",
  type: data?.result?.type ?? "",
  name: data?.result?.name ?? "",
  time: data?.result?.time ?? "",
  sessionStartTimeUtc: data?.result?.sessionStartTimeUtc ?? "",
  sessionEndTimeUtc: data?.result?.sessionEndTimeUtc ?? "",
  timezone: data?.result?.timezone ?? "",
  complete: data?.result?.complete ?? false,
  additionalNotes: data?.result?.additionalNotes ?? "",
  timeSlotDuration: data?.result?.timeSlotDuration ?? 0,
  location: data?.result?.location ?? "",
  timeline: data?.result?.timeline?.map(parseSessionTimelineItem) ?? [],
  videoCallUrl: data?.result.videoCallUrl ?? "",
});

const parseProjectSessionTimelineSnapshot = (
  data: any
): ProjectSessionTimelineSnapshot => ({
  timeline: data?.result?.timeline?.map(parseSessionTimelineItem) ?? [],
});

const parseManuallyUploadedClip = (data: any): DownloadedFile => ({
  id: data?.uploadedFileId ?? "",
  uploadedFileId: data?.uploadedFileId ?? "",
  fileName: data?.fileName ?? "",
});

const parseSessionTimeSlotDetails = (data: any): SessionTimeSlotDetails => ({
  id: data?.result?.id ?? "",
  projectId: data?.result?.projectId ?? "",
  projectName: data?.result?.projectName ?? "",
  projectOffice: data?.result?.projectOffice ?? "",
  sessionId: data?.result?.sessionId ?? "",
  dateAndTime: toDate(data?.result?.dateAndTime),
  format: data?.result?.format ?? "",
  type: data?.result?.type ?? "",
  name: data?.result?.name ?? "",
  timezone: data?.result?.timezone ?? "",
  timeSlotDuration: data?.result?.timeSlotDuration ?? 0,
  artists: data?.result?.artists ?? [],
  manuallyUploadedClips:
    data?.result?.manuallyUploadedClips?.map(parseManuallyUploadedClip) ?? [],
  userName:
    data?.result?.userName && data?.result?.userName !== null
      ? data?.result?.userName
      : undefined,
});

const toSessionStatus = (data: any): SessionStatus => ({
  success: data?.success ?? false,
  message: data?.message,
  id: data?.result?.id ?? "",
  hasStarted: data?.result?.hasStarted ?? false,
  isRecording: data?.result?.isRecording ?? false,
  agoraSession: data?.result?.agoraSession ?? null,
  conferenceId: data?.result?.conferenceId,
  users: data?.result?.users ?? null,
  waitingRoomUserStatus: data?.result?.waitingRoomUserStatus ?? null,
});

const parseArtistComments = (data: any): ArtistListComment[] | null => {
  const res =
    data?.map((x: any) => {
      const comment: ArtistListComment = {
        id: x.id,
        isNote: x.isNote,
        isAgent: x.isAgent,
        addedBy: x.addedBy,
        addedDate: toDate(x.addedDate) as Date,
        message: x.message,
        projectId: x.projectId ?? undefined,
        location: x.location ?? null,
        startDate: toDate(x.startDate),
        endDate: toDate(x.endDate),
        status: x.status,
        // Agent properties
        project: x.project ?? undefined,
        artist: x.artist,
        castingOffice: x.castingOffice,
        response: x.response,
        responseAuthor: x.responseAuthor,
        responseDate: x.responseDate,
        isResponse: x.isResponse ?? false,
        isArchived: x.isArchived ?? false,
        responses: x.responses?.map((y: any) => ({
          response: y.response,
          responseAuthor: y.responseAuthor,
          responseDate: toDate(y.responseDate),
          project: y.project ?? undefined,
          location: y.location ?? null,
          startDate: toDate(y.startDate),
          endDate: toDate(y.endDate),
        })),

        //Table view properties
        selected: false,
      };
      return comment;
    }) ?? null;
  return res;
};

const parseCdCommentNotifications = (
  data: any
): CdCommentNotification[] | null => {
  const res =
    data?.map((x: any) => {
      const notification: CdCommentNotification = {
        artistName: x.artistName,
        isAgent: x.isAgent,
        listName: x.listName,
        link: x.link,
      };
      return notification;
    }) ?? null;
  return res;
};

const parseListArtistProfile = (data: any): ListArtistProfile => {
  const groupBy = (contacts: NonAltaiArtistContactsItem[] | null) => {
    return contacts?.reduce((acc, x) => {
      const key = x.company ?? "Contact";
      return {
        ...acc,
        [key]: [...(acc[key] || []), x],
      };
    }, {} as Record<string, NonAltaiArtistContactsItem[]>);
  };

  return {
    id: data?.id ?? "",
    profileId: data?.profileId,
    nonAltaiProfileId: data?.nonAltaiProfileId,
    availability: data?.availability ?? availabilities[0],
    visible: data?.visible ?? true,
    firstName: data?.firstName ?? "",
    lastName: data?.lastName ?? "",
    name: `${data?.firstName ?? ""} ${data?.lastName ?? ""}`,
    gender: data?.gender ?? null,
    location: data?.location ?? null,
    culturalBackground: data?.culturalBackground ?? null,
    nationality: data?.nationality,
    profileUrl: data?.profileUrl ?? null,
    height: data?.height ?? null,
    ageRange: data?.ageRange ?? null,
    image: data?.image ?? null,
    materials: data?.materials ?? undefined,
    imdbUrl: data?.imdbUrl ?? null,
    biography: data?.biography ?? null,
    agent: data?.agent ?? null,
    agencyPhone: data?.agencyPhone ?? null,
    agencyEmail: data?.agencyEmail ?? null,
    secondaryAgencyEmail: data?.secondaryAgencyEmail ?? null,
    secondaryAgencyName: data?.secondaryAgencyName ?? null,
    secondaryAgencyPhone: data?.secondaryAgencyPhone ?? null,
    notes: data?.notes ?? null,
    notesAuthor: data?.notesAuthor ?? null,
    notesChanged: toDate(data?.notesChanged),
    contactCompany: data?.contactCompany ?? null,
    contactPhone: data?.contactPhone ?? null,
    contactEmail: data?.contactEmail ?? null,
    contacts: groupBy(data?.contacts) ?? null,
    comments: parseArtistComments(data?.comments),
    artistType: data.artistType,
    // properties below aren't used in artists list
    underEighteen: data?.underEighteen ?? false,
    contactAddress: data?.contactAddress ?? null,
    profileComplete: data?.profileComplete ?? false,
    includedArtistLists: toArtistLists(data?.includedArtistLists),
    isPremiumArtist: data?.isPremiumArtist ?? false,
    isLapsedpayment: data?.isLapsedpayment ?? false,
    locationAbbreviation: data?.locationAbbreviation ?? "",
  };
};

const parseArtistListDetails = (data: any): ArtistListDetails[] => {
  return data.map((x: ArtistListDetails) => {
    return {
      id: x.id ?? "",
      name: x.name ?? "",
      artists: x.artists.map(parseListArtistProfile),
      folders: parseArtistListDetails(x.folders),
      order: x.order,
      castingOffice: x.castingOffice,
      castingOfficeLogo: x.castingOfficeLogo,
    };
  });
};

const parseListAgencyProfile = (data: any): ListAgencyProfile => ({
  agencyId: data?.agencyId ?? "",
  country: data?.country ?? null,
  agencyName: data?.agencyName ?? null,
  location: data?.location ?? null,
  territory: data?.territory ?? null,
});

const parseAgencyListDetails = (data: any): AgencyListDetails => ({
  id: data?.id ?? "",
  name: data?.name ?? "",
  agencies: data?.agencies.map(parseListAgencyProfile) ?? [],
});

const toDeleteListResponse = (data: any): DeleteListResponse => ({
  result: data?.result ?? null,
  success: data?.success ?? false,
  message: data?.message ?? null,
});

const toDuplicateListResponse = (data: any): DuplicateListResponse => ({
  id: data?.result ?? null,
  success: data?.success ?? false,
  message: data?.message ?? null,
});

const toListResponse = (data: any): ListResponse => ({
  result: data?.result ?? null,
  success: data?.success ?? false,
});

const toMessageListResponse = (data: any): MessageListResponse => {
  console.log(data);
  return {
    result: data?.result ?? null,
    success: data?.success ?? false,
    message: data?.message ?? null,
  };
};

const toSelfTapeClipDetails = (data: any): SelfTapeClipDetails => ({
  id: data?.id ?? "",
  auditionId: data?.auditionId ?? "",
  src: data?.src ?? "",
  videoId: data?.videoId ?? "",
  title: data?.title ?? "",
  order: data?.order ?? null,
  reviewByAgent: data?.reviewByAgent ?? null,
  submittedToCasting: data?.submitToCd ?? false,
  trimInProgress: data?.trimInProgress ?? false,
  trimStart: data?.trimStart ?? null,
  trimEnd: data?.trimEnd ?? null,
  originalDuration: data?.originalDuration ?? null,
});

const parseNonAltaiArtistDetails = (data: any): NonAltaiArtistDetails => {
  const contacts =
    data?.contacts?.map((x: NonAltaiArtistContactsItem) => {
      return {
        ...x,
        id: x.id ?? uuidv4(),
      };
    }) ?? null;
  return {
    id: data?.id ?? null,
    availability: data?.availability ?? null,
    firstName: data?.firstName ?? null,
    lastName: data?.lastName ?? null,
    gender: data?.gender ?? null,
    baseLocation: data?.baseLocation ?? null,
    culturalBackground: data?.culturalBackground ?? null,
    nationality: data?.nationality ?? null,
    height: data?.height ?? null,
    age: data?.age ?? null,
    agencyName: data?.agencyName ?? null,
    profileUrl: data?.profileUrl ?? null,
    imdbUrl: data?.imdbUrl ?? null,
    headshotFileId: data?.headshotFileId ?? null,
    headshotFileName: data?.headshotFileName ?? null,
    headshotUrl: data?.headshotUrl ?? null,
    contacts: contacts,
    materials: data?.materials ?? null,
    internalNotes: data?.internalNotes ?? null,
    knownForNotes: data?.knownForNotes ?? null,
  };
};

const parseAltaiArtistDetails = (data: any): AltaiArtistDetails => ({
  id: data?.id ?? null,
  profileId: data?.id ?? null,
  nonAltaiArtistProfile: parseNonAltaiArtistDetails(
    data?.nonAltaiArtistProfile
  ),
  firstName: data?.firstName ?? null,
  lastName: data?.lastName ?? null,
  gender: data?.gender ?? null,
  baseLocation: data?.baseLocation ?? null,
  culturalBackground: data?.culturalBackground ?? [],
  nationality: data?.nationality ?? [],
  height: data?.height ?? null,
  age: data?.age ?? null,
  agencyName: data?.agencyName ?? null,
  profileUrl: data?.profileUrl ?? null,
  imdbUrl: data?.imdbUrl ?? null,
  headshotUrl: data?.headshotUrl ?? null,
  internalNotes: data?.internalNotes ?? null,
  knownForNotes: data?.knownForNotes ?? null,
});
const toCastingOfficeCalendar = (data: any): CastingOfficeCalendar => ({
  id: data?.sessionId ?? "",
  projectId: data?.projectId ?? "",
  sessionId: data?.sessionId ?? "",
  projectName: data?.projectName ?? "",
  sessionName: data?.sessionName ?? "",
  date: toDate(data?.date),
  format: data?.format ?? "",
  timeFrame: data?.timeFrame ?? "",
  timezoneName: data?.timezoneName ?? "",
});

const toAgencyCalendar = (data: any): AgencyCalendar => ({
  id: data?.Id ?? "",
  submissionId: data?.submissionId ?? "",
  date: toDate(data?.date),
  timeFrame: data?.timeFrame ?? "",
  artistName: data?.artistName ?? "",
  projectName: data?.projectName ?? "",
  castingOfficeName: data?.castingOfficeName ?? "",
  format: data?.format ?? "",
  timezoneName: data?.timezoneName ?? "",
});

const toArtistCalendar = (data: any): ArtistCalendar => ({
  id: data?.Id ?? "",
  submissionId: data?.submissionId ?? "",
  date: toDate(data?.date),
  timeFrame: data?.timeFrame ?? "",
  role: data?.role ?? "",
  projectName: data?.projectName ?? "",
  castingOfficeName: data?.castingOfficeName ?? "",
  format: data?.format ?? "",
  timezoneName: data?.timezoneName ?? "",
});

const toSelfTapeArtistProfile = (data: any): SelfTapeArtistProfile => ({
  id: data?.id ?? "",
  artistId: data?.artistId ?? "",
  underEighteen: data?.underEighteen ?? false,
  roles: data?.roles ?? [],
  roleIds: data?.roleIds ?? [],
  name: data?.name ?? "",
  status: data?.status ?? "",
  agencyNotes: data?.agencyNotes ?? "",
  castingNotes: data?.castingNotes ?? "",
  rating: data?.rating ?? 0,
  agent: data?.agent ?? "",
  gender: data?.gender ?? "",
  ageRange: data?.ageRange ?? "",
  location: data?.location ?? "",
  height: data?.height ?? "",
  culturalBackground: data?.culturalBackground ?? "",
  conflicts: data?.conflicts ?? [],
  image: data?.image ?? "",
  profileUrl: data?.profileUrl ?? "",
  selected: false,
  roleStatus: data?.roleStatus ?? "",
  locationAbbriviation: data?.locationAbbreviation ?? "",
});

const toSelfTapeArtistProfileWithRoles = (
  data: any
): SelfTapeArtistProfileWithRoles => ({
  roleId: data?.roleId ?? "",
  roleName: data?.roleName ?? "",
  assignArtistProfile: data?.assignArtistProfile ?? [],
});

const toSelfTapeRequestStatus = (data: any): SelfTapeRequestStatus => ({
  id: data?.selfTapeRequestId ?? "",
  selfTapeRequestId: data?.selfTapeRequestId ?? "",
  projectId: data?.projectId ?? "",
  roleId: data?.roleId ?? "",
  roleName: data?.roleName ?? "",
  sessionType: data?.sessionType ?? "",
  receivedCount: data?.receivedCount ?? 0,
  pendingCount: data?.pendingCount ?? 0,
  requestedCount: data?.requestedCount ?? 0,
  declinedCount: data?.declinedCount ?? 0,
  checked: false,
});

const toSelfTapeRequestBasicDetails = (
  data: any
): SelfTapeRequestBasicDetails => ({
  id: data?.id ?? "",
  projectId: data?.projectId ?? "",
  projectName: data?.projectName ?? "",
  roleId: data?.roleId ?? "",
  roleName: data?.roleName ?? "",
  sessionType: data?.sessionType ?? "",
  additionalNotes: data?.additionalNotes ?? "",
  selfTapeParticipantIds: data?.selfTapeParticipantIds ?? [],
});

const toSelfTapeArtistSession = (data: any): SelfTapeArtistSession => ({
  selfTapeRequestId: data?.selfTapeRequestId ?? "",
  selfTapeAuditionId: data?.selfTapeAuditionId ?? "",
  requestType: data?.requestType ?? "",
  status: data?.status ?? "",
  dateAndTime: toDate(data?.dateAndTime),
  timeFrame: data?.timeFrame ?? "",
  timezone: data?.timezone ?? "",
  declineReasons: data?.declineReasons ?? [],
  additionalNotes: data?.additionalNotes ?? "",
});

const toSelfTapeRequestArtistDetails = (
  data: any
): SelfTapeRequestArtistDetails => ({
  artistId: data?.artistId ?? "",
  name: data?.name ?? "",
  agent: data?.agent ?? "",
  gender: data?.gender ?? "",
  culturalBackground: data?.culturalBackground ?? "",
  height: data?.height ?? "",
  image: data?.image ?? "",
  location: data?.location ?? "",
  ageRange: data?.ageRange ?? "",
  underEighteen: data?.underEighteen ?? false,
  selfTapeArtistSessions:
    data?.selfTapeArtistSessions.map(toSelfTapeArtistSession) ?? [],
  locationAbbreviation: data?.locationAbbreviation ?? "",
});

const toSelfTapeRequestDetails = (data: any): SelfTapeRequestDetails => ({
  id: data?.id ?? "",
  projectId: data?.projectId ?? "",
  projectName: data?.projectName ?? "",
  roleName: data?.roleName ?? "",
  additionalNotes: data?.additionalNotes ?? "",
  selfTapeRequestedArtists:
    data?.selfTapeRequestedArtists.map(toSelfTapeRequestArtistDetails) ?? [],
});

const toChannelInfo = (data: any): ChannelInfo => ({
  id: data?.id ?? "",
  channelName: data?.channelName ?? "",
  enableDownloads: data?.enableDownloads ?? false,
  showComments: data?.showComments ?? false,
  projectId: data?.projectId ?? "",
  projectName: data?.projectName ?? "",
  companyName: data?.companyName ?? "",
  channelUrl: data?.channelUrl ?? "",
  companyBrandImageUrl: data?.companyBrandImageUrl,
  password: data?.password,
  expiryDate: toDate(data?.expiryDate),
  hideCommentsExternal: !data?.showCommentsExternal,
});

const toProjectBasicDetails = (data: any): ProjectBasicDetails => ({
  id: data?.id ?? "",
  title: data?.title ?? "",
  format: data?.format ?? "",
  type: data?.type ?? "",
});

const toChannelArtistList = (data: any): ChannelArtistList => ({
  channelArtistListId: data?.channelArtistListId ?? "",
  artistListId: data?.artistListId ?? "",
  artistListName: data?.artistListName ?? "",
  orderPosition: data?.orderPosition ?? "",
});

const toChannelListArtistComment = (data: any): ChannelListArtistComment => ({
  id: data?.id ?? "",
  addedBy: data?.addedBy ?? "",
  comment: data?.comment ?? "",
});

const toChannelListArtist = (data: any): ChannelListArtist => ({
  artistId: data?.artistId ?? "",
  artistListEntryId: data?.artistListEntryId ?? "",
  name: data?.name ?? "",
  image: data?.image ?? "",
  ageRange: data?.ageRange ?? "",
  underEighteen: data?.underEighteen ?? "",
  gender: data?.gender ?? "",
  height: data?.height ?? "",
  location: data?.location ?? "",
  culturalBackground: data?.culturalBackground ?? "",
  agent: data?.agent ?? "",
  nonAltaiArtist: data?.nonAltaiArtist ?? false,
  materialsUrl: data?.materialsUrl ?? "",
  imdbUrl: data?.imdbUrl ?? "",
  comments: data?.comments.map(toChannelListArtistComment) ?? [],
});

const toChannelArtistListDetails = (data: any): ChannelArtistListDetails => ({
  channelArtistListId: data?.channelArtistListId ?? "",
  artists: data?.artists.map(toChannelListArtist) ?? [],
});

const toChannelSessionInfo = (data: any): ChannelSessionInfo => ({
  id: data?.id ?? "",
  projectId: data?.projectId ?? "",
  sessionId: data?.sessionId ?? "",
  date: toDate(data?.date),
  timeFrame: data?.timeFrame ?? "",
  projectName: data?.projectName ?? "",
  format: data?.format ?? "",
  sessionName: data?.sessionName ?? "",
  timezoneName: data?.timezoneName ?? "",
  startTime: data?.startTime ?? "",
  endTime: data?.endTime ?? "",
});

const toAgencyUserHomePageDetails = (data: any): AgencyUserHomePageDetails => ({
  firstName: data?.firstName ?? "",
  lastName: data?.lastName ?? "",
  firstVisitDate: data?.firstVisitDate ?? "",
  calendarItemCount: data?.calendarItemCount ?? 0,
  activeCastingBriefCount: data?.activeCastingBriefCount ?? 0,
  newCastingBriefCount: data?.newCastingBriefCount ?? 0,
  newStatusReportUpdateCount: data?.newStatusReportUpdateCount ?? 0,
});

const toArtistHomePageDetails = (data: any): ArtistHomePageDetails => ({
  artistId: data?.artistId ?? "",
  profileCode: data?.profileCode ?? "",
  firstName: data?.firstName ?? "",
  lastName: data?.lastName ?? "",
  firstVisitDate: data?.firstVisitDate ?? "",
  calendarItemCount: data?.calendarItemCount ?? 0,
  newStatusReportUpdateCount: data?.newStatusReportUpdateCount ?? 0,
  isLiteTierArtist: data?.isLiteTierArtist ?? false,
  isRepresented: data?.isRepresented ?? false,
  isProfileCompleted: data?.isProfileCompleted ?? false,
});

const toCdHomePageDetails = (data: any): CdHomePageDetails => ({
  firstName: data?.firstName ?? "",
  lastName: data?.lastName ?? "",
  firstVisitDate: data?.firstVisitDate ?? "",
  calendarItemCount: data?.calendarItemCount ?? 0,
  activeProjectCount: data?.activeProjectCount ?? 0,
  projectCount: data?.projectCount ?? 0,
  unreadNotificationCount: data?.unreadNotificationCount ?? 0,
  mostRecentProjectId: data?.mostRecentProjectId ?? 0,
  mostRecentProjectName: data?.mostRecentProjectName ?? 0,
  secondMostRecentProjectId: data?.secondMostRecentProjectId ?? 0,
  secondMostRecentProjectName: data?.secondMostRecentProjectName ?? 0,
});

const toTapeResponse = (data: any): TapeResponse => ({
  id: data?.result?.id ?? "",
  videoId: data?.result?.videoId,
  commentsUserName: data?.result?.commentsUserName ?? "",
  projectName: data?.result?.projectName ?? "",
  sessionName: data?.result?.sessionName ?? "",
  channelName: data?.result?.channelName ?? "",
  folderName: data?.result?.folderName ?? "",
  clips: data?.result?.clips ?? [],
  nextPrevLinks: data?.result?.nextPrevLinks ?? {
    nextLink: null,
    prevLink: null,
  },
  isSelfTape: data?.result?.isSelfTape ?? false,
  isManualTape: data?.result?.isManualTape ?? false,
  isCcBriefSubmissionTape: data?.result?.isCcBriefSubmissionTape ?? false,
});

const toStatusReportTapePanelDetailsResponse = (
  data: any
): StatusReportTapePanelDetailsResponse => ({
  clips: data?.result?.clips ?? [],
  isSelfTape: data?.result?.isSelfTape ?? false,
  isManualTape: data?.result?.isManualTape ?? false,
  isCcBriefSubmissionTape: data?.result?.isCcBriefSubmissionTape ?? false,
  isSharedInChannels: data?.result?.isSharedInChannels ?? false,
  cdSetTapesAsViewed: data?.result?.cdSetTapesAsViewed ?? false,
});

const parseCommentItem = (data: any): CommentItem => ({
  id: data?.id ?? "",
  authorId: data?.authorId ?? "",
  authorName: data?.authorName ?? "",
  createdAt: toDate(data?.createdAt) ?? new Date(),
  message: data?.message ?? "",
  isCastingComment: data?.isCastingComment ?? false,
});

const parseAuditionMessageHistory = (data: any): AuditionMessageHistory => ({
  id: data?.id ?? "",
  addedBy: data?.addedBy ?? "",
  createdAt: toDate(data?.createdAt),
  message: data?.message ?? "",
  tenantName: data?.tenantName ?? "",
  auditionData:
    data.auditionData && data.auditionData !== null
      ? {
          id: data.auditionData.id,
          auditionFormat: data.auditionData.auditionFormat,
          dateAndTimeUtc: toDate(data.auditionData.dateAndTimeUtc),
          timeFrame: data.auditionData.timeFrame,
          location: data.auditionData.location,
        }
      : null,
});

const parseAuditionMessageHistoryGroup = (
  data: any
): AuditionMessageHistoryGroup => ({
  id: data?.id ?? "",
  auditionName: data?.auditionName ?? "",
  messages: data?.messages?.map(parseAuditionMessageHistory) ?? [],
});

const toCdStatusReportProfile = (data: any): ProjectSelectedArtist => ({
  id: data?.id ?? "",
  artistId: data?.artistId ?? "",
  underEighteen: data?.underEighteen ?? false,
  name: data?.name ?? "",
  rating: data?.rating ?? 0,
  agent: data?.agent ?? "",
  agentEmail: data?.agentEmail ?? "",
  agentPhone: data?.agentPhone ?? "",
  image: data?.image ?? "",
  profileUrl: data?.profileUrl ?? "",
  roles: data?.roles ?? [],
  status: data?.roleStatus ?? "selected",
  requiresNda: data?.requiresNda ?? false,
  agencyNotes: data?.agencyNotes ?? "",
  ageRange: data?.ageRange ?? "",
  castingNotes: data?.castingNotes ?? "",
  culturalBackground: data?.culturalBackground ?? "",
  gender: data?.gender ?? "",
  height: data?.height ?? "",
  location: data?.location ?? "",
  message: data?.message ?? "",
  timeSlotId: data?.timeSlotId ?? "",
  pronouns: data?.pronouns ?? "",
  roleStatus: data?.roleStatus ?? "",
  conflicts: data?.conflicts ?? [],
  hasWardrobeForm: data?.hasWardrobeForm ?? false,
  docs: data?.docs ?? [],
  completedWardrobeForms:
    data?.completedWardrobeForms?.map(parseCompletedWardrobeFromOverviews) ??
    [],
  comments: data?.comments?.map(parseCommentItem) ?? [],
  messageHistories: [],
  auditionMessageHistory:
    data?.messageHistory?.map(parseAuditionMessageHistoryGroup) ?? [],
  nationality: data?.nationality ?? [],
  manualArtist: data?.manualArtist ?? false,
  ccBriefSubmissionArtist: data?.ccBriefSubmissionArtist ?? false,
  hideComments: data?.hideComments ?? false,
  ccBriefDetails: data?.ccBriefDetails,
  isScheduledForAudition: data?.isScheduledForAudition ?? false,
  cdAgentCommunicationEnabled: data?.cdAgentCommunicationEnabled ?? false,
  cdArtistCommunicationEnabled: data?.cdArtistCommunicationEnabled ?? false,
  isFreelanceArtist: data?.isFreelanceArtist ?? false,
  locationAbbreviation: null,
});

const toCdStatusReportProfileWithCompletedArtistForms = (
  data: any
): StatusReportTapePanelProfile => ({
  id: data?.id ?? "",
  artistId: data?.artistId ?? "",
  underEighteen: data?.underEighteen ?? false,
  name: data?.name ?? "",
  rating: data?.rating ?? 0,
  agent: data?.agent ?? "",
  agentEmail: data?.agentEmail ?? "",
  agentPhone: data?.agentPhone ?? "",
  image: data?.image ?? "",
  profileUrl: data?.profileUrl ?? "",
  roles: data?.roles ?? [],
  roleIds: data?.roleIds ?? [],
  status: data?.roleStatus ?? "selected",
  requiresNda: data?.requiresNda ?? false,
  agencyNotes: data?.agencyNotes ?? "",
  ageRange: data?.ageRange ?? "",
  castingNotes: data?.castingNotes ?? "",
  culturalBackground: data?.culturalBackground ?? "",
  gender: data?.gender ?? "",
  height: data?.height ?? "",
  location: data?.location ?? "",
  message: data?.message ?? "",
  timeSlotId: data?.timeSlotId ?? "",
  imdbUrl: data?.imdbUrl ?? "",
  pronouns: data?.pronouns ?? "",
  roleStatus: data?.roleStatus ?? "",
  conflicts: data?.conflicts ?? [],
  hasWardrobeForm: data?.hasWardrobeForm ?? false,
  docs: data?.documents ?? [],
  imageDocuments: data?.imageDocuments ?? [],
  tapeCompletedDate: toDate(data?.tapeCompletedDate),
  completedWardrobeForm:
    data?.completedWardrobeForms && data?.completedWardrobeForms?.length > 0
      ? parseCompletedWardrobeFormDetailsFromOverview(
          data.completedWardrobeForms[0]
        )
      : null,
  comments: data?.comments?.map(parseCommentItem) ?? [],
  messageHistories: [],
  auditionMessageHistory:
    data?.messageHistory?.map(parseAuditionMessageHistoryGroup) ?? [],
  nationality: data?.nationality ?? [],
  manualArtist: data?.manualArtist ?? false,
  ccBriefSubmissionArtist: data?.ccBriefSubmissionArtist ?? false,
  ccBriefSubmissionDetail: data?.ccBriefSubmissionDetail ?? null,
  hideComments: data?.hideComments ?? false,
  isScheduledForAudition: data?.isScheduledForAudition ?? false,
  cdAgentCommunicationEnabled: data?.cdAgentCommunicationEnabled ?? false,
  cdArtistCommunicationEnabled: data?.cdArtistCommunicationEnabled ?? false,
  isFreelanceArtist: data?.isFreelanceArtist ?? false,
  locationAbbreviation: null,
});

const parseAuditionStatus = (data: any): StatusReportAuditionStatus => ({
  id: data?.id ?? "",
  dueBy: data?.dueBy ?? 0,
  label: data?.label ?? "",
  sessionType: data?.sessionType ?? "",
  selfTape: data?.selfTape ?? false,
  dateAndTime: toDate(data?.dateAndTime),
  timeFrame: data?.timeFrame ?? "",
  timezone: data?.timezone ?? "",
});

const parseStatusReportProfileArtist = (
  data: any
): StatusReportProfileArtist => ({
  id: data?.id ?? "",
  artistId: data?.artistId ?? "",
  underEighteen: data?.underEighteen ?? false,
  name: data?.name ?? "",
  rating: data?.rating ?? 0,
  agent: data?.agent ?? "",
  agentEmail: data?.agentEmail ?? data?.agencyEmail ?? "",
  agentPhone: data?.agentPhone ?? data?.agencyPhone ?? "",
  agencyPhone: data?.agencyPhone ?? "",
  agencyEmail: data?.agencyEmail ?? "",
  agencyAdminName: data?.agencyAdminName ?? "",
  image: data?.image ?? "",
  profileUrl: data?.profileUrl ?? "",
  roles: data?.roles ?? [],
  roleIds: data.roleIds ?? [],
  status: data?.roleStatus ?? "selected",
  agencyNotes: data?.agencyNotes ?? "",
  ageRange: data?.ageRange ?? "",
  castingNotes: data?.castingNotes ?? "",
  culturalBackground: data?.culturalBackground ?? "",
  gender: data?.gender ?? "",
  height: data?.height ?? "",
  location: data?.location ?? "",
  message: data?.message ?? "",
  timeSlotId: data?.timeSlotId ?? "",
  pronouns: data?.pronouns ?? "",
  city: data?.city ?? "",
  roleStatus: data?.roleStatus ?? "",
  conflicts: data?.conflicts ?? [],
  comments: data?.comments?.map(parseCommentItem) ?? [],
  auditionStatus: data?.auditionStatus?.map(parseAuditionStatus) ?? [],
  hasCastingComments: data?.hasCastingComments ?? false,
  hasClientComments: data?.hasClientComments ?? false,
  isOtherLocation: data?.isOtherLocation ?? false,
  messageHistories: [],
  auditionMessageHistory:
    data?.messageHistory?.map(parseAuditionMessageHistoryGroup) ?? [],
  nationality: data?.nationality ?? [],
  isManualArtist: data?.isManualArtist ?? false,
  isCcBriefSubmission: data?.isCcBriefSubmission ?? false,
  isArtistEmailAvailable: data?.isArtistEmailAvailable ?? false,
  hasRoleStatus: data?.hasRoleStatus ?? false,
  hasWardrobeForm: data?.hasWardrobeForm ?? false,
  requiresNda: data?.requiresNda ?? false,
  docs: data?.docs ?? [],
  completedWardrobeForms:
    data?.completedWardrobeForms?.map(parseCompletedWardrobeFromOverviews) ??
    [],
  isScheduledForAudition: data?.isScheduledForAudition ?? false,
  enableForMessaging: data?.enableForMessaging ?? false,
  profileSharedInChannels: data?.profileSharedInChannels ?? false,
  cdAgentCommunicationEnabled: data?.cdAgentCommunicationEnabled ?? false,
  cdArtistCommunicationEnabled: data?.cdArtistCommunicationEnabled ?? false,
  isFreelanceArtist: data?.isFreelanceArtist ?? false,
  locationAbbreviation: null,
});

const toStatusReportManageStatusRoles = (
  data: any
): StatusReportManageStatusRole => ({
  id: data?.id ?? "",
  title: data?.title ?? "",
  profileCount: data?.profileCount ?? 0,
  list: data?.list?.map(parseStatusReportProfileArtist) ?? [],
});

const toUploadedRoleStatusDoc = (data: any): UploadedFile => ({
  id: data?.result?.id ?? "",
  name: data?.result?.name ?? "",
});

const toManualArtistEditResponse = (data: any): ManualArtistEditResponse => ({
  projectName: data?.result?.projectName ?? "",
  tapeName: data?.result?.tapeName ?? "",
  artist: {
    id: data?.result?.artist.id ?? "",
    role: data?.result?.artist.roleId ?? "",
    status: data?.result?.artist.status ?? "",
    firstName: data?.result?.artist.firstName ?? "",
    lastName: data?.result?.artist.lastName ?? "",
    gender: data?.result?.artist.gender ?? "",
    location: data?.result?.artist.location ?? "",
    culturalBackground:
      data?.result?.artist.culturalBackground?.map(toAutocompleteItem) ?? [],
    height: data?.result?.artist.height ?? "",
    ageRange: data?.result?.artist.ageRange ?? "",
    agencyName: data?.result?.artist.agencyName ?? "",
    profileUrl: data?.result?.artist.profileUrl ?? "",
    documents:
      data?.result?.artist.documents?.map((d: any) => ({
        id: d.uploadedFileId ?? "",
        name: d.fileName ?? "",
      })) ?? [],
    headshots:
      data?.result?.artist.headshots?.map((d: any) => ({
        id: d.uploadedFileId ?? "",
        name: d.fileName ?? "",
      })) ?? [],
    comments: [],
  },
});

const toManualTapeCollectionDetails = (
  data: any
): ManualTapeCollectionDetails => ({
  id: data?.result?.id ?? "",
  projectId: data?.result?.projectId ?? "",
  projectName: data?.result?.projectName ?? "",
  tapeName: data?.result?.tapeName ?? "",
  clips:
    data?.result?.clips?.map((c: any) => ({
      id: c.uploadedFileId ?? "",
      uploadedFileId: c.uploadedFileId ?? "",
      fileName: c.fileName ?? "",
    })) ?? [],
  artists:
    data?.result?.artists?.map((a: any) => ({
      id: a.id ?? "",
      role: a.roleId ?? "",
      status: a.status ?? "",
      firstName: a.firstName ?? "",
      lastName: a.lastName ?? "",
      gender: a.gender ?? "",
      location: a.location ?? "",
      culturalBackground: a.culturalBackground?.map(toAutocompleteItem) ?? [],
      height: a.height ?? "",
      ageRange: a.ageRange ?? "",
      agencyName: a.agencyName ?? "",
      profileUrl: a.profileUrl ?? "",
      documents:
        a.documents?.map((d: any) => ({
          id: d.uploadedFileId ?? "",
          name: d.fileName ?? "",
        })) ?? [],
      headshots:
        a.headshots?.map((d: any) => ({
          id: d.uploadedFileId ?? "",
          name: d.fileName ?? "",
        })) ?? [],
    })) ?? [],
});

const toWardrobeToPdfDetails = (data: any): WardrobeToPdfDetails => ({
  roleName: data?.result?.roleName ?? "",
  projectName: data?.result?.projectName ?? "",
  castingOfficeName: data?.result?.castingOfficeName ?? "",
  castingOfficeLogoUrl: data?.result?.castingOfficeLogoUrl ?? "",
  artistName: data?.result?.artistName ?? "",
  completedByAgency: data?.result?.completedByAgency ?? null,
  completedBy: data?.result?.completedBy ?? "",
  artistImageUrl: data?.result?.artistImageUrl ?? "",
  artistSignatureUrl: data?.result?.artistSignatureUrl ?? "",
  agencyName: data?.result?.agencyName ?? "",
  agencyPhone: data?.result?.agencyPhone ?? "",
  agencyEmail: data?.result?.agencyEmail ?? "",
  completedDate: data?.result?.completedDate ?? "",
  preFilledArtistFields: data?.result?.preFilledArtistFields ?? [],
  answers: data?.result?.answers ?? [],
});

const parseCcBriefFormQuestion = (data: any): CcBriefFormQuestion => ({
  questionId: data?.questionId ?? "",
  questionText: data?.questionText ?? "",
  commonRequest: data?.commonRequest ?? false,
  fileUpload: data?.fileUpload ?? false,
  formalAcknowledgement: data?.formalAcknowledgement ?? false,
  profileImage: data?.profileImage ?? false,
  answerRequired: data?.answerRequired ?? false,
});

const parseCcBriefFormFile = (data: any): CcBriefFormFile => ({
  fileName: data?.fileName ?? "",
  fileUrl: data?.fileUrl ?? "",
  uploadedFileId: data?.uploadedFileId ?? "",
  videoClipId: data?.videoClipId ?? null,
});

const toCcBriefFormDetails = (data: any): CcBriefFormDetails => ({
  ccBriefId: data?.result?.ccBriefId ?? "",
  ccBriefName: data?.result?.ccBriefName ?? "",
  projectId: data?.result?.projectId ?? "",
  projectName: data?.result?.projectName ?? "",
  castingOfficeName: data?.result?.castingOfficeName ?? "",
  castingOfficeLogo: data?.result?.castingOfficeLogo,
  roleId: data?.result?.roleId,
  ccBriefFormId: data?.result?.ccBriefFormId ?? "",
  note: data?.result?.note ?? "",
  dueByDateAndTimeUTC:
    data?.result?.dueByDateTimeUtc !== null &&
    data?.result?.dueByTimezone !== null
      ? new Date(
          moment
            .utc(data?.result?.dueByDateTimeUtc)
            .tz(data?.result?.dueByTimezone)
            .format("YYYY/MM/DD HH:mm:ss")
        )
      : null,
  dueByTimezone: data?.result?.dueByTimezone ?? null,
  dueByTimezoneAbbreviation: data?.result?.dueByTimezoneAbbreviation ?? null,
  shareProjectDetails: data?.result?.shareProjectDetails ?? false,
  shareRoleDetails: data?.result?.shareRoleDetails ?? false,
  artistDetailFields:
    data?.result?.artistDetailFields.map(toAutocompleteItem) ?? [],
  ccBriefFormQuestions:
    data?.result?.ccBriefFormQuestions?.map(parseCcBriefFormQuestion) ?? [],
  ccBriefFormFiles:
    data?.result?.ccBriefFormFiles?.map(parseCcBriefFormFile) ?? [],
});

const toCcBriefProjectAndRoleDetails = (
  data: any
): CcBriefProjectAndRoleDetails => ({
  projectDetails: data?.result?.projectDetails
    ? parseProjectDetails(data?.result?.projectDetails)
    : null,
  roleDetails: data?.result?.roleDetails
    ? parseRoleDetails(data?.result?.roleDetails)
    : null,
});

const toCcBriefSubmissionDetails = (data: any): CcBriefSubmissionDetail => ({
  id: data?.result?.id ?? "",
  ccBriefId: data?.result?.ccBriefId ?? "",
  artistName: data?.result?.artistName ?? "",
  completedDate: data?.result?.completedDate ?? "",
  projectName: data?.result?.projectName ?? "",
  roleName: data?.result?.roleName ?? "",
  preFilledArtistFields: data?.result?.preFilledArtistFields ?? [],
  answers: data?.result?.answers ?? [],
  artistUploadedFiles: data?.result?.artistUploadedFiles ?? [],
  isArtistEmailAvailable: data?.result?.isArtistEmailAvailable ?? false,
  hasProjectAndRoleDetailsShared:
    data?.result?.hasProjectAndRoleDetailsShared ?? false,
});

const toCcBriefSubmissionPdfDetail = (
  data: any
): CcBriefSubmissionPdfDetail => ({
  id: data?.result?.id ?? "",
  ccBriefId: data?.result?.ccBriefId ?? "",
  artistName: data?.result?.artistName ?? "",
  artistImageUrl: data?.result?.artistImageUrl ?? "",
  castingOfficeLogoUrl: data?.result?.castingOfficeLogoUrl ?? "",
  castingOfficeName: data?.result?.castingOfficeName ?? "",
  completedDate: data?.result?.completedDate ?? "",
  projectName: data?.result?.projectName ?? "",
  roleName: data?.result?.roleName ?? "",
  preFilledArtistFields: data?.result?.preFilledArtistFields ?? [],
  answers: data?.result?.answers ?? [],
  artistUploadedFiles: data?.result?.artistUploadedFiles ?? [],
  isArtistEmailAvailable: data?.result?.isArtistEmailAvailable ?? false,
  hasProjectAndRoleDetailsShared:
    data?.result?.hasProjectAndRoleDetailsShared ?? false,
});

const toWaitingRoomItems = (data: any): WaitingRoomItem[] =>
  data?.result?.map(parseWaitingRoomItem) ?? [];

const parseWaitingRoomItem = (data: any): WaitingRoomItem => ({
  id: data?.id ?? "",
  selected: data?.selected,
  waiting: parseWaitingRoomUser(data?.waiting),
});

const parseWaitingRoomUser = (data: any): WaitingRoomUser => ({
  status: data?.status ?? "waiting",
  uid: data?.uid ?? "",
  userType: data?.userType ?? null,
  profile: data?.profile,
  timeSlot: toDate(data?.timeSlot),
});

const toStatusReportThumbnailsRow = (data: any): StatusReportThumbnailsRow => ({
  id: data.folderId ?? "",
  title: data.folderTitle ?? "",
  artistNumber: data.tapesCount,
  list: [],
  isRowExpanded: false,
  isMediaFolder: data.isMediaFolder ?? false,
});

const toStatusReportTableItem = (data: any): StatusReportTableItem => ({
  id: data.id,
  artistName: data.artistName,
  projectName: data.projectName,
  roleName: data.roleName,
  projectType: data.projectType,
  responseType: data.responseType || null,
  responseTimeRemainingInMinutes: data.responseTimeRemainingInMinutes,
  castingOfficeName: data.castingOfficeName,
  sessionId: data.sessionId,
  sessionFormat: data.sessionFormat,
  sessionType: data.sessionType,
  sessionStatusStage: data.sessionStatusStage,
  sessionDateAndTimeUTC: toDate(data.sessionDateAndTimeUTC),
  wardrobeNeeded: data.wardrobeNeeded ?? false,
  docsNeeded: data.docsNeeded ?? false,
  isNew: data.isNew ?? false,
  isMessage: data.isMessage ?? false,
  roleStatusId: data.roleStatusId,
  requestRoleStatus: data.requestRoleStatus,
  latestRequestDateUtc: toDate(data.latestRequestDateUtc),
});

const toCcSubmissionFollowUpMessage = (
  data: any
): CcSubmissionFollowUpMessage => ({
  id: data.id,
  authorName: data.authorName,
  confirmationRequired: data.confirmationRequired,
  followUpDocuments: data.followUpDocuments,
  message: data.message,
  submittedAt: toDate(data.submittedAt),
  auditionData:
    data.auditionData && data.auditionData !== null
      ? {
          id: data.auditionData.id,
          format: data.auditionData.format,
          dateAndTimeUtc: toDate(data.auditionData.dateAndTimeUtc),
          timeFrame: data.auditionData.timeFrame,
          timezone: data.auditionData.timezone,
          location: data.auditionData.location,
        }
      : null,
});

/********************************************** */
// registration
/********************************************** */

export const submitCastingDirectorRegistration = async (
  values: any
): Promise<CreateProjectResponse> => {
  const path = "/users/register/castingdirector";
  const { data } = await base.postAnonymous({
    path,
    data: values,
    variant: "users",
  });
  return toResponse(data);
};

export const submitAgencyRegistration = async (
  values: any
): Promise<CreateProjectResponse> => {
  const path = "/users/register/agency";
  const { data } = await base.postAnonymous({
    path,
    data: values,
    variant: "users",
  });
  return toResponse(data);
};

export const submitArtistRegistration = async (
  values: any
): Promise<CreateProjectResponse> => {
  const path = "/users/register/artist";
  const { data } = await base.postAnonymous({
    path,
    data: values,
    variant: "users",
  });
  return toResponse(data);
};

export const validateConfirmationToken = async (
  token: string
): Promise<UserDetailsResponse> => {
  const path = `/users/register/castingdirector/validateconfirm?token=${token}`;
  const { data } = await base.getAnonymous({
    path,
    variant: "users",
  });
  return toUserDetailsResponse(data);
};

export const validateArtistConfirmationToken = async (
  token: string
): Promise<UserDetailsResponse> => {
  const path = `/users/register/artist/validateconfirm?token=${token}`;
  const { data } = await base.getAnonymous({
    path,
    variant: "users",
  });
  return toUserDetailsResponse(data);
};

export const confirmCastingDirectorAdmin = async (
  token: string,
  password: string
): Promise<CreateProjectResponse> => {
  const path = "/users/register/castingdirector/confirm";
  const { data } = await base.postAnonymous({
    path,
    data: {
      confirmationToken: token,
      password: password,
    },
    variant: "users",
  });
  return toResponse(data);
};

export const confirmArtistInvitationFromAgent = async (
  token: string,
  password: string
): Promise<CreateProjectResponse> => {
  const path = "/users/register/artist/confirm";
  const { data } = await base.postAnonymous({
    path,
    data: {
      confirmationToken: token,
      password: password,
    },
    variant: "users",
  });
  return toResponse(data);
};

export const confirmAgencyAdmin = async (
  token: string,
  password: string
): Promise<CreateProjectResponse> => {
  const path = "/users/register/agency/confirm";
  const { data } = await base.postAnonymous({
    path,
    data: {
      confirmationToken: token,
      password: password,
    },
    variant: "users",
  });
  return toResponse(data);
};

export const confirmCastingDirectorColleague = async (
  token: string,
  values: ColleagueConfirmation
): Promise<CreateProjectResponse> => {
  const path = "/users/register/castingdirector/confirmcolleague";
  const { data } = await base.postAnonymous({
    path,
    data: {
      confirmationToken: token,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      jobTitle: values.jobTitle,
      mobile: values.mobile,
    },
    variant: "users",
  });
  return toResponse(data);
};

export const confirmAgencyColleague = async (
  token: string,
  values: ColleagueConfirmation
): Promise<CreateProjectResponse> => {
  const path = "/users/register/agency/confirmcolleague";
  const { data } = await base.postAnonymous({
    path,
    data: {
      confirmationToken: token,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      jobTitle: values.jobTitle,
      mobile: values.mobile,
    },
    variant: "users",
  });
  return toResponse(data);
};

export const agenciesReserveAgencyId =
  async (): Promise<CreateProjectResponse> => {
    const path = "/users/register/agency/reserve";
    const { data } = await base.getAnonymous({ path, variant: "users" });
    return toResponse(data);
  };

/********************************************** */
// Account management
/********************************************** */
export const getPaymentStatus = async (): Promise<PaymentStatus> => {
  const path = "/users/account/paymentstatus";
  const { data } = await base.get({ path, variant: "users" });
  return toPaymentStatus(data);
};

export const getAgencyArtistInvitations = async (): Promise<
  AgencyInvitationTableItem[]
> => {
  const path = "/users/invitations/agency/artists";
  const { data } = await base.get({
    path,
    variant: "users",
  });
  return toAgencyInvitationTableItems(data);
};

export const inviteAgencyArtists = async (
  emails: string[]
): Promise<InviteAgencyArtistsResponse> => {
  const path = "/users/invitations/agency/artists";
  const { data } = await base.post({
    path,
    data: {
      emails: emails,
    },
    variant: "users",
  });
  return toInviteAgencyArtistsResponse(data);
};

export const checkEmailExist = async (
  email: string
): Promise<boolean | null> => {
  const path = "/users/register/check-email-exist";
  const { data } = await base.post({
    path,
    data: {
      email: email,
    },
    variant: "users",
  });
  return data?.result;
};

export const agentRegisterArtist = async (
  requestData: any
): Promise<CreateProjectResponse> => {
  const path = "/users/register/agency/artist";
  const { data } = await base.post({
    path,
    data: requestData,
    variant: "users",
  });
  return toResponse(data);
};

export const invitaionToArtistToActivateAccount = async (
  requestData: InviteArtistsToActivateAccountRequest
): Promise<boolean> => {
  const path = "/users/invitations/agency/artist/activateAccount";
  const { data } = await base.post({
    path,
    data: requestData,
    variant: "users",
  });
  return data?.result ?? false;
};

export const getAgencyRoster = async (
  filters: ArtistFilters | null
): Promise<AgencyRosterProfile[]> => {
  let query: string = "";
  query = filters?.artistName ? `artistName=${filters.artistName}&` : "";
  query += filters?.agencyName ? `agencyName=${filters.agencyName}&` : "";
  query += filters?.minAge ? `minAge=${filters.minAge}&` : "";
  query +=
    filters?.maxAge && filters?.maxAge !== 120
      ? `maxAge=${filters.maxAge}&`
      : "";
  query += filters?.minHeight ? `minHeight=${filters.minHeight}&` : "";
  query +=
    filters?.maxHeight && filters?.maxHeight !== 220
      ? `maxHeight=${filters.maxHeight}&`
      : "";
  query += filters?.baseLocation ? `locations=${filters.baseLocation}&` : "";
  query += filters?.skills ? `skills=${filters.skills}&` : "";
  query += filters?.gender ? `gender=${filters.gender}&` : "";
  query += filters?.representation
    ? `representation=${filters.representation}&`
    : "";
  query += filters?.nationalities
    ? `nationalities=${filters.nationalities}&`
    : "";
  query += filters?.languages ? `languages=${filters.languages}&` : "";
  query += filters?.disabilities ? `disabilities=${filters.disabilities}&` : "";
  query += filters?.accents ? `accents=${filters.accents}&` : "";
  query += filters?.eyeColours ? `eyeColours=${filters.eyeColours}&` : "";
  query += filters?.hairColours ? `hairColours=${filters.hairColours}&` : "";
  query += filters?.culturalBackgrounds
    ? `culturalBackgrounds=${filters.culturalBackgrounds}&`
    : "";

  const path = `/users/roster?${query}`;

  const { data } = await base.get({ path, variant: "users" });
  return toRosterArtists(data);
};

export const releaseAgencyArtist = async (
  artistId: string
): Promise<CreateProjectResponse> => {
  const path = `/users/roster/release/${artistId}`;
  const { data } = await base.post({
    path,
    data: null,
    variant: "users",
  });
  return toResponse(data);
};

export const getAgencyRosterForDropdown = async (): Promise<
  AgencyArtistForDropdown[]
> => {
  const { data } = await base.get({
    path: "/users/roster/artistsForDropdown",
    variant: "users",
  });

  return data?.result ?? [];
};

export const generateArtistSubmissionReport = async (
  artistProfileId: string,
  startDate: Date | null,
  endDate: Date | null
): Promise<ArtistSubmissionReport> => {
  const { data } = await base.post({
    path: "/users/roster/generateArtistSubmissionReport",
    data: {
      artistProfileId: artistProfileId,
      startDate: startDate,
      endDate: endDate,
    },
    variant: "users",
  });

  return toArtistSubmissionReport(data);
};

export const castingDirectorInviteTeammates = async (
  emails: string[]
): Promise<CreateProjectResponse> => {
  const path = "/users/invitations/castingdirector/teammates";
  const { data } = await base.post({
    path,
    data: {
      emails: emails,
    },
    variant: "users",
  });
  return toResponse(data);
};

export const agencyInviteTeammates = async (
  emails: string[]
): Promise<CreateProjectResponse> => {
  const path = "/users/invitations/agency/teammates";
  const { data } = await base.post({
    path,
    data: {
      emails: emails,
    },
    variant: "users",
  });
  return toResponse(data);
};

export const validateAgencyInvitationToken = async (
  token: string
): Promise<CreateProjectResponse> => {
  const path = `/users/invitations/agency/validate?token=${token}`;
  const { data } = await base.get({
    path,
    variant: "users",
  });
  return toResponse(data);
};

export const validateAgencyInvitationTokenAnonymous = async (
  token: string,
  email: string
): Promise<CreateProjectResponse> => {
  const path = "/users/invitations/agency/validateanonymous";
  const { data } = await base.postAnonymous({
    path,
    data: {
      confirmationToken: token,
      email: email,
    },
    variant: "users",
  });
  return toResponse(data);
};

export const confirmAgencyInvitation = async (
  token: string
): Promise<CreateProjectResponse> => {
  const path = "/users/invitations/agency/confirm";
  const { data } = await base.post({
    path,
    data: {
      confirmationToken: token,
    },
    variant: "users",
  });
  return toResponse(data);
};

export const declineAgencyInvitation = async (
  token: string
): Promise<CreateProjectResponse> => {
  const path = "/users/invitations/agency/decline";
  const { data } = await base.post({
    path,
    data: {
      confirmationToken: token,
    },
    variant: "users",
  });
  return toResponse(data);
};

/********************************************** */
// Upload Agency Reference file
/********************************************** */
export const createUploadAgencyReferenceFile = async (
  agencyId: string,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `/users/register/agency/uploadreferencefile/${agencyId}`;
  const { data } = await base.uploadFileAnonymous({
    path,
    file,
    onProgress,
    cancelSource,
    variant: "users",
  });

  return toResponse(data);
};

export const createAgencyFileDelete = async (
  agencyId: string,
  fileId: string
): Promise<CreateProjectResponse> => {
  const path = `/users/register/agency/deletefile/${agencyId}/${fileId}`;
  const { data } = await base.deleteFileAnonymous({
    path,
    variant: "users",
  });
  return toResponse(data);
};

/********************************************** */
// Admin approvals
/********************************************** */
export const getPendingApprovals =
  async (): Promise<AdminApprovalsResponse> => {
    const path = "/users/admin/registrations/pending";
    const { data } = await base.get({ path, variant: "users" });
    return parseApprovalsResponse(data);
  };

export const getPendingCastingDirector = async (
  id: string
): Promise<CastingDirectorCompany> => {
  const path = `/users/admin/registrations/pending/castingdirector/${id}`;
  const { data } = await base.get({ path, variant: "users" });
  return toCastingDirectorCompany(data);
};

export const getPendingAgency = async (
  id: string
): Promise<CastingDirectorCompany> => {
  const path = `/users/admin/registrations/pending/agency/${id}`;
  const { data } = await base.get({ path, variant: "users" });
  return toCastingDirectorCompany(data);
};

export const pendingAgencyDownloadReferenceFile = async (
  agencyId: string,
  fileId: string
): Promise<DownloadedFilePathResponse> => {
  const path = `/users/admin/registrations/pending/agency/${agencyId}/document/${fileId}`;
  const { data } = await base.get({ path, variant: "users" });
  return toDownloadedFilePathResponse(data);
};

export const approvePendingCastingDirectorRegistration = async (
  id: string
): Promise<CreateProjectResponse> => {
  const path = "/users/admin/registrations/castingdirector/approve";
  const { data } = await base.post({
    path,

    data: id,
    variant: "users",
  });
  return toResponse(data);
};

export const approvePendingAgencyRegistration = async (
  id: string
): Promise<CreateProjectResponse> => {
  const path = "/users/admin/registrations/agency/approve";
  const { data } = await base.post({
    path,

    data: id,
    variant: "users",
  });
  return toResponse(data);
};

export const declinePendingCastingDirectorRegistration = async (
  id: string
): Promise<CreateProjectResponse> => {
  const path = "/users/admin/registrations/castingdirector/decline";
  const { data } = await base.post({
    path,

    data: id,
    variant: "users",
  });
  return toResponse(data);
};

export const declineAgencyRegistration = async (
  id: string
): Promise<CreateProjectResponse> => {
  const path = "/users/admin/registrations/agency/decline";
  const { data } = await base.post({
    path,

    data: id,
    variant: "users",
  });
  return toResponse(data);
};

/********************************************** */
// sessions
/********************************************** */

export const sessionsGetArtistsForProject = async (
  projectId: string
): Promise<ProjectSelectedArtist[]> => {
  const path = `/roles/sessions/${projectId}/artists`;
  const { data } = await base.get({ path, variant: "roles" });
  return toProjectSelectedArtists(data);
};

export const getProjectSessions = async (
  projectId: string
): Promise<ProjectSession[]> => {
  const path = `/roles/sessions/${projectId}`;
  const { data } = await base.get({
    path,

    variant: "roles",
  });
  return toProjectSessions(data);
};

export const quickAddManualArtist = async (
  projectId: string,
  roleId: string,
  firstName: string,
  lastName: string,
  agencyName: string | null,
  headshotFileId: string | null
): Promise<ProjectSelectedArtist> => {
  const path = `/roles/sessions/${projectId}/quick-add`;
  const { data } = await base.post({
    path,
    data: {
      roleId,
      firstName,
      lastName,
      agencyName,
      headshotFileId,
    },
    variant: "roles",
  });
  return data?.result ?? null;
};

export const createProjectSession = async (
  projectId: string,
  values: any,
  isInPerson: boolean
): Promise<CreateProjectResponse> => {
  const path = `/roles/sessions/${projectId}/create?isInPerson=${isInPerson}`;
  const { data } = await base.post({
    path,
    data: values,
    variant: "roles",
  });
  return toResponse(data);
};

export const createLiveProjectSession = async (
  projectId: string,
  values: any,
  isInPerson: boolean
): Promise<CreateProjectResponse> => {
  const path = `/roles/sessions/${projectId}/create-and-initialise-runsheet-snapshot?isInPerson=${isInPerson}`;
  const { data } = await base.post({
    path,
    data: values,
    variant: "roles",
  });
  return toResponse(data);
};

export const getSessionTimelineSnapshot = async (
  projectId: string,
  sessionId: string
): Promise<ProjectSessionTimelineSnapshot> => {
  const path = `/roles/sessions/${projectId}/${sessionId}/get-timeline-snapshot`;
  const { data } = await base.get({
    path,
    variant: "roles",
  });

  return parseProjectSessionTimelineSnapshot(data);
};

export const editProjectSessionName = async (
  projectId: string,
  sessionId: string,
  newName: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/sessions/${projectId}/${sessionId}/edit-name/${newName}`;
  const { data } = await base.patch({
    path,

    variant: "roles",
  });
  return toResponse(data);
};

export const saveLiveProjectTimelineDraft = async (
  projectId: string,
  sessionId: string,
  timeline: any
): Promise<CreateProjectResponse> => {
  const path = `/roles/sessions/${projectId}/${sessionId}/save-session-timeline-draft`;
  const { data } = await base.put({
    path,
    data: timeline,
    variant: "roles",
  });

  return toResponse(data);
};

export const getWardrobeQuestionsforSession = async (
  sessionId: string,
  projectId: string
): Promise<string[]> => {
  const path = `/roles/sessions/${projectId}/${sessionId}/wardrobeQuestions`;
  const { data } = await base.get({ path, variant: "roles" });
  return data.result;
};
export const getExportSessionRunningListData = async (
  sessionId: string,
  projectId: string,
  runningListFilter: RunningListFilter
): Promise<SessionRunningListPdfData> => {
  const checkedQuestions = runningListFilter.questions
    .filter((question) => question.checked)
    .map((question) => question.label);

  // query = query.slice(0, -1);
  var filter = {
    Headshot: runningListFilter.headshot.checked,
    CulturalBackground: runningListFilter.culturalBackground.checked,
    Location: runningListFilter.location.checked,
    Height: runningListFilter.height.checked,
    Gender: runningListFilter.gender.checked,
    Agent: runningListFilter.agent.checked,
    ProNouns: runningListFilter.pronouns.checked,
    IncludeInternalComments: runningListFilter.includeInternalComments.checked,
    IncludeClientandCastingComments:
      runningListFilter.includeClientandCastingComments.checked,
    Questions: checkedQuestions,
  };
  const path = `/roles/sessions/${projectId}/${sessionId}/runningList`;

  const { data } = await base.post({ path, data: filter, variant: "roles" });
  return mapSessionRunningListPdfData(data.result);
};

export const sendUnsentLiveSessionRequests = async (
  projectId: string,
  sessionId: string,
  payload: any
): Promise<CreateProjectResponse> => {
  const path = `/roles/sessions/${projectId}/${sessionId}/send-unsent-live-session-requests`;
  const { data } = await base.post({
    path,
    data: payload,
    variant: "roles",
  });
  return toResponse(data);
};

export const updateSubmissionAuditionStatus = async (
  projectId: string,
  sessionId: string,
  payload: any
): Promise<CreateProjectResponse> => {
  const path = `/roles/sessions/${projectId}/${sessionId}/update-submission-audition-status`;
  const { data } = await base.post({
    path,
    data: payload,
    variant: "roles",
  });
  return toResponse(data);
};

export const changeSessionTimeframe = async (
  projectId: string,
  sessionId: string,
  payload: any
): Promise<CreateProjectResponse> => {
  const path = `/roles/sessions/${projectId}/${sessionId}/change-session-timeframe`;
  const { data } = await base.post({
    path,
    data: payload,
    variant: "roles",
  });
  return toResponse(data);
};

export const editSessionDetails = async (
  projectId: string,
  sessionId: string,
  payload: any
): Promise<CreateProjectResponse> => {
  const path = `/roles/sessions/${projectId}/${sessionId}/edit-session`;

  const { data } = await base.post({
    path,
    data: payload,
    variant: "roles",
  });

  return toResponse(data);
};

export const sessionConfirmChanges = async (
  projectId: string,
  sessionId: string,
  values: any,
  skipNotifying: boolean
): Promise<CreateProjectResponse> => {
  const path = `/roles/sessions/${projectId}/${sessionId}/save?skipNotifying=${skipNotifying}`;
  const { data } = await base.post({
    path,

    data: values,
    variant: "roles",
  });
  return toResponse(data);
};

export const sessionsGetSessionDetails = async (
  projectId: string,
  sessionId: string
): Promise<ProjectSessionDetails> => {
  const path = `/roles/sessions/${projectId}/${sessionId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return parseProjectSessionDetails(data);
};

export const sessionsGetSessionDetailsForExternalAccess = async (
  channelId: string,
  projectId: string,
  sessionId: string
): Promise<ProjectSessionDetails> => {
  const path = `/external/channel/${channelId}/project/${projectId}/session/${sessionId}`;
  const { data } = await base.getAnonymous({ path, variant: "external" });
  return parseProjectSessionDetails(data);
};

export const sessionsGetSessionHeaderDetails = async (
  projectId: string,
  sessionId: string
): Promise<ProjectSessionHeaderDetails> => {
  const path = `/roles/sessions/${projectId}/${sessionId}/get-session-header-details`;
  const { data } = await base.get({ path, variant: "roles" });
  return {
    id: data?.result?.id ?? "",
    projectId: data?.result?.projectId ?? "",
    version: data?.result?.version ?? 1,
    date: toDate(data?.result?.date),
    projectName: data?.result?.projectName ?? "",
    timezone: data?.result?.timezone ?? "",
    displayTime: data?.result?.displayTime ?? "",
    startTime: toDate(data?.result?.startTime),
    endTime: toDate(data?.result?.endTime),
    name: data?.result?.name ?? "",
    format: data?.result?.format ?? "",
    isInPerson: data?.result?.isInPerson ?? false,
    type: data?.result?.type ?? "",
    additionalNotes: data?.result?.additionalNotes ?? "",
    location: data?.result?.location ?? "",
    timeslotsDurationInMinutes: data?.result?.timeslotsDurationInMinutes ?? "",
    videoCallLink: data?.result?.videoCallLink ?? "",
  };
};

export const sessionsGetSessionTimeSlotDetails = async (
  projectId: string,
  sessionId: string,
  timeSlotId: string
): Promise<SessionTimeSlotDetails> => {
  const path = `/roles/sessions/${projectId}/${sessionId}/${timeSlotId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return parseSessionTimeSlotDetails(data);
};

export const sessionsGetSessionV2Details = async (
  projectId: string,
  sessionId: string,
  artistId?: string,
  isRegularTapeArtist?: boolean
): Promise<SessionTimeSlotDetails> => {
  const path = `/roles/sessions/${projectId}/${sessionId}
    /v2/live-session${
      artistId
        ? isRegularTapeArtist
          ? "?artistId=" + artistId
          : "?manualArtistId=" + artistId
        : ""
    }`;
  const { data } = await base.get({ path, variant: "roles" });
  const res = parseSessionTimeSlotDetails(data);
  return res;
};

export const sessionsGetSessionTimeSlotDetailsForExternalAccess = async (
  channelId: string,
  projectId: string,
  sessionId: string,
  timeSlotId: string
): Promise<SessionTimeSlotDetails> => {
  const path = `/external/channel/${channelId}/project/${projectId}/session/${sessionId}/timeslot/${timeSlotId}`;
  const { data } = await base.getAnonymous({ path, variant: "external" });
  return parseSessionTimeSlotDetails(data);
};

export const sessionsGetSessionTimeSlotDetailsForExternalAccessV2 = async (
  channelId: string,
  projectId: string,
  sessionId: string
): Promise<SessionTimeSlotDetails> => {
  const path = `/external/channel/${channelId}/project/${projectId}/session/${sessionId}/v2/live-session`;
  const { data } = await base.get({ path, variant: "external" });
  return parseSessionTimeSlotDetails(data);
};

export const sessionsGetArtistSessionTimeSlotDetails = async (
  submissionId: string,
  timeSlotId: string
): Promise<SessionTimeSlotDetails> => {
  const path = `/roles/sessions/artist/${submissionId}/${timeSlotId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return parseSessionTimeSlotDetails(data);
};

export const sessionCheckSession = async (
  sessionId: string
): Promise<SessionStatus> => {
  const path = `/roles/livesession/${sessionId}/v2`;
  const { data } = await base.getAnonymous({
    path,
    variant: "roles",
  });
  return toSessionStatus(data);
};

export const sessionRefreshtoken = async (
  sessionId: string
): Promise<SessionStatus> => {
  const path = `/roles/livesession/${sessionId}/v2/refresh-token`;
  const { data } = await base.postAnonymous({
    path,
    variant: "roles",
  });
  return toSessionStatus(data);
};

export const sessionCheckSessionForArtist = async (
  timeLineId: string
): Promise<SessionStatus> => {
  const path = `/roles/livesession/${timeLineId}/v2/artist/lise-session-status`;
  const { data } = await base.getAnonymous({
    path,
    variant: "roles",
  });
  return toSessionStatus(data);
};

export const sessionJoinSession = async (
  timeSlotId: string,
  recordingSettings: RecordingUser
): Promise<SessionStatus> => {
  const path = `/roles/livesession/${timeSlotId}/join`;

  const { data } = await base.post({
    path,

    data: recordingSettings,
    variant: "roles",
  });
  return toSessionStatus(data);
};

export const artistJoinsessionV2 = async (
  submissionId: string,
  timeSlotId: string,
  recordingSettings: RecordingUser
): Promise<SessionStatus> => {
  const path = `/roles/livesession/v2/artist/join/${timeSlotId}/${submissionId}`;

  const { data } = await base.post({
    path,

    data: recordingSettings,
    variant: "roles",
  });
  return toSessionStatus(data);
};

export const joinsessionV2 = async (
  sessionId: string,
  recordingSettings: RecordingUser
): Promise<SessionStatus> => {
  const path = `/roles/livesession/${sessionId}/v2/join`;

  const { data } = await base.post({
    path,

    data: recordingSettings,
    variant: "roles",
  });
  return toSessionStatus(data);
};

export const sessionLeaveSession = async (
  timeSlotId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/livesession/${timeSlotId}/leave`;

  const { data } = await base.deleteFile({
    path,

    variant: "roles",
  });
  return toResponse(data);
};

export const sessionLeaveSessionV2 = async (
  sessionId: string,
  uid: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/livesession/${sessionId}/v2/leave/${uid}`;

  const { data } = await base.deleteFile({
    path,

    variant: "roles",
  });
  return toResponse(data);
};

export const sessionArtistLeaveSessionV2 = async (
  timeSlotId: string,
  uid: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/livesession/${timeSlotId}/v2/artist/leave/${uid}`;

  const { data } = await base.deleteFile({
    path,

    variant: "roles",
  });
  return toResponse(data);
};

export const sessionV2AdmitUser = async (
  sessionId: string,
  uid: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/livesession/${sessionId}/v2/admit/${uid}`;

  const { data } = await base.post({
    path,
    variant: "roles",
  });
  return toResponse(data);
};

export const sessionV2RemoveUser = async (
  sessionId: string,
  uid?: string,
  agoraUid?: number,
  disconnect?: boolean
): Promise<CreateProjectResponse> => {
  const path = `/roles/livesession/${sessionId}/v2/remove/${uid}?disconnect=${
    disconnect ?? false
  }${agoraUid ? "&agoraUid=" + agoraUid + "" : ""}`;

  const { data } = await base.deleteFile({
    path,
    variant: "roles",
  });
  return toResponse(data);
};

export const sessionStartSession = async (
  projectId: string,
  sessionId: string,
  isInPerson?: boolean
): Promise<SessionStatus> => {
  const path = isInPerson
    ? `/roles/livesession/${projectId}/${sessionId}/start?inPerson=true`
    : `/roles/livesession/${projectId}/${sessionId}/v2/start?inPerson=false`;
  const { data } = await base.post({
    path,

    data: null,
    variant: "roles",
  });
  return toSessionStatus(data);
};

export const getWaitingRoomUserDetails = async (
  sessionId: string
): Promise<WaitingRoomItem[]> => {
  const path = `/roles/livesession/${sessionId}/v2/waiting-room-users`;
  const { data } = await base.get({
    path,
    variant: "roles",
  });

  return toWaitingRoomItems(data);
};

export const getLiveSessionArtistDetails = async (
  sessionId: string
): Promise<WaitingRoomItem[]> => {
  const path = `/roles/livesession/${sessionId}/v2/session-artists`;
  const { data } = await base.get({
    path,
    variant: "roles",
  });

  return data?.result ?? [];
};

export const sessionStopSession = async (
  projectId: string,
  sessionId: string,
  isInPerson?: boolean
): Promise<SessionStatus> => {
  const path = isInPerson
    ? `/roles/livesession/${projectId}/${sessionId}/stop?inPerson=true`
    : `/roles/livesession/${projectId}/${sessionId}/v2/stop?inPerson=false`;
  const { data } = await base.post({
    path,

    data: null,
    variant: "roles",
  });
  return toSessionStatus(data);
};

export const sessionUpdatePinStatus = async (
  projectId: string,
  sessionId: string,
  users: RecordingUser[]
): Promise<SessionStatus> => {
  const path = `/roles/livesession/${projectId}/${sessionId}/v2/pin`;
  const { data } = await base.post({
    path,
    data: users,
    variant: "roles",
  });
  return toSessionStatus(data);
};

export const sessionUpdateUsersStatus = async (
  sessionId: string,
  users: RecordingUser[]
): Promise<SessionStatus> => {
  const path = `/roles/livesession/${sessionId}/v2/mute`;
  const { data } = await base.post({
    path,
    data: users,
    variant: "roles",
  });
  return toSessionStatus(data);
};

export const sessionUpdateUserQuality = async (
  sessionId: string,
  agoraUid: number
): Promise<SessionStatus> => {
  const path = `/roles/livesession/${sessionId}/v2/quality`;
  const { data } = await base.post({
    path,
    data: agoraUid,
    variant: "roles",
  });
  return toSessionStatus(data);
};

export const sessionToggleRecordingSession = async (
  projectId: string,
  sessionId: string,
  startRecording: boolean,
  users?: RecordingUser[]
): Promise<SessionStatus> => {
  const path = `/roles/livesession/${projectId}/${sessionId}/v2/recording`;
  const { data } = await base.post({
    path,
    data: {
      startRecording: startRecording,
      users: users != null ? users : null,
    },
    variant: "roles",
  });
  return toSessionStatus(data);
};

export const sessionToggleInPersonRecordingSession = async (
  projectId: string,
  timeSlotId: string,
  startRecording: boolean
): Promise<SessionStatus> => {
  const path = `/roles/livesession/${projectId}/${timeSlotId}/in-person/recording`;
  const { data } = await base.post({
    path,
    data: {
      startRecording: startRecording,
    },
    variant: "roles",
  });
  return toSessionStatus(data);
};

export const sessionUploadManualClip = async (
  projectId: string,
  timeSlotId: string,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<UploadVideoResponse> => {
  const path = `/roles/livesession/${projectId}/${timeSlotId}/upload`;
  // Because triggering the cancellation token returns a null response,
  // we cant automatically unpack the data node
  const response = await base.uploadFile({
    path,
    file,
    onProgress,
    cancelSource,
    variant: "roles",
  });
  return toUploadVideoResponse(response?.data);
};

export const sessionCheckClipEmbedStatus = async (
  clipId: string
): Promise<CreateProjectResponse> => {
  const path = `/external/vimeothumbnail/embedready/${clipId}`;
  const { data } = await base.postAnonymous({
    path,
    data: null,
    variant: "external",
  });
  return toResponse(data);
};

export const clipCheckTrimStatus = async (
  clipId: string
): Promise<CreateProjectResponse> => {
  const path = `/external/vimeothumbnail/trimready/${clipId}`;
  const { data } = await base.postAnonymous({
    path,
    data: null,
    variant: "external",
  });
  return toResponse(data);
};

export const sessionDeleteManualClip = async (
  projectId: string,
  timeSlotId: string,
  fileId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/livesession/${projectId}/${timeSlotId}/delete/${fileId}`;
  const { data } = await base.deleteFile({
    path,
    variant: "roles",
  });
  return toResponse(data);
};

export const sessionDeleteCcBriefSubmissionClip = async (
  projectId: string,
  timeSlotId: string,
  fileId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/ccbriefsubmission/clips/delete/${timeSlotId}/${fileId}`;
  const { data } = await base.deleteFile({
    path,
    variant: "roles",
  });
  return toResponse(data);
};

export const sessionMessageArtists = async (
  projectId: string,
  sessionId: string,
  message: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/sessions/${projectId}/${sessionId}/message/artists`;
  const { data } = await base.post({
    path,

    data: {
      message: message,
    },
    variant: "roles",
  });
  return toResponse(data);
};

export const sessionMessageAgent = async (
  projectId: string,
  sessionId: string,
  submissionId: string,
  message: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/sessions/${projectId}/${sessionId}/message/agent/${submissionId}`;
  const { data } = await base.post({
    path,

    data: {
      message: message,
    },
    variant: "roles",
  });
  return toResponse(data);
};

export const sessionCancelSession = async (
  projectId: string,
  sessionId: string,
  message: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/sessions/${projectId}/${sessionId}/cancel`;
  const { data } = await base.post({
    path,

    data: {
      message: message,
    },
    variant: "roles",
  });
  return toResponse(data);
};

export const sessionChangeToOnline = async (
  projectId: string,
  sessionId: string
): Promise<boolean> => {
  const path = `/roles/sessions/${projectId}/${sessionId}/change-to-online`;
  const { data } = await base.patch({
    path,
    data: {},
    variant: "roles",
  });
  return data?.result ?? false;
};

export const getSubmittedArtistsForSelfTape = async (
  projectId: string,
  roleId: string
): Promise<SelfTapeArtistProfile[]> => {
  const path = `/roles/sessions/${projectId}/role/${roleId}/artists`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result.map(toSelfTapeArtistProfile) ?? [];
};

export const getSubmittedArtistsForSelfTapeMultipleRoles = async (
  projectId: string,
  roleIdList: string[],
  sessionType: string
): Promise<SelfTapeArtistProfileWithRoles[]> => {
  const roleIdParams = roleIdList.join("&roleList=");
  const path = `/roles/sessions/${projectId}/roles/artists?roleList=${roleIdParams}&sessionType=${sessionType}`;

  const { data } = await base.get({ path, variant: "roles" });
  return data?.result.map(toSelfTapeArtistProfileWithRoles) ?? [];
};

export const createSelfTapeRequest = async (
  selfTapeRequest: SelfTapeRequest
): Promise<CreateProjectResponse> => {
  const path = `/roles/sessions/self-tape/request`;
  const { data } = await base.post({
    path,

    data: selfTapeRequest,
    variant: "roles",
  });
  return toResponse(data);
};

export const createSelfTapeRequestWithMultipleRoles = async (
  selfTapeRequest: SelfTapeRequest
): Promise<CreateProjectResponse> => {
  const path = `/roles/sessions/self-tape/requestWithMultipleRoles`;
  const { data } = await base.post({
    path,

    data: selfTapeRequest,
    variant: "roles",
  });
  return toResponse(data);
};

export const addParticipantsToSelfTapeRequest = async (
  selfTapeRequestId: string,
  selfTapeRequest: SelfTapeRequest
): Promise<CreateProjectResponse> => {
  const path = `/roles/sessions/self-tape/request/${selfTapeRequestId}/add-participants`;
  const { data } = await base.patch({
    path,

    data: selfTapeRequest,
    variant: "roles",
  });
  return toResponse(data);
};

export const getSelfTapeRequestStatus = async (
  projectId: string
): Promise<SelfTapeRequestStatus[]> => {
  const path = `/roles/sessions/${projectId}/self-tape/requests`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result.map(toSelfTapeRequestStatus) ?? [];
};

export const getSelfTapeRequestBasicDetails = async (
  projectId: string,
  selfTapeRequestId: string
): Promise<SelfTapeRequestBasicDetails> => {
  const path = `/roles/sessions/${projectId}/self-tape/request/${selfTapeRequestId}/basic-details`;
  const { data } = await base.get({ path, variant: "roles" });
  return toSelfTapeRequestBasicDetails(data?.result);
};

export const getSelfTapeRequestsForProject = async (
  projectId: string
): Promise<SelfTapeRequestBasicDetails[]> => {
  const path = `/roles/sessions/${projectId}/self-tape/requests-basic-details`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result.map(toSelfTapeRequestBasicDetails) ?? [];
};

export const addMessageToAuditionSession = async (
  submissionId: string,
  timeSlotId: string,
  message: string,
  userType: string
): Promise<ApiResponse> => {
  const path = `/roles/statusreports/${submissionId}/${timeSlotId}/message`;
  const { data } = await base.post({
    path,

    data: { addedByUserType: userType, message: message },
    variant: "roles",
  });
  return toApiResponse(data?.result);
};

export const addMessageToSelfTapeSession = async (
  submissionId: string,
  selfTapeAuditionId: string,
  message: string,
  userType: string
): Promise<ApiResponse> => {
  const path = `/roles/statusreports/${submissionId}/${selfTapeAuditionId}/self-tape/message`;
  const { data } = await base.post({
    path,

    data: { addedByUserType: userType, message: message },
    variant: "roles",
  });
  return toApiResponse(data?.result);
};

/********************************************** */
// submissions
/********************************************** */

export const submissionsDataForFilter = async (
  projectId: string,
  roleId: string
): Promise<FilterData> => {
  const path = `/roles/submissions/${projectId}/${roleId}/dataforfilter`;
  const { data } = await base.get({ path, variant: "roles" });
  return toSubmissionsFilterDataResponse(data);
};

export const submissionsGetForRole = async (
  projectId: string,
  roleId: string,
  roleStatus: string,
  page: Number,
  pageSize: Number,
  query: string
): Promise<SubmissionsResponse> => {
  const path = `/roles/submissions/${projectId}/${roleId}/submissionstatus/${roleStatus}?${query}page=${page}&pageSize=${pageSize}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toSubmissionsResponse(data);
};

export const submissionsCountGetForRole = async (
  projectId: string,
  roleId: string
): Promise<SubmissionBadgeCountResponse> => {
  const path = `/roles/submissions/${projectId}/${roleId}/counts`;
  const { data } = await base.get({ path, variant: "roles" });
  return toSubmissionBadgeCountResponse(data);
};

export const submissionsUpdate = async (
  projectId: string,
  roleId: string,
  value: SubmissionUpdate
): Promise<CreateProjectResponse> => {
  const path = `/roles/submissions/update/${projectId}/${roleId}`;
  const { data } = await base.post({
    path,

    data: value,
    variant: "roles",
  });
  return toResponse(data);
};

export const submissionsMoveToRole = async (
  projectId: string,
  roleId: string,
  submissionId: string,
  newRoleId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/submissions/movetorole/${projectId}/${roleId}`;
  const { data } = await base.post({
    path,

    data: {
      id: submissionId,
      newRoleId,
    },
    variant: "roles",
  });
  return toResponse(data);
};

export const submissionsCopyToRole = async (
  projectId: string,
  roleId: string,
  submissionId: string,
  newRoleId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/submissions/copytorole/${projectId}/${roleId}`;
  const { data } = await base.post({
    path,

    data: {
      id: submissionId,
      newRoleId,
    },
    variant: "roles",
  });
  return toResponse(data);
};

export const submissionsMarkAllReviewed = async (
  projectId: string,
  roleId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/submissions/markallreviewed/${projectId}/${roleId}`;
  const { data } = await base.post({
    path,

    data: null,
    variant: "roles",
  });
  return toResponse(data);
};

export const submissionsSubmitArtistProfile = async (
  projectId: string,
  roleId: string,
  artistProfileId: string,
  agencyNotes: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/submissions/submit/${projectId}/${roleId}`;
  const { data } = await base.post({
    path,

    data: {
      artistSubmissionRequests: [{ artistProfileId, agencyNotes }],
    },
    variant: "roles",
  });
  // This call is slightly different because we are submitting a single artist to a method that can take many
  return unpackResponseArray(data);
};

export const submissionsSubmitArtistProfiles = async (
  projectId: string,
  roleId: string,
  profiles: {
    artistProfileId: string;
    artistListEntryId: string;
  }[]
): Promise<CreateProjectResponse> => {
  const path = `/roles/submissions/submit/${projectId}/${roleId}`;
  const { data } = await base.post({
    path,

    data: {
      ArtistSubmissionRequests: profiles,
    },
    variant: "roles",
  });
  // This call is slightly different because we are submitting a single artist to a method that can take many
  return unpackResponseArray(data);
};

/********************************************** */
// Reserve project/role id (Initiates create project/role)
/********************************************** */

export const projectsReserveProjectId =
  async (): Promise<CreateProjectResponse> => {
    const path = "/projects/reserveprojectid";
    const { data } = await base.get({ path, variant: "projects" });
    return toResponse(data);
  };

export const rolesReserveRoleId = async (
  projectId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/reserveroleid/${projectId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toResponse(data);
};

export const rolesGetDropdownValues =
  async (): Promise<RoleDropdownResponse> => {
    const path = "/roles/dropdowns";
    const { data } = await base.get({ path, variant: "roles" });
    return toRoleDropdownResponse(data);
  };

export const getAgencyListsValues = async (): Promise<AgencyListsResponse> => {
  const path = "/directory/agencylists";
  const { data } = await base.get({ path, variant: "directory" });
  return toAgencyListsListsResponse(data);
};

export const getProjectRolesAgenciesValues = async (
  projectId: string
): Promise<RoleAgenciesResponse> => {
  const path = `/directory/roleagencies/${projectId}`;
  const { data } = await base.get({ path, variant: "directory" });
  return toRoleAgenciesResponse(data);
};

export const getProjectRoleWardrobeForms = async (
  projectId: string
): Promise<RoleWardrobeForm[]> => {
  const path = `/roles/progect/${projectId}/rolewardrobeforms`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result ?? [];
};

export const getProjectRoleWardrobeTemplates = async (
  projectId: string
): Promise<RoleWardrobeForm[]> => {
  const path = `/wardrobeTemplate/toRoleWardrobeForm`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result ?? [];
};

export const getWardrobeFormDetails = async (
  projectId: string,
  wardrobeFormId: string
): Promise<WardrobeForm | null> => {
  const path = `/roles/progect/${projectId}/role/${wardrobeFormId}/wardrobeform-details`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result ?? null;
};

/********************************************** */
// Save first step of create project
/********************************************** */

export const createProjectSave = async (
  values: any
): Promise<CreateProjectResponse> => {
  console.log("In altai createProjectSave - values", values);

  const path = `/projects/save/${values.id!}/1`;
  const { data } = await base.post({
    path,

    data: values,
    variant: "projects",
  });

  console.log("data", data);

  return toResponse(data);
};

/********************************************** */
// Save progress of create project step 2 (team)
/********************************************** */

export const createProjectTeamSave = async (
  values: any
): Promise<CreateProjectResponse> => {
  console.log("In altai createProjectTeamSave - values", values);

  const path = `/projects/save/${values.id!}/2`;
  const { data } = await base.post({
    path,

    data: values,
    variant: "projects",
  });
  return toResponse(data);
};

/********************************************** */
// Save progress of create project step 3 (Logistics)
/********************************************** */

export const createProjectLogisticsSave = async (
  values: any
): Promise<CreateProjectResponse> => {
  const path = `/projects/save/${values.id!}/3`;
  const { data } = await base.post({
    path,

    data: values,
    variant: "projects",
  });
  return toResponse(data);
};

/********************************************** */
// Save create role
/********************************************** */
export const createRoleSave = async (
  values: any
): Promise<CreateProjectResponse> => {
  const path = `/roles/save`;
  const { data } = await base.post({
    path,

    data: values,
    variant: "roles",
  });
  return toResponse(data);
};

/********************************************** */
// Publish casting briefs
/********************************************** */
export const publishCastingBrief = async (
  projectId: string,
  values: any
): Promise<PublishCastingBriefsResponse> => {
  const path = `/roles/publishroles/${projectId}`;
  const { data } = await base.post({
    path,

    data: values,
    variant: "roles",
  });
  return toPublishCastingBriefsResponse(data);
};

/********************************************** */
// Widen publication
/********************************************** */
export const widenPublication = async (
  projectId: string,
  roleId: string,
  values: any
): Promise<CreateProjectResponse> => {
  const path = `/roles/widenpublication/${projectId}/${roleId}`;
  const { data } = await base.post({
    path,

    data: values,
    variant: "roles",
  });
  return toResponse(data);
};

/********************************************** */
// Upload NDA file
/********************************************** */
export const createProjectUploadNDA = async (
  projectId: string,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `/projects/uploadnda/${projectId}`;

  const { data } = await base.uploadFile({
    path,

    file,
    onProgress,
    cancelSource,
    variant: "projects",
  });

  return toResponse(data);
};

/********************************************** */
// Upload Role reference file
/********************************************** */
export const createRoleUploadReferenceFile = async (
  projectId: string,
  roleId: string,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `/roles/uploadreferencefile/${projectId}/${roleId}`;
  const { data } = await base.uploadFile({
    path,

    file,
    onProgress,
    cancelSource,
    variant: "roles",
  });

  return toResponse(data);
};

/********************************************** */
// Upload Role guide file
/********************************************** */
export const createRoleUploadGuideFile = async (
  projectId: string,
  roleId: string,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `/roles/uploadguidefile/${projectId}/${roleId}`;
  const { data } = await base.uploadFile({
    path,
    file,
    onProgress,
    cancelSource,
    variant: "roles",
  });

  return toResponse(data);
};

/********************************************** */
// Upload Role file
/********************************************** */
export const createRoleUploadFile = async (
  projectId: string,
  roleId: string,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `/roles/uploadrolefile/${projectId}/${roleId}`;
  const { data } = await base.uploadFile({
    path,

    file,
    onProgress,
    cancelSource,
    variant: "roles",
  });

  return toResponse(data);
};

/********************************************** */
// Upload Wardrobe file
/********************************************** */
export const createWardrobeUploadFile = async (
  projectId: string,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `/roles/uploadWardrobefile/${projectId}`;
  const { data } = await base.uploadFile({
    path,

    file,
    onProgress,
    cancelSource,
    variant: "roles",
  });

  return toResponse(data);
};

/********************************************** */
// Request file (Download)
/********************************************** */
export const downloadFile = async (
  projectId: string,
  fileId: string
): Promise<DownloadedFilePathResponse> => {
  const path = `/projects/download/${projectId}/${fileId}`;
  const { data } = await base.get({ path, variant: "projects" });
  return toDownloadedFilePathResponse(data);
};

export const getProjectPdfDetails = async (
  projectId: string
): Promise<ViewRoleWithDetailsTableItem[]> => {
  const path = `roles/details/projects/${projectId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toRolesWithDetailsPdfResponse(data).result;
};

export const downloadRoleFile = async (
  projectId: string,
  roleId: string,
  fileId: string
): Promise<DownloadedFilePathResponse> => {
  const path = `/roles/download/${projectId}/${roleId}/${fileId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toDownloadedFilePathResponse(data);
};

export const downloadCastingBriefRoleFile = async (
  projectId: string,
  roleId: string,
  fileId: string
): Promise<DownloadedFilePathResponse> => {
  const path = `/roles/castingbriefs/download/${projectId}/${roleId}/${fileId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toDownloadedFilePathResponse(data);
};

export const downloadCastingBriefProjectFile = async (
  projectId: string,
  roleId: string,
  fileId: string
): Promise<DownloadedFilePathResponse> => {
  const path = `/roles/castingbriefs/download/projectfile/${projectId}/${roleId}/${fileId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toDownloadedFilePathResponse(data);
};

/********************************************** */
// Upload Project File
/********************************************** */
export const createProjectUploadProjectFile = async (
  projectId: string,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `/projects/uploadprojectfile/${projectId}`;
  const { data } = await base.uploadFile({
    path,

    file,
    onProgress,
    cancelSource,
    variant: "projects",
  });
  return toResponse(data);
};

/********************************************** */
// General usage delete file associated to project
/********************************************** */

export const createProjectDeleteFile = async (
  projectId: string,
  fileId: string
): Promise<CreateProjectResponse> => {
  const path = `/projects/deleteprojectfile/${projectId}/${fileId}`;
  const { data } = await base.deleteFile({
    path,
    variant: "projects",
  });
  return toResponse(data);
};

export const createRoleDeleteFile = async (
  projectId: string,
  roleId: string,
  fileId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/deleterolefile/${projectId}/${roleId}/${fileId}`;
  const { data } = await base.deleteFile({
    path,
    variant: "roles",
  });
  return toResponse(data);
};

export const deleteWardrobeFile = async (
  fileId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/deletewardrobefile/${fileId}`;
  const { data } = await base.deleteFile({
    path,
    variant: "roles",
  });
  return toResponse(data);
};

/********************************************** */
// Return a list of projects
// TODO - Consider the need for pagination in future
/********************************************** */
export const getProjectsItems = async (archived: boolean = false) => {
  const path = `/projects?archived=${archived}`;
  const { data } = await base.get({ path, variant: "projects" });
  return toProjectResponse(data);
};

/********************************************** */
// Return a list of roles
// TODO - Consider the need for pagination in future
/********************************************** */
export const getRolesItems = async (
  projectId: string,
  archived: boolean = false
) => {
  const path = `/roles/${projectId}?archived=${archived}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toRolesResponse(data);
};

/********************************************** */
// Returns a list of roles with their details
/********************************************** */
export const getRolesWithDetails = async (
  projectId: string,
  archived: boolean = false
) => {
  //console.log("in alati.getRolesWithDetails - Before getting data");
  //const path = `/roles/projectRolesWithDetails/${projectId}?archived=${archived}`;
  const path = `/roles/${projectId}?archived=${archived}`;
  const { data } = await base.get({ path, variant: "roles" });
  //console.log("in alati.getRolesWithDetails - After getting data");
  return toRolesWithDetailsResponse(data);
};

/********************************************** */
// Get project details by ID
/********************************************** */
export const getProjectDetails = async (
  projectId: string
): Promise<ProjectDetailsResponse> => {
  const path = `/projects/details/${projectId}`;
  const { data } = await base.get({ path, variant: "projects" });

  return toProjectDetailsResponse(data);
};

/********************************************** */
// Get role details by ID
/********************************************** */
export const getRoleDetails = async (
  projectId: string,
  roleId: string
): Promise<RoleDetails> => {
  const path = `/roles/details/${projectId}/${roleId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return parseRoleDetails(data.result);
};

/********************************************** */
// Archive project by ID
/********************************************** */
export const validateArchiveProject = async (
  projectId: string
): Promise<CreateProjectResponse> => {
  const path = `/projects/archive/${projectId}/validate`;
  const { data } = await base.get({
    path,
    variant: "projects",
  });
  return toResponse(data);
};

export const archiveProject = async (
  projectId: string,
  archiveRequestData: ProjectRoleArchiveRequest
): Promise<CreateProjectResponse> => {
  const path = `/projects/archive/${projectId}`;
  const { data } = await base.post({
    path,
    variant: "projects",
    data: archiveRequestData,
  });
  return toResponse(data);
};

/********************************************** */
// Un-Archive project by ID
/********************************************** */
export const unarchiveProject = async (
  projectId: string
): Promise<CreateProjectResponse> => {
  const path = `/projects/unarchive/${projectId}`;
  const { data } = await base.post({ path, variant: "projects" });
  return toResponse(data);
};

/********************************************** */
// Archive Get Artist To Release By Project Id
/********************************************** */
export const getArtistToReleaseByProjectId = async (
  projectId: string
): Promise<ArtistToReleaseDto> => {
  const path = `/projects/${projectId}/artist-to-release`;
  const { data } = await base.get({ path, variant: "projects" });
  return data?.result ?? { ccArtistsIncluded: false, roleArtistsToRelease: [] };
};

/********************************************** */
// Archive role by ID
/********************************************** */
export const archiveRole = async (
  projectId: string,
  roleId: string,
  archiveRequestData: ProjectRoleArchiveRequest
): Promise<CreateProjectResponse> => {
  const path = `/roles/archive/${projectId}/${roleId}`;
  const { data } = await base.post({
    path,
    variant: "roles",
    data: archiveRequestData,
  });
  return toResponse(data);
};

/********************************************** */
// Archive Get Artist To Release By role Id
/********************************************** */
export const getArtistToReleaseByRoleId = async (
  roleId: string
): Promise<ArtistToReleaseDto> => {
  const path = `/roles/${roleId}/artist-to-release`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result ?? { ccArtistsIncluded: false, roleArtistsToRelease: [] };
};

/********************************************** */
// Un-Archive role by ID
/********************************************** */
export const unarchiveRole = async (
  projectId: string,
  roleId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/unarchive/${projectId}/${roleId}`;
  const { data } = await base.post({ path, variant: "roles" });
  return toResponse(data);
};

/********************************************** */
// Agency search query
/********************************************** */
export const searchAgency = async (query: string): Promise<any> => {
  const cleanQuery = encodeURIComponent(query);
  const path = `/directory/agencysearch?query=${cleanQuery}`;
  const { data } = await base.get({ path, variant: "directory" });
  return toAgencyResponse(data);
};

/********************************************** */
// Detect user country
/********************************************** */
export const getUserCountry = async (): Promise<string> => {
  // TODO - go back to using Ip-Api once we are paying for it and can use https
  // const path = "json";
  // const { data } = await base.getIpApi(path);
  const path = "?token=a530b432c191cf";
  const { data } = await base.getIpInfo(path);
  return parseCountryResponse(data);
};

export const artistCcBriefSubmission = async (
  ccBriefSubmissionId: string | null
): Promise<RegisterArtist> => {
  const path = `/communitycastingbrief/${ccBriefSubmissionId}/artist/submission`;
  const { data } = await base.get({ path, variant: "external" });
  return toRegisterArtistProfile(data);
};

/********************************************** */
// Artist profile
/********************************************** */

export const artistProfileGetMyProfile = async (
  profileId: string | null
): Promise<ArtistProfile> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }`;
  const { data } = await base.get({ path, variant: "users" });
  return toArtistProfile(data);
};

export const setVimeoVideoThumbnail = async (
  clipId: number,
  timestamp: number
): Promise<CreateProjectResponse> => {
  const path = `/users/artistprofile/material/${clipId}/setthumbnail`;
  const { data } = await base.post({
    path,
    data: {
      timestamp,
    },
    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileCheckVideoEmbedStatus = async (
  clipId: number
): Promise<CreateProjectResponse> => {
  const path = `/external/vimeothumbnail/${clipId}/embedready`;
  const { data } = await base.postAnonymous({
    path,
    data: null,
    variant: "external",
  });
  return toResponse(data);
};

export const artistProfileGetPublicProfile = async (
  profileId: string
): Promise<ArtistProfile> => {
  const path = `/users/artistprofile/${profileId}/publicinfo`;
  const { data } = await base.get({ path, variant: "users" });
  return toArtistProfile(data);
};

export const artistProfileGetPreviewInfo = async (
  profileCode: string
): Promise<ArtistProfile> => {
  const path = `/users/artistprofile/${profileCode}/previewinfo`;
  const { data } = await base.get({ path, variant: "users" });
  return toArtistProfile(data);
};

export const getAuditionMaterialsForArtist = async (
  profileId: string,
  pageNumber: number,
  pageSize: number
): Promise<AuditionClipsDetail> => {
  const path = `/users/artistprofile/${profileId}/audition-materials?page=${pageNumber}&pageSize=${pageSize}`;
  const { data } = await base.get({ path, variant: "users" });
  return data?.result ?? { totalClipsCount: 0, auditionClips: [] };
};

export const artistProfileSaveKeyAttributes = async (
  profileId: string | null,
  values: any
): Promise<CreateProjectResponse> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/save/keyattributes`;
  const { data } = await base.post({
    path,

    data: values,
    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileSaveAdditionalInformation = async (
  profileId: string | null,
  values: any
): Promise<CreateProjectResponse> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/save/additionalinformation`;
  const { data } = await base.post({
    path,

    data: values,
    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileSaveMeasurements = async (
  profileId: string | null,
  values: any
): Promise<CreateProjectResponse> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/save/measurements`;
  const { data } = await base.post({
    path,

    data: values,
    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileSaveBiography = async (
  profileId: string | null,
  values: Biography
): Promise<CreateProjectResponse> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/save/biography`;
  const { data } = await base.post({
    path,

    data: values,
    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileSaveSecondaryAgency = async (
  profileId: string | null,
  values: SecondaryAgency
): Promise<CreateProjectResponse> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/save/secondaryagency`;
  const { data } = await base.post({
    path,

    data: values,
    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileSaveAward = async (
  profileId: string | null,
  values: Award,
  id: string | null
): Promise<CreateProjectResponse> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/save/award`;
  const { data } = await base.post({
    path: id == null ? path : path + `/${id}`,

    data: values,
    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileDeleteAward = async (
  profileId: string | null,
  id: string
): Promise<CreateProjectResponse> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/delete/award/${id}`;
  const { data } = await base.deleteFile({
    path,

    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileSaveCredit = async (
  profileId: string | null,
  values: Credit,
  id: string | null
): Promise<CreateProjectResponse> => {
  const path = `/users/artistprofile${
    profileId && profileId !== null && profileId !== "" ? `/${profileId}` : ""
  }/save/credit`;
  const { data } = await base.post({
    path: id == null ? path : path + `/${id}`,

    data: values,
    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileBulkUploadCredits = async (
  profileId: string | null,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<BulkImportCreditResponse> => {
  const path = `/users/artistprofile${
    profileId && profileId !== null && profileId !== "" ? `/${profileId}` : ""
  }/bulkSave/credit`;
  const { data } = await base.uploadFile({
    path,
    file,
    onProgress,
    cancelSource,
    variant: "users",
  });

  return data;
};

export const artistProfileDeleteCredit = async (
  profileId: string | null,
  id: string
): Promise<CreateProjectResponse> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/delete/credit/${id}`;
  const { data } = await base.deleteFile({
    path,

    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileSaveSkill = async (
  profileId: string | null,
  values: Skill,
  id: string | null
): Promise<CreateProjectResponse> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/save/skill`;
  const { data } = await base.post({
    path: id == null ? path : path + `/${id}`,

    data: values,
    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileDeleteSkill = async (
  profileId: string | null,
  id: string
): Promise<CreateProjectResponse> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/delete/skill/${id}`;
  const { data } = await base.deleteFile({
    path,

    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileSaveTraining = async (
  profileId: string | null,
  value: Training,
  id: string | null
): Promise<CreateProjectResponse> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/save/training`;
  const { data } = await base.post({
    path: id == null ? path : path + `/${id}`,

    data: value,
    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileDeleteTraining = async (
  profileId: string | null,
  id: string
): Promise<CreateProjectResponse> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/delete/training/${id}`;
  const { data } = await base.deleteFile({
    path,

    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileGetMaterialsThumbnails = async (
  profileId: string | null
): Promise<MaterialVideoThumbnails[]> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/materials/thumbnails`;
  const { data } = await base.get({ path, variant: "users" });
  return toThumbnails(data);
};

export const artistProfileSaveMaterials = async (
  profileId: string | null,
  values: MaterialVideo[]
): Promise<CreateProjectResponse> => {
  const path = `/users/artistprofile${
    profileId != null && profileId !== "" ? `/${profileId}` : ""
  }/save/materials`;
  const { data } = await base.post({
    path,

    data: {
      materials: values,
    },
    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileDownloadMaterials = async (
  videoId: number
): Promise<DownloadMaterialResponse> => {
  const path = `/users/artistprofile/downloadmaterial/${videoId}`;
  const { data } = await base.get({ path, variant: "users" });
  return toDownloadMaterialResponse(data);
};

export const artistProfileUploadImage = async (
  profileId: string | null,
  coverPhoto: boolean,
  order: number,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/uploadimage?coverPhoto=${coverPhoto}&order=${order}`;
  const { data } = await base.uploadFile({
    path,
    file,
    onProgress,
    cancelSource,
    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileUploadTemporaryImage = async (
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `users/artistprofile/uploadimage/agent-register-artist`;
  const { data } = await base.uploadFile({
    path,

    file,
    onProgress,
    cancelSource,
    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileEditKeyVitalsUploadImage = async (
  projectId: string | null,
  tapeId: string | null,
  profileId: string | null,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `roles/CdStatusReports/${projectId}/tape/${tapeId}/artist/${profileId}/alter-profile-image`;
  const { data } = await base.uploadFile({
    path,

    file,
    onProgress,
    cancelSource,
    variant: "roles",
  });
  return toResponse(data);
};

export const artistInRoleEditKeyVitalsUploadImage = async (
  projectId: string | null,
  roleId: string,
  artistProfileId: string | null,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `roles/CdStatusReports/${projectId}/role/${roleId}/artist/${artistProfileId}/alter-profile-image`;
  const { data } = await base.uploadFile({
    path,

    file,
    onProgress,
    cancelSource,
    variant: "roles",
  });
  return toResponse(data);
};

export const artistProfileUploadMaterialVideo = async (
  profileId: string | null,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/uploadmaterial`;
  const { data } = await base.uploadFile({
    path,

    file,
    onProgress,
    cancelSource,
    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileUploadDocument = async (
  profileId: string | null,
  docType: DocumentType,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/uploaddocument?docType=${docType}`;
  const { data } = await base.uploadFile({
    path,

    file,
    onProgress,
    cancelSource,
    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileDeleteImage = async (
  profileId: string | null,
  fileId: string
): Promise<CreateProjectResponse> => {
  const path = `users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/deleteimage/${fileId}`;
  const { data } = await base.deleteFile({
    path,

    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileDeleteTemporaryImage = async (
  fileId: string
): Promise<CreateProjectResponse> => {
  const path = `users/artistprofile/uploadimage/${fileId}/agent-register-artist`;
  const { data } = await base.deleteFile({
    path,

    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileEditKeyVitalsDeleteImage = async (
  projectId: string | null,
  tapeId: string | null,
  profileId: string | null
): Promise<CreateProjectResponse> => {
  const path = `roles/CdStatusReports/${projectId}/tape/${tapeId}/artist/${profileId}/delete-profile-image`;
  const { data } = await base.deleteFile({
    path,

    variant: "roles",
  });
  return toResponse(data);
};

export const artistInRoleEditKeyVitalsDeleteImage = async (
  projectId: string | null,
  roleId: string | null,
  artistProfileId: string | null
): Promise<CreateProjectResponse> => {
  const path = `roles/CdStatusReports/${projectId}/role/${roleId}/artist/${artistProfileId}/delete-profile-image`;
  const { data } = await base.deleteFile({
    path,

    variant: "roles",
  });
  return toResponse(data);
};

export const artistProfileDeleteDocument = async (
  profileId: string | null,
  docType: DocumentType,
  fileId: string
): Promise<CreateProjectResponse> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/deletedocument/${fileId}?docType=${docType}`;
  const { data } = await base.deleteFile({
    path,

    variant: "users",
  });
  return toResponse(data);
};
export const artistProfileSavePrimaryHeadshot = async (
  profileId: string | null,
  orderList: HeadShotOrder[]
): Promise<CreateProjectResponse> => {
  const path = `users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/save/images`;
  const { data } = await base.post({
    path,

    data: { orderList },
    variant: "users",
  });
  return toResponse(data);
};

export const artistProfileDownloadDocument = async (
  profileId: string | null,
  docType: DocumentType,
  fileId: string
): Promise<DownloadedFilePathResponse> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/downloaddocument/${fileId}?docType=${docType}`;
  const { data } = await base.get({ path, variant: "users" });
  return toDownloadedFilePathResponse(data);
};

/********************************************** */
// Account details
/********************************************** */

export const getCastingDirectorAccountDetails =
  async (): Promise<CastingDirectorOrAgentAccountDetails> => {
    const path = "/users/account/castingdirector";
    const { data } = await base.get({ path, variant: "users" });
    return toCastingOrAgentDetails(data);
  };

export const getAgentAccountDetails =
  async (): Promise<CastingDirectorOrAgentAccountDetails> => {
    const path = "/users/account/agency";
    const { data } = await base.get({ path, variant: "users" });
    return toCastingOrAgentDetails(data);
  };

export const artistGetPersonalDetails = async (
  profileId: string | null
): Promise<ArtistPersonalDetails> => {
  const path =
    profileId != null
      ? `/users/account/artist/${profileId}`
      : "/users/account/artist";
  const { data } = await base.get({ path, variant: "users" });
  return toArtistPersonalDetails(data);
};

export const artistGetAgencyRepresentation =
  async (): Promise<AgencyRepresentationResponse> => {
    const path = "/users/account/artist/agency";
    const { data } = await base.get({ path, variant: "users" });
    return toAgencyRepresentationResponse(data);
  };

export const artistDropAgencyRepresentation =
  async (): Promise<CreateProjectResponse> => {
    const path = "/users/account/artist/agency/remove";
    const { data } = await base.post({
      path,

      data: null,
      variant: "users",
    });
    return toResponse(data);
  };

export const artistEditPersonalDetails = async (
  profileId: string | null,
  values: any
): Promise<CreateProjectResponse> => {
  const path =
    profileId != null
      ? `/users/account/artist/${profileId}/edit`
      : "/users/account/artist/edit";
  const { data } = await base.post({
    path,

    data: values,
    variant: "users",
  });
  return toResponse(data);
};

export const castingDirectorEditPersonalDetails = async (
  values: CastingDirectorOrAgentAccountDetails
): Promise<CreateProjectResponse> => {
  const path = "/users/account/castingdirector/editpersonal";
  const { data } = await base.post({
    path,

    data: values,
    variant: "users",
  });
  return toResponse(data);
};

export const castingDirectorEditCompanyDetails = async (
  values: CastingDirectorOrAgentCompanyDetails
): Promise<CreateProjectResponse> => {
  const path = "/users/account/castingdirector/company/edit";
  const { data } = await base.post({
    path,

    data: values,
    variant: "users",
  });
  return toResponse(data);
};

export const agentEditPersonalDetails = async (
  values: CastingDirectorOrAgentAccountDetails
): Promise<CreateProjectResponse> => {
  const path = "/users/account/agency/editpersonal";
  const { data } = await base.post({
    path,

    data: values,
    variant: "users",
  });
  return toResponse(data);
};

export const agencyEditCompanyDetails = async (
  values: CastingDirectorOrAgentCompanyDetails
): Promise<CreateProjectResponse> => {
  const path = "/users/account/agency/company/edit";
  const { data } = await base.post({
    path,

    data: values,
    variant: "users",
  });
  return toResponse(data);
};

export const castingDirectorGetTeamMembers =
  async (): Promise<TeamMembersResponse> => {
    console.log("In altai castingDirectorGetTeamMembers");

    const path = "/users/account/castingdirector/team";
    const { data } = await base.get({ path, variant: "users" });

    console.log("data", data);

    return toTeamMembersResponse(data);
  };

export const castingDirectorGetCompanyDetails =
  async (): Promise<CastingDirectorOrAgentCompanyDetails> => {
    const path = "users/account/castingdirector/company";
    const { data } = await base.get({ path, variant: "users" });
    return toCastingOrAgentCompanyDetails(data);
  };

export const agencyGetTeamMembers = async (): Promise<TeamMembersResponse> => {
  const path = "/users/account/agency/team";
  const { data } = await base.get({ path, variant: "users" });
  return toTeamMembersResponse(data);
};

export const agencyGetCompanyDetails =
  async (): Promise<CastingDirectorOrAgentCompanyDetails> => {
    const path = "users/account/agency/company";
    const { data } = await base.get({ path, variant: "users" });
    return toCastingOrAgentCompanyDetails(data);
  };

export const castingDirectorRemoveTeamMember = async (
  userId: string
): Promise<CreateProjectResponse> => {
  const path = "/users/account/castingdirector/team/remove";
  const { data } = await base.post({
    path,

    data: userId,
    variant: "users",
  });
  return toResponse(data);
};

export const agencyRemoveTeamMember = async (
  userId: string
): Promise<CreateProjectResponse> => {
  const path = "/users/account/agency/team/remove";
  const { data } = await base.post({
    path,

    data: userId,
    variant: "users",
  });
  return toResponse(data);
};

export const changePassword = async (
  values: ChangePassword
): Promise<CreateProjectResponse> => {
  const path = "/users/account/changepassword";
  const { data } = await base.post({
    path,

    data: {
      password: values.password,
      newPassword: values.newPassword,
    },
    variant: "users",
  });
  return toResponse(data);
};

/********************************************** */
// Casting briefs
/********************************************** */

export const agencyGetCastingBriefsOverview = async (): Promise<
  ViewCastingBriefItem[]
> => {
  const path = "/roles/castingbriefs/projects";
  const { data } = await base.get({ path, variant: "roles" });
  return toCastingBriefProjects(data);
};

export const agencyGetCastingBriefRoleDetails = async (
  projectId: string,
  roleId: string
): Promise<CastingBriefRoleDetails> => {
  const path = `/roles/castingbriefs/role/${projectId}/${roleId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toCastingBriefRole(data);
};

export const agencyGetRosterForCastingBrief = async (
  projectId: string,
  roleId: string,
  filters: ArtistFilters | null
): Promise<ArtistCastingBriefProfile[]> => {
  let query: string = "";
  query = filters?.artistName ? `artistName=${filters.artistName}&` : "";
  query += filters?.agencyName ? `agencyName=${filters.agencyName}&` : "";
  query += filters?.minAge ? `minAge=${filters.minAge}&` : "";
  query +=
    filters?.maxAge && filters?.maxAge !== 120
      ? `maxAge=${filters.maxAge}&`
      : "";
  query += filters?.minActualAge ? `minActualAge=${filters.minActualAge}&` : "";
  query +=
    filters?.maxActualAge && filters?.maxActualAge !== 120
      ? `maxActualAge=${filters.maxActualAge}&`
      : "";
  query += filters?.minHeight ? `minHeight=${filters.minHeight}&` : "";
  query +=
    filters?.maxHeight && filters?.maxHeight !== 220
      ? `maxHeight=${filters.maxHeight}&`
      : "";
  query += filters?.baseLocation ? `locations=${filters.baseLocation}&` : "";
  query += filters?.skills ? `skills=${filters.skills}&` : "";
  query += filters?.gender ? `gender=${filters.gender}&` : "";
  query += filters?.representation
    ? `representation=${filters.representation}&`
    : "";
  query += filters?.nationalities
    ? `nationalities=${filters.nationalities}&`
    : "";
  query += filters?.languages ? `languages=${filters.languages}&` : "";
  query += filters?.disabilities ? `disabilities=${filters.disabilities}&` : "";
  query += filters?.accents ? `accents=${filters.accents}&` : "";
  query += filters?.eyeColours ? `eyeColours=${filters.eyeColours}&` : "";
  query += filters?.hairColours ? `hairColours=${filters.hairColours}&` : "";
  query += filters?.culturalBackgrounds
    ? `culturalBackgrounds=${filters.culturalBackgrounds}&`
    : "";

  const path = `/roles/castingbriefs/roster/${projectId}/${roleId}?${query}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toArtistCastingBriefs(data);
};

export const agencySubmitArtistForRole = async (
  projectId: string,
  roleId: string,
  profile: ArtistCastingBriefProfile
): Promise<CreateProjectResponse> => {
  const path = `/roles/submissions/agency/submit/${projectId}/${roleId}`;
  const { data } = await base.post({
    path,

    data: {
      artistSubmissionRequests: [
        { artistProfileId: profile.id, agencyNotes: profile.notes },
      ],
    },
    variant: "roles",
  });
  // This call is slightly different because we are submitting a single artist to a method that can take many
  return unpackResponseArray(data);
};

export const agencyUnsubmitArtistFromRole = async (
  projectId: string,
  roleId: string,
  artistId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/submissions/agency/unsubmit/${projectId}/${roleId}/${artistId}`;
  const { data } = await base.deleteFile({
    path,

    variant: "roles",
  });
  return toResponse(data);
};

export const agencyUpdateSubmissionNote = async (
  projectId: string,
  roleId: string,
  artistProfileId: string,
  notes: string | null
): Promise<CreateProjectResponse> => {
  const path = `/roles/submissions/agency/note/${projectId}/${roleId}/${artistProfileId}`;
  const { data } = await base.post({
    path,

    data: notes,
    variant: "roles",
  });
  return toResponse(data);
};

/********************************************** */
// Reset Password
/********************************************** */

export const forgotPassword = async (
  values: ForgotPasswordProps
): Promise<CreateProjectResponse> => {
  const path = "/users/account/forgotpassword";
  const { data } = await base.post({
    path,

    data: {
      email: values.email,
    },
    variant: "users",
  });
  return toResponse(data);
};

export const resetPassword = async (
  values: ResetPasswordProps
): Promise<CreateProjectResponse> => {
  const path = "/users/account/resetpassword";
  const { data } = await base.post({
    path,

    data: {
      resetPasswordToken: values.resetToken,
      newPassword: values.newPassword,
    },
    variant: "users",
  });
  return toResponse(data);
};

export const agencyProjectCastingBriefs = async (
  projectId: string
): Promise<CastingBriefProjectDetails> => {
  const path = `/roles/castingbriefs/project/${projectId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return parseCastingBriefProjectDetails(data?.result ?? {});
};

export const agencyCastingBriefRoleFiles = async (
  projectId: string,
  roleId: string
): Promise<CastingBriefRoleFiles> => {
  const path = `/roles/castingbriefs/role/${projectId}/${roleId}/files`;
  const { data } = await base.get({ path, variant: "roles" });
  return parseCastingBriefRoleFiles(data);
};

export const agencyCastingBriefRoleSubmissions = async (
  projectId: string,
  roleId: string
): Promise<CastingBriefRoleSubmissionsResponse> => {
  const path = `/roles/castingbriefs/role/${projectId}/${roleId}/submitted`;
  const { data } = await base.get({ path, variant: "roles" });
  return {
    id: roleId,
    submissions: data?.result ?? [],
  };
};

export const markCastingBriefRoleViewed = async (
  projectId: string,
  roleId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/castingbriefs/role/${projectId}/${roleId}/viewed`;
  const { data } = await base.post({ path, variant: "roles" });
  return toResponse(data);
};

export const markCastingBriefRoleUnviewed = async (
  projectId: string,
  roleId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/castingbriefs/role/${projectId}/${roleId}/unviewed`;
  const { data } = await base.post({ path, variant: "roles" });
  return toResponse(data);
};

export const markCastingBriefRolesViewed = async (
  projectId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/castingbriefs/project/${projectId}/viewed`;
  const { data } = await base.post({ path, variant: "roles" });
  return toResponse(data);
};

export const markCastingBriefRolesUnviewed = async (
  projectId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/castingbriefs/project/${projectId}/unviewed`;
  const { data } = await base.post({ path, variant: "roles" });
  return toResponse(data);
};

/********************************************** */
// Reset Password
/********************************************** */

export const getDirectoryArtistProfiles = async (
  filters: ArtistFilters | null,
  page: Number,
  pageSize: Number,
  filterForAgent: boolean
): Promise<DirectoryArtistGridDetails> => {
  let query: string = "";
  query = filters?.artistName ? `artistName=${filters.artistName}&` : "";
  query += filters?.agencyName ? `agencyName=${filters.agencyName}&` : "";
  query += filters?.minAge ? `minAge=${filters.minAge}&` : "";
  query += filters?.maxAge ? `maxAge=${filters.maxAge}&` : "";
  query += filters?.minHeight ? `minHeight=${filters.minHeight}&` : "";
  query += filters?.maxHeight ? `maxHeight=${filters.maxHeight}&` : "";
  query += filters?.baseLocation ? `locations=${filters.baseLocation}&` : "";
  query += filters?.skills ? `skills=${filters.skills}&` : "";
  query += filters?.gender ? `gender=${filters.gender}&` : "";
  query += filters?.representation
    ? `representation=${filters.representation}&`
    : "";
  query += filters?.nationalities
    ? `nationalities=${filters.nationalities}&`
    : "";
  query += filters?.languages ? `languages=${filters.languages}&` : "";
  query += filters?.disabilities ? `disabilities=${filters.disabilities}&` : "";
  query += filters?.accents ? `accents=${filters.accents}&` : "";
  query += filters?.eyeColours ? `eyeColours=${filters.eyeColours}&` : "";
  query += filters?.hairColours ? `hairColours=${filters.hairColours}&` : "";
  query += filters?.culturalBackgrounds
    ? `culturalBackgrounds=${filters.culturalBackgrounds}&`
    : "";

  const path = `/directory/artists?${query}page=${page}&pageSize=${pageSize}&filterForAgent=${filterForAgent}`;
  const { data } = await base.get({
    path,

    variant: "directory",
  });
  const result: DirectoryArtistGridDetails = {
    totalRowCount: data?.result?.totalRowCount,
    pageSize: data?.result?.pageSize,
    numberOfPages: data?.result?.numberOfPages,
    currentPageNumber: data?.result?.currentPageNumber,
    data: toDirectoryArtistProfiles(data?.result?.data),
  };
  return result;
};

export const getDirectoryAgencies = async (
  filters: AgencyFilters | null
): Promise<AgencyBasicDetails[]> => {
  let query: string = "";
  query += filters?.agencyName ? `agencyName=${filters.agencyName}&` : "";
  query += filters?.officeLocations
    ? `officeLocations=${filters.officeLocations}&`
    : "";

  const path = `/directory/agencies?${query}`;
  const { data } = await base.get({
    path,

    variant: "directory",
  });

  return toDirectoryAgencyBasicProfiles(data.result);
};

export const getAgencyDetails = async (
  agencyId: string
): Promise<DirectoryAgencyProfile> => {
  const path = `/directory/agency/${agencyId}`;
  const { data } = await base.get({
    path,

    variant: "directory",
  });

  return parseDirectoryAgencyProfile(data.result);
};

export const getCastingOffices = async (
  filters: CastingOfficeFilters | null
): Promise<CastingOfficeBasicInfo[]> => {
  let query: string = "";
  query += filters?.castingOfficeName
    ? `castingOfficeName=${filters.castingOfficeName}&`
    : "";
  query += filters?.officeLocations
    ? `officeLocations=${filters.officeLocations}&`
    : "";

  const path = `/directory/castingoffices?${query}`;
  const { data } = await base.get({
    path,

    variant: "directory",
  });

  return toDirectoryCastingOfficeBasicInfos(data.result);
};

export const getCastingOfficeDetails = async (
  castingOfficeId: string
): Promise<CastingOfficeDetails> => {
  const path = `/directory/castingoffice/${castingOfficeId}`;
  const { data } = await base.get({
    path,

    variant: "directory",
  });

  return parseDirectoryCastingOfficeDetails(data.result);
};

/********************************************** */
// Messaging
/********************************************** */
export const getMessagingUserToken = async (): Promise<string> => {
  const path = `/messaging/userToken`;
  const { data } = await base.get({
    path,

    variant: "users",
  });

  return data?.result ?? "";
};

export const getMessagingUser = async (): Promise<MessagingUser> => {
  const path = `/messaging/user`;
  const { data } = await base.get({
    path,

    variant: "users",
  });

  return parseMessagingUser(data?.result);
};

export const revokeMessagingUserToken =
  async (): Promise<CreateProjectResponse> => {
    const path = `/messaging/revokeUserToken`;
    const { data } = await base.deleteFile({
      path,

      variant: "users",
    });

    return toResponse(data);
  };

export const markAllMessageAsRead = async (): Promise<boolean> => {
  const path = `/messaging/notification/mark_all_read`;
  const { data } = await base.get({
    path,

    variant: "users",
  });

  return data?.result ?? false;
};

/********************************************** */
// Lists
/********************************************** */

export const getAgencyLists = async (): Promise<AgencyLists[]> => {
  const path = `/lists/agency`;
  const { data } = await base.get({
    path,

    variant: "directory",
  });

  return toAgencyLists(data?.result);
};

export const getArtistLists = async (
  projectId?: string | null
): Promise<ArtistLists[]> => {
  const path = `/lists/artist${projectId ? `?project=${projectId}` : ""}`;
  const { data } = await base.get({
    path,
    variant: "directory",
  });

  return toArtistLists(data?.result);
};

export const getOtherArtistLists = async (
  projectId?: string | null
): Promise<ArtistLists[]> => {
  if (projectId) {
    const path = `/lists/artist/${projectId}`;
    const { data } = await base.get({
      path,
      variant: "directory",
    });

    return toArtistLists(data?.result);
  } else {
    return Promise.resolve([]);
  }
};

export const getOtherProjects = async (
  listId?: string | null
): Promise<ProjectBasicDetails[]> => {
  const path = `/lists/artist/${listId}/projects`;
  const { data } = await base.get({
    path,
    variant: "directory",
  });
  return data?.result?.map(parseProjectDetails) ?? [];
};

export const getArtistComments = async (
  nonAltaiArtistId?: string
): Promise<ArtistListComment[] | null> => {
  const path = `/comments/get${
    nonAltaiArtistId ? `?nonAltaiArtistId=${nonAltaiArtistId}` : ""
  }`;
  const { data } = await base.get({
    path,
    variant: "directory",
  });
  const res = parseArtistComments(data?.result);
  return res;
};

export const getCdCommentNotifications = async (): Promise<
  CdCommentNotification[] | null
> => {
  const path = `/comments/notifications`;
  const { data } = await base.get({
    path,
    variant: "directory",
  });
  const res = parseCdCommentNotifications(data?.result);
  return res;
};

export const editArtistComment = async (
  entryId: string,
  data: EditCommentForm
): Promise<ListResponse> => {
  const path = `/comments/${entryId}/edit`;
  const { response } = await base.post({
    path,
    data,
    variant: "directory",
  });

  return toListResponse(response);
};

export const addArtistChannelComment = async (
  entryId: string,
  data: EditCommentForm
): Promise<ListResponse> => {
  const path = `/comments/${entryId}/add-from-channel`;
  const { response } = await base.post({
    path,
    data,
    variant: "directory",
  });

  return toListResponse(response);
};

export const createArtistBulkComments = async (
  entries: string[],
  data: EditCommentForm
): Promise<ListResponse> => {
  const path = `/comments/bulk/create`;
  const { response } = await base.post({
    path,
    data: {
      entries,
      data,
    },
    variant: "directory",
  });

  return toListResponse(response);
};

export const removeArtistsFromList = async (
  listId: string,
  entries: string[]
): Promise<ListResponse> => {
  const path = `/lists/artist/${listId}/remove/`;
  const { data } = await base.post({
    path,
    data: entries,
    variant: "directory",
  });

  return toListResponse(data);
};

export const deleteArtistComment = async (
  entryId: string,
  commentId: string
): Promise<ListResponse> => {
  const path = `comments/${entryId}/delete/${commentId}`;
  const { response } = await base.post({
    path,
    variant: "directory",
  });

  return toListResponse(response);
};

export const deleteArtistResponse = async (
  commentId: string
): Promise<ListResponse> => {
  const path = `comments/delete/${commentId}`;
  const { response } = await base.post({
    path,
    variant: "directory",
  });

  return toListResponse(response);
};

export const respondArtistComment = async (
  commentId: string,
  message: string | null
): Promise<ListResponse> => {
  const path = `/comments/${commentId}/respond${
    message ? `?response=${message}` : ""
  }`;
  const { response } = await base.post({
    path,

    variant: "directory",
  });

  return toListResponse(response);
};

export const respondContactComment = async (
  commentId: string,
  contactId: string,
  message: string | null
): Promise<ListResponse> => {
  const path = `/comments/${commentId}/respond/${contactId}${
    message ? `?response=${message}` : ""
  }`;
  const { response } = await base.post({
    path,

    variant: "directory",
  });

  return toListResponse(response);
};

export const archiveArtistComments = async (
  comments: string[]
): Promise<ListResponse> => {
  const path = `/comments/archive`;
  const { response } = await base.post({
    path,
    data: comments,
    variant: "directory",
  });

  return toListResponse(response);
};

export const restoreArtistComments = async (
  comments: string[]
): Promise<ListResponse> => {
  const path = `/comments/restore`;
  const { response } = await base.post({
    path,
    data: comments,
    variant: "directory",
  });

  return toListResponse(response);
};

export const addNewArtistList = async (
  name: string,
  team: string[],
  artistId?: string
): Promise<NewListResponse> => {
  const path = "/lists/artist";
  const { data } = await base.post({
    path,
    data: {
      name,
      team,
      artistId,
    },
    variant: "directory",
  });
  return toNewListsResponse(data);
};

export const artistListManageComms = async (
  listId: string,
  team: string[]
): Promise<ListResponse> => {
  const path = `/lists/artist/${listId}/comms`;
  const { data } = await base.post({
    path,
    data: team,
    variant: "directory",
  });
  return toListResponse(data);
};

export const addNewArtistListsFolder = async (
  artistListId: string,
  folderName: string,
  parentId?: string
): Promise<NewListResponse> => {
  const path = `/lists/artist/${artistListId}/new-folder/${folderName}${
    parentId ? `?parentId=${parentId}` : ""
  }`;
  const { data } = await base.post({
    path,
    variant: "directory",
  });
  return toNewListsResponse(data);
};

export const addArtistsListToProject = async (
  listId: string,
  projectId: string
): Promise<ListResponse> => {
  const path = `/lists/artist/${listId}/add/${projectId}`;
  const { data } = await base.post({
    path,
    data: {},
    variant: "directory",
  });
  return toListResponse(data);
};

export const removeArtistsListToProject = async (
  listId: string,
  projectId: string
): Promise<ListResponse> => {
  const path = `/lists/artist/${listId}/remove/${projectId}`;
  const { data } = await base.post({
    path,
    data: {},
    variant: "directory",
  });
  return toListResponse(data);
};

export const addNewAgencyLists = async (
  newlist: NewList
): Promise<NewListResponse> => {
  const path = "/lists/agency";
  const { data } = await base.post({
    path,

    data: {
      name: newlist.name,
    },
    variant: "directory",
  });
  return toNewListsResponse(data);
};

export const addNewAgencyListEntry = async (
  agencyListEntry: AgencyListEntry
): Promise<string> => {
  const path = "/lists/agency/listentry";
  const { data } = await base.post({
    path,

    data: {
      agencyId: agencyListEntry.agencyId,
      agencyListsId: agencyListEntry.agencyListsId,
    },
    variant: "directory",
  });
  return data?.result.result;
};

export const addBulkAgencyListEntry = async (
  bulkAgencyListEntry: BulkAgencyListEntry
): Promise<boolean> => {
  const path = "/lists/agency/listentries";
  const { data } = await base.post({
    path,

    data: bulkAgencyListEntry,
    variant: "directory",
  });
  return data?.result.result ?? false;
};

export const addNewArtistListEntry = async (
  artistListEntry: ArtistListEntry
): Promise<string> => {
  const path = "/lists/artist/listsentry";
  const { data } = await base.post({
    path,

    data: {
      artistId: artistListEntry.artistId,
      artistListsId: artistListEntry.artistListsId,
      nonAltaiArtist: artistListEntry.nonAltaiArtist,
    },
    variant: "directory",
  });
  return data?.result.result;
};

export const getArtistListDetails = async (
  artistListId: string
): Promise<ArtistListDetails[]> => {
  const path = `/lists/artist/${artistListId}/details`;
  const { data } = await base.get({
    path,

    variant: "directory",
  });
  const res = parseArtistListDetails(data?.result);
  return res;
};

export const getFilteredArtistsList = async (
  artistListId: string,
  filters: ArtistsListFilters
): Promise<ArtistListDetails[]> => {
  const path = `/lists/artist/${artistListId}/details`;
  const { data } = await base.post({
    path,
    data: filters,
    variant: "directory",
  });
  return parseArtistListDetails(data?.result);
};

export const getAgencyListDetails = async (
  agencyListId: string
): Promise<AgencyListDetails> => {
  const path = `/lists/agency/${agencyListId}/details`;
  const { data } = await base.get({
    path,

    variant: "directory",
  });

  return parseAgencyListDetails(data?.result);
};

export const deleteArtistList = async (
  artistListId: string
): Promise<DeleteListResponse> => {
  const path = `/lists/artist/${artistListId}`;
  const { data } = await base.deleteFile({
    path,

    variant: "directory",
  });

  return toDeleteListResponse(data);
};

export const deleteAgencyList = async (
  agencyListId: string
): Promise<DeleteListResponse> => {
  const path = `/lists/agency/${agencyListId}`;
  const { data } = await base.deleteFile({
    path,

    variant: "directory",
  });

  return toDeleteListResponse(data);
};

export const reorderFolders = async (
  artistListId: string,
  folders: ArtistListDetails[]
): Promise<ListResponse> => {
  const path = `/lists/artist/${artistListId}/reorder`;
  const { data } = await base.post({
    path,
    data: folders.map((x) => x.id),
    variant: "directory",
  });

  return toListResponse(data);
};

export const moveArtistToFolder = async (
  folderId: string,
  artistId: string
): Promise<ListResponse> => {
  const path = `/lists/artist/${artistId}/move-to-folder/${folderId}`;
  const { data } = await base.get({
    path,
    variant: "directory",
  });

  return toListResponse(data);
};

export const moveArtistsToFolder = async (
  folderId: string,
  data: string[]
): Promise<ListResponse> => {
  const path = `/lists/artists/move-to-folder/${folderId}`;
  const res = await base.post({
    path,
    data,
    variant: "directory",
  });

  return toListResponse(res.data);
};

export const copyArtistsToFolder = async (
  folderId: string,
  data: string[]
): Promise<ListResponse> => {
  const path = `/lists/artists/copy-to-folder/${folderId}`;
  const res = await base.post({
    path,
    data,
    variant: "directory",
  });

  return toListResponse(res.data);
};

export const moveArtistNextToArtist = async (
  artist1Id: string,
  artist2Id: string
): Promise<ListResponse> => {
  const path = `/lists/artist/${artist1Id}/move-next-to-artist/${artist2Id}`;
  const { data } = await base.get({
    path,
    variant: "directory",
  });

  return toListResponse(data);
};

export const changeArtistAvailability = async (
  entryId: string,
  availability: string
): Promise<ListResponse> => {
  const path = `/lists/artist/${entryId}/change-availability/${availability}`;
  const { data } = await base.get({
    path,
    variant: "directory",
  });

  return toListResponse(data);
};

export const changeArtistVisibility = async (
  entryId: string,
  visible: boolean
): Promise<ListResponse> => {
  const path = `/lists/artist/${entryId}/change-visibility/${visible}`;
  const { data } = await base.get({
    path,
    variant: "directory",
  });

  return toListResponse(data);
};

export const renameArtistList = async (
  artistListId: string,
  newName: string
): Promise<MessageListResponse> => {
  const path = `/lists/artist/${artistListId}/rename/${newName}`;
  const { data } = await base.patch({
    path,

    variant: "directory",
  });

  return toMessageListResponse(data);
};

export const renameAgencyList = async (
  agencyListId: string,
  newName: string
): Promise<MessageListResponse> => {
  const path = `/lists/agency/${agencyListId}/rename/${newName}`;
  const { data } = await base.patch({
    path,

    variant: "directory",
  });

  return toMessageListResponse(data);
};

export const duplicateArtistList2 = async (
  artistListId: string,
  newName: string,
  isClone: boolean
): Promise<DuplicateListResponse> => {
  const path = `/lists/artist/${artistListId}/duplicate/${newName}?clone=${isClone}`;
  const { data } = await base.post({
    path,

    variant: "directory",
  });

  return toDuplicateListResponse(data);
};

export const copyArtistFolder = async (
  folderId: string,
  listId: string
): Promise<ListResponse> => {
  const path = `/lists/artist/${folderId}/copy/${listId}`;
  const { data } = await base.post({
    path,

    variant: "directory",
  });

  return toListResponse(data);
};

export const duplicateAgencyList = async (
  agencyListId: string
): Promise<DuplicateListResponse> => {
  const path = `/lists/agency/${agencyListId}/duplicate`;
  const { data } = await base.post({
    path,

    variant: "directory",
  });

  return toDuplicateListResponse(data);
};

export const removeAgencyFromList = async (
  agencyListId: string,
  agencyId: string
): Promise<ListResponse> => {
  const path = `/lists/agency/${agencyListId}/remove/${agencyId}`;
  const { data } = await base.deleteFile({
    path,

    variant: "directory",
  });

  return toListResponse(data);
};

export const nonAltaiArtistProfileUploadImage = async (
  nonAltaiArtistId: string | null,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `lists/artist/manuallyadd/uploadheadshot?nonAltaiArtistId=${nonAltaiArtistId}`;
  const { data } = await base.uploadFile({
    path,

    file,
    onProgress,
    cancelSource,
    variant: "directory",
  });
  return toResponse(data);
};

export const altaiArtistProfileUploadImage = async (
  entryId: string,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `lists/artist/altai/${entryId}/uploadheadshot`;
  const { data } = await base.uploadFile({
    path,
    file,
    onProgress,
    cancelSource,
    variant: "directory",
  });
  return toResponse(data);
};

export const nonAltaiArtistProfileDeleteImage = async (
  nonAltaiArtistId: string | null
): Promise<ListResponse> => {
  const path = `lists/artist/manuallyadd/${nonAltaiArtistId}/uploadedheadshot/remove`;
  const { data } = await base.deleteFile({
    path,

    variant: "directory",
  });
  return toListResponse(data);
};

export const cdTenanatUploadLogo = async (
  isChannelLogo: boolean,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  let path = `/users/account/castingdirector/company/upload`;
  path = isChannelLogo ? `${path}/channellogo` : `${path}/wardrobeformlogo`;
  const { data } = await base.uploadFile({
    path,
    file,
    onProgress,
    cancelSource,
    variant: "users",
  });
  return toResponse(data);
};

export const cdTenanatRemoveLogo = async (
  isChannelLogo: boolean
): Promise<ListResponse> => {
  let path = `/users/account/castingdirector/company/remove`;
  path = isChannelLogo ? `${path}/channellogo` : `${path}/wardrobeformlogo`;
  const { data } = await base.deleteFile({
    path,
    variant: "users",
  });
  return toListResponse(data);
};

export const addNewNonAltaiArtist = async (
  artistListId: string,
  nonAltaiArtist: NonAltaiArtistRequest
): Promise<CreateProjectResponse> => {
  const path = `/lists/artist/${artistListId}/manuallyadd`;
  const { data } = await base.post({
    path,

    data: nonAltaiArtist,
    variant: "directory",
  });
  return toResponse(data);
};

export const editNewNonAltaiArtist = async (
  nonAltaiArtist: NonAltaiArtistRequest
): Promise<CreateProjectResponse> => {
  const path = `/lists/artist/nonaltai/edit`;
  const { data } = await base.post({
    path,

    data: nonAltaiArtist,
    variant: "directory",
  });
  return toResponse(data);
};

export const editAltaiArtist = async (
  entryId: string,
  nonAltaiArtist: NonAltaiArtistRequest
): Promise<CreateProjectResponse> => {
  const path = `/lists/artist/altai/${entryId}/edit`;
  const { data } = await base.post({
    path,
    data: nonAltaiArtist,
    variant: "directory",
  });
  return toResponse(data);
};

export const getNonAltaiArtistDetails = async (
  nonAltaiArtistId: string
): Promise<NonAltaiArtistDetails> => {
  const path = `/lists/artist/nonaltai/${nonAltaiArtistId}/details`;
  const { data } = await base.get({
    path,

    variant: "directory",
  });

  return parseNonAltaiArtistDetails(data?.result);
};

export const getAltaiArtistDetails = async (
  entryId: string
): Promise<AltaiArtistDetails> => {
  const path = `/lists/artist/altai/${entryId}/details`;
  const { data } = await base.get({
    path,
    variant: "directory",
  });

  return parseAltaiArtistDetails(data?.result);
};

/********************************************** */
// Status reports
/********************************************** */
export const getAgencyStatusReports = async (
  isArtist: boolean
): Promise<StatusReportItem[]> => {
  const path = `/roles/statusreports${isArtist ? "/artist" : ""}`;
  const { data } = await base.get({
    path,

    variant: "roles",
  });

  return data?.result ?? [];
};

export const getAgentStatusReportTable = async (
  getArchived: boolean
): Promise<StatusReportTableItem[]> => {
  const path = `/roles/statusreports/agent-table?getArchived=${getArchived}`;
  const { data } = await base.get({
    path,
    variant: "roles",
  });

  return data?.result.map(toStatusReportTableItem) ?? [];
};

export const getArtistStatusReportTable = async (
  getArchived: boolean
): Promise<StatusReportTableItem[]> => {
  const path = `/roles/statusreports/artist-table?getArchived=${getArchived}`;
  const { data } = await base.get({
    path,
    variant: "roles",
  });

  return data?.result.map(toStatusReportTableItem) ?? [];
};

export const markSubmissionsAsActive = async (
  submissionIds: string[],
  isArtist: boolean
): Promise<boolean> => {
  const path = `/roles/statusreports/${
    isArtist ? "artist" : "agency"
  }/markSubmissionsAsActive`;

  const { data } = await base.patch({
    path,
    data: submissionIds,
    variant: "roles",
  });

  return data?.result ?? false;
};

export const markSubmissionsAsArchived = async (
  submissionIds: string[],
  isArtist: boolean
): Promise<boolean> => {
  const path = `/roles/statusreports/${
    isArtist ? "artist" : "agency"
  }/markSubmissionsAsArchived`;

  const { data } = await base.patch({
    path,
    data: submissionIds,
    variant: "roles",
  });

  return data?.result ?? false;
};

export const forwardSelectedSubmissionsToArtists = async (
  request: ForwardMultipleStatusReportItemsToArtistRequest
): Promise<boolean> => {
  const path = "/roles/statusreports/forwardStatusReportRequestsToArtists";

  const { data } = await base.post({
    path,
    data: request,
    variant: "roles",
  });

  return data?.result ?? false;
};

export const getStatusReportDetails = async (
  submissionId: string,
  isArtist: boolean
): Promise<StatusReportDetails> => {
  const path = `/roles/statusreports${
    isArtist ? "/artist" : ""
  }/${submissionId}`;
  const { data } = await base.get({
    path,

    variant: "roles",
  });
  return toStatusReportDetails(data);
};

export const markGeneralMessagesAsViewedByAgency = async (
  submissionId: string
): Promise<boolean> => {
  const path = `/roles/statusreports/${submissionId}/markMessagesAsViewed`;
  const { data } = await base.patch({
    path,
    variant: "roles",
  });
  return data?.result ?? false;
};

export const cdStatusReportGetWardrobeForm = async (
  submissionId: string,
  artistProfileId: string
): Promise<StatusReportWardrobeDetails> => {
  const path = `/roles/CdStatusReports/artist/${artistProfileId}/${submissionId}/wardrobe-form`;
  const { data } = await base.get({
    path,

    variant: "roles",
  });
  return toStatusReportWardrobeDetails(data);
};

export const statusReportGetWardrobeForm = async (
  submissionId: string,
  userType: UserType
): Promise<StatusReportWardrobeDetails> => {
  //Default is for agent user type
  var path = `/roles/statusreports/${submissionId}/wardrobe-form`;

  if (userType === "CastingDirector") {
    path = `/roles/cdstatusreports/castingdirector/${submissionId}/wardrobe-form`;
  }
  if (userType === "Artist") {
    path = `/roles/statusreports/artist/${submissionId}/wardrobe-form`;
  }
  const { data } = await base.get({
    path,

    variant: "roles",
  });
  return toStatusReportWardrobeDetails(data);
};

export const getWardrobeLatestSubmissionInfo = async (
  wardrobeFormId: string
): Promise<WardrobeLatestSubmissionInfo | null> => {
  const path = `/roles/CdStatusReports/wardrobe/${wardrobeFormId}`;
  const { data } = await base.get({
    path,
    variant: "roles",
  });

  return data?.result;
};

export const statusReportGetCompletedWardrobeForm = async (
  submissionId: string,
  wardrobeFormId: string,
  isArtist: boolean
): Promise<CompletedWardrobeFormDetails> => {
  const path = `/roles/statusreports${
    isArtist ? "/artist" : ""
  }/${submissionId}/wardrobe-form/${wardrobeFormId}`;
  const { data } = await base.get({
    path,

    variant: "roles",
  });
  return toCompletedWardrobeFormDetails(data);
};

export const statusReportSubmitWardrobeForm = async (
  submissionId: string,
  userType: string | null,
  values: any
): Promise<CreateProjectResponse> => {
  //Default is for agent user type
  var path = `/roles/statusreports/${submissionId}/wardrobe-form/save`;
  if (userType === "Artist") {
    path = `/roles/statusreports/artist/${submissionId}/wardrobe-form/save`;
  }

  if (userType === "CastingDirector") {
    path = `/roles/cdstatusreports/castingdirector/${submissionId}/wardrobe-form/save`;
  }

  const { data } = await base.post({
    path,

    data: values,
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportEditSubmitWardrobeForm = async (
  submissionId: string,
  values: any
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/wardrobe/edit/submission/${submissionId}/save`;
  const { data } = await base.post({
    path,
    data: values,
    variant: "roles",
  });
  return toResponse(data);
};

export const statusReportDownloadProjectFile = async (
  submissionId: string,
  fileId: string,
  isArtist: boolean
): Promise<DownloadedFilePathResponse> => {
  const path = `/roles/statusreports${
    isArtist ? "/artist" : ""
  }/${submissionId}/download/project/${fileId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toDownloadedFilePathResponse(data);
};

export const statusReportDownloadRoleFile = async (
  submissionId: string,
  fileId: string,
  isArtist: boolean
): Promise<DownloadedFilePathResponse> => {
  const path = `/roles/statusreports${
    isArtist ? "/artist" : ""
  }/${submissionId}/download/role/${fileId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toDownloadedFilePathResponse(data);
};

export const statusReportDownloadSignedNdaFile = async (
  submissionId: string,
  fileId: string,
  isArtist: boolean
): Promise<DownloadedFilePathResponse> => {
  const path = `/roles/statusreports${
    isArtist ? "/artist" : ""
  }/${submissionId}/download/nda/${fileId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toDownloadedFilePathResponse(data);
};

export const statusReportDownloadStatusFile = async (
  submissionId: string,
  fileId: string,
  isArtist: boolean
): Promise<DownloadedFilePathResponse> => {
  const path = `/roles/statusreports${
    isArtist ? "/artist" : ""
  }/${submissionId}/download/status/${fileId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toDownloadedFilePathResponse(data);
};

export const wardrobeDownloadQuestionFile = async (
  submissionId: string,
  fileId: string,
  isArtist: boolean
): Promise<DownloadedFilePathResponse> => {
  const path = `/roles/statusreports${
    isArtist ? "/artist" : ""
  }/${submissionId}/download/question/${fileId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toDownloadedFilePathResponse(data);
};

export const statusReportRespondToRoleStatus = async (
  submissionId: string,
  message: string | null,
  accepted: boolean,
  informArtistOfStatusChange: boolean,
  shareCastingMessageWithArtist: boolean,
  isArtist: boolean,
  documents: UploadedFile[],
  isWithdraw: boolean
): Promise<CreateProjectResponse> => {
  const path = `/roles/statusreports${
    isArtist ? "/artist" : ""
  }/${submissionId}/respondtostatus`;
  const { data } = await base.post({
    path,
    data: {
      message: message,
      accepted: accepted === true,
      informArtistOfStatusChange: informArtistOfStatusChange === true,
      shareCastingMessageWithArtist: shareCastingMessageWithArtist === true,
      documentIds: documents?.map((d) => d.id!) ?? [],
      isWithdraw: isWithdraw,
    },
    variant: "roles",
  });
  return toResponse(data);
};

export const statusReportForwardStatusToArtist = async (
  submissionId: string,
  message: string | null,
  includeMessageFromCasting: boolean
): Promise<CreateProjectResponse> => {
  const path = `/roles/statusreports/${submissionId}/forwardstatus`;
  const { data } = await base.post({
    path,
    data: {
      message: message,
      includeMessageFromCasting: includeMessageFromCasting === true,
    },
    variant: "roles",
  });
  return toResponse(data);
};

export const statusReportForwardReleasedToArtist = async (
  submissionId: string,
  message: string | null,
  includeMessageFromCasting: boolean
): Promise<CreateProjectResponse> => {
  const path = `/roles/statusreports/${submissionId}/forwardreleased`;
  const { data } = await base.post({
    path,
    data: {
      message: message,
      includeMessageFromCasting: includeMessageFromCasting === true,
    },
    variant: "roles",
  });
  return toResponse(data);
};

export const statusReportAddRoleStatusMessage = async (
  submissionId: string,
  message: string | null
): Promise<CreateProjectResponse> => {
  const path = `/roles/statusreports/${submissionId}/messagestatus`;
  const { data } = await base.post({
    path,
    data: message,
    variant: "roles",
  });
  return toResponse(data);
};

export const statusReportAddGeneralMessage = async (
  submissionId: string,
  message: string | null
): Promise<CreateProjectResponse> => {
  const path = `/roles/statusreports/${submissionId}/generalmessage`;
  const { data } = await base.post({
    path,
    data: { message: message },
    variant: "roles",
  });
  return toResponse(data);
};

export const sessionAgentRespondForArtist = async (
  submissionId: string,
  sessionId: string,
  responseType: number,
  message: string | null,
  isArtist: boolean,
  isSelfTape: boolean,
  tapeManagementType: string | null
): Promise<CreateProjectResponse> => {
  const path = isSelfTape
    ? `/roles/statusreports${
        isArtist ? "/artist" : ""
      }/${submissionId}/${sessionId}/self-tape/respond`
    : `/roles/statusreports${
        isArtist ? "/artist" : ""
      }/${submissionId}/${sessionId}/respond`;
  const { data } = await base.post({
    path,

    data: {
      responseType,
      message,
      tapeManagementType,
    },
    variant: "roles",
  });
  return toResponse(data);
};

export const sessionAgentForwardToArtist = async (
  submissionId: string,
  sessionId: string,
  message: string | null,
  isSelfTape: boolean,
  tapeManagementType: string | null
): Promise<CreateProjectResponse> => {
  const path = isSelfTape
    ? `/roles/statusreports/${submissionId}/${sessionId}/self-tape/forward`
    : `/roles/statusreports/${submissionId}/${sessionId}/forward`;
  const { data } = await base.post({
    path,

    data: {
      message,
      tapeManagementType,
    },
    variant: "roles",
  });
  return toResponse(data);
};

export const statusReportsUploadRoleStatusDoc = async (
  submissionId: string,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<UploadedFile> => {
  const path = `/roles/statusreports/uploadrolestatusdoc/${submissionId}`;
  const { data } = await base.uploadFile({
    path,

    file,
    onProgress,
    cancelSource,
    variant: "roles",
  });
  return toUploadedRoleStatusDoc(data);
};

export const statusReportsUploadRoleStatusDocForArtist = async (
  submissionId: string,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<UploadedFile> => {
  const path = `/roles/statusreports/artist/uploadrolestatusdoc/${submissionId}`;
  const { data } = await base.uploadFile({
    path,

    file,
    onProgress,
    cancelSource,
    variant: "roles",
  });
  return toUploadedRoleStatusDoc(data);
};

export const uploadSignedNda = async (
  submissionId: string,
  userType: UserType,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `/roles/statusreports${
    userType === "Artist" ? "/artist" : ""
  }/uploadnda/${submissionId}`;
  const { data } = await base.uploadFile({
    path,

    file,
    onProgress,
    cancelSource,
    variant: "roles",
  });

  return toResponse(data);
};

export const wardrobeUploadQuestionFile = async (
  submissionId: string,
  questionId: string,
  userType: UserType,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `/roles/statusreports${
    userType === "Artist" ? "/artist" : ""
  }/upload/${submissionId}/${questionId}`;
  const response = await base.uploadFile({
    path,
    file,
    onProgress,
    cancelSource,
    variant: "roles",
  });

  return toResponse(response?.data);
};

export const wardrobeDeleteQuestionFile = async (
  submissionId: string,
  userType: UserType,
  fileId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/statusreports${
    userType === "Artist" ? "/artist" : ""
  }/delete/${submissionId}/${fileId}`;
  const { data } = await base.deleteFile({
    path,

    variant: "roles",
  });
  return toResponse(data);
};

export const projectSelfTapeDetails = async (
  projectId: string,
  selfTapeRequestId: string
): Promise<SelfTapeRequestDetails> => {
  const path = `/roles/sessions/${projectId}/self-tape/request/${selfTapeRequestId}`;
  const { data } = await base.get({
    path,

    variant: "roles",
  });
  return toSelfTapeRequestDetails(data?.result);
};

export const removeProjectSelfTapeRequest = async (
  projectId: string,
  selfTapeRequestId: string,
  artistId: string | null,
  message: string | null
): Promise<ListResponse> => {
  let queryStr = "";

  if (artistId !== null) {
    queryStr = queryStr + `artistId=${artistId}&`;
  }

  if (message !== null) {
    queryStr = queryStr + `message=${message}`;
  }

  const path = `/roles/sessions/${projectId}/self-tape/request/${selfTapeRequestId}/cancel?${queryStr}`;
  const { data } = await base.deleteFile({
    path,

    variant: "roles",
  });
  return toListResponse(data);
};

export const sendProjectSelfTapeRequest = async (
  projectId: string,
  selfTapeRequestId: string,
  message: string | null
): Promise<ListResponse> => {
  let queryStr = "";

  if (message !== null) {
    queryStr = queryStr + `message=${message}`;
  }

  const path = `/roles/sessions/${projectId}/self-tape/chaseSelfTape`;
  const { data } = await base.post({
    path,
    data: { message: message, selfTaperequestIds: [selfTapeRequestId] },

    variant: "roles",
  });
  return toListResponse(data);
};

export const sendNudgeTapeRequest = async (
  projectId: string,
  selfTapeRequestId: string,
  message: string | null
): Promise<ListResponse> => {
  let queryStr = "";

  if (message !== null) {
    queryStr = queryStr + `message=${message}`;
  }

  const path = `/roles/sessions/${projectId}/self-tape/nudgeRequest`;
  const { data } = await base.post({
    path,
    data: { message: message, selfTaperequestIds: [selfTapeRequestId] },

    variant: "roles",
  });
  return toListResponse(data);
};

export const performDirectoryArtistSearch = async (
  artistName: string,
  roleId: string | null
): Promise<SelfTapeArtistProfile[]> => {
  let queryStr = "";

  if (artistName !== null) {
    queryStr = queryStr + `artistName=${artistName}&`;
  }
  if (roleId !== null) {
    queryStr = queryStr + `roleId=${roleId}`;
  }
  const path = `/roles/sessions/self-tape/getDirectoryArtists?${queryStr}`;
  const { data } = await base.get({
    path,
    variant: "roles",
  });
  return data?.result;
};

export const selfTapeProfileSubmit = async (
  projectId: string,
  profileId: string,
  roleId: string
): Promise<string> => {
  const path = `/roles/submissions/submit/${projectId}/${roleId}`;
  const { data } = await base.post({
    path,
    data: {
      artistSubmissionRequests: [{ artistProfileId: profileId }],
    },
    variant: "roles",
  });
  console.log("submit" + data?.result);
  return data?.result[0];
};

export const sendMessageAgentTapeRequest = async (
  projectId: string,
  selfTapeRequestId: string,
  message: string | null,
  viewSelfTapeMessageFilter: ViewSelfTapeMessageFilter
): Promise<ListResponse> => {
  let queryStr = "";

  if (message !== null) {
    queryStr = queryStr + `message=${message}`;
  }

  const path = `/roles/sessions/${projectId}/self-tape/messageAgents`;

  var messageRequest = {
    Message: message,
    SelfTaperequestIds: [selfTapeRequestId],
    SelfTapeMessageFilter: {
      Requested: viewSelfTapeMessageFilter.requested.checked,
      Confirm: viewSelfTapeMessageFilter.confirmed.checked,
      Recieved: viewSelfTapeMessageFilter.recieved.checked,
      Decline: viewSelfTapeMessageFilter.decline.checked,
    },
  };
  const { data } = await base.post({
    path,
    data: messageRequest,

    variant: "roles",
  });
  return toListResponse(data);
};

export const sendExtendedDeadlineTapeRequest = async (
  projectId: string,
  selfTapeDeadLineExtendRequest: SelfTapeDeadLineExtendRequest
): Promise<ListResponse> => {
  const path = `/roles/sessions/${projectId}/self-tape/sendExtendedDeadlineTapeRequest`;

  var extendedDeadline = {
    Message: selfTapeDeadLineExtendRequest.message,
    SelfTapeParticipantsIds:
      selfTapeDeadLineExtendRequest.selfTapeRequestParticipantsId,
    DueDate: formatSelftapeDueDate(
      selfTapeDeadLineExtendRequest.date!,
      selfTapeDeadLineExtendRequest.timeZone.value
    ),
    DueTime: formatSelftapeDueTime(
      selfTapeDeadLineExtendRequest.time!,
      selfTapeDeadLineExtendRequest.timeZone.value
    ),
    TimeZone: selfTapeDeadLineExtendRequest.timeZone.value,
  };
  const { data } = await base.post({
    path,
    data: extendedDeadline,

    variant: "roles",
  });
  return toListResponse(data);
};

export const updateSelfTapeRequestStatus = async (
  selfTapeRequestId: string,
  statusId: string,
  projectId: string,
  artisId: string
): Promise<ListResponse> => {
  let queryStr = "";
  const statusUpdateSelftapeRequest = {
    statusStage: statusId,
    artisProfileId: artisId,
  };
  const path = `/roles/sessions/${projectId}/self-tape/${selfTapeRequestId}/updateSelfTapeStatus`;
  const { data } = await base.post({
    path,
    data: statusUpdateSelftapeRequest,

    variant: "roles",
  });
  return toListResponse(data);
};

export const getSelfTapeClips = async (
  submissionId: string,
  auditionIds: string[]
): Promise<SelfTapeClipDetails[]> => {
  const path = `/roles/statusreports/${submissionId}/self-tape/clips?auditionIds=${auditionIds.join(
    ","
  )}`;
  const { data } = await base.get({
    path,

    variant: "roles",
  });

  return data?.result?.map(toSelfTapeClipDetails) ?? [];
};

export const isWardrobeformComplete = async (
  submissionId: string
): Promise<boolean> => {
  const path = `/roles/statusreports/${submissionId}/isWardrobeformComplete`;
  const { data } = await base.get({
    path,

    variant: "roles",
  });

  return data?.result ?? false;
};

export const uploadSelfTapeClip = async (
  submissionId: string,
  auditionId: string,
  uploadByAgent: boolean,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<SelfTapeClipDetails> => {
  const path = `/roles/statusreports/${submissionId}/${auditionId}/self-tape/upload?uploadByAgent=${uploadByAgent}`;
  // Because triggering the cancellation token returns a null response,
  // we cant automatically unpack the data node
  const response = await base.uploadFile({
    path,
    file,
    onProgress,
    cancelSource,
    variant: "roles",
  });

  return toSelfTapeClipDetails(response?.data?.result);
};

export const removeSelfTapeClip = async (
  auditionId: string,
  clipId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/statusreports/${auditionId}/self-tape/clip/${clipId}/delete`;
  const { data } = await base.deleteFile({
    path,

    variant: "roles",
  });
  return toResponse(data);
};

export const updateSelfTapeClipTitle = async (
  auditionId: string,
  clipId: string,
  newTitile: string
): Promise<boolean> => {
  const path = `/roles/statusreports/${auditionId}/self-tape/clip/${clipId}/change-title/${newTitile}`;
  const { data } = await base.patch({
    path,

    variant: "roles",
  });
  return data?.result ?? false;
};

export const reOrderSelfTapeClips = async (
  auditionId: string,
  reOrderRequest: SelfTapeClipReorder[]
): Promise<boolean> => {
  const path = `/roles/statusreports/${auditionId}/self-tape/clip/re-order`;
  const { data } = await base.patch({
    path,

    data: reOrderRequest,
    variant: "roles",
  });
  return data?.result ?? false;
};

export const submmitSelfTapes = async (
  submissionId: string,
  auditionId: string,
  selfTapeNote: string,
  submmitByAgent: boolean,
  submitToReview: boolean
): Promise<ViewProjectResponse> => {
  let path = `/roles/statusreports/${submissionId}/${auditionId}/self-tape`;
  if (submitToReview) {
    path = `${path}/submit-to-review`;
  } else {
    path = `${path}/submit-to-casting`;
  }

  let requestData = {
    selfTapeNote: "",
    isRequestByAgent: false,
  };

  if (submmitByAgent) {
    requestData.isRequestByAgent = submmitByAgent;
    requestData.selfTapeNote = selfTapeNote;
  }

  const { data } = await base.post({
    path,
    data: requestData,
    variant: "roles",
  });
  return toProjectResponse(data?.result);
};

export const checkSelfTapeReady = async (
  clipId: string
): Promise<SelfTapeClipStatus> => {
  let path = `/roles/statusreports/self-tape/clip/${clipId}/check-is-ready`;
  const { data } = await base.post({
    path,
    data: null,
    variant: "roles",
  });
  return toSelfTapeClipStatus(data);
};

export const setClipReviewed = async (clipId: string): Promise<boolean> => {
  let path = `/roles/statusreports/self-tape/clip/${clipId}/set-reviewed`;
  const { data } = await base.patch({
    path,

    variant: "roles",
  });
  return data?.result ?? false;
};

export const trimSelfTapeClip = async (
  clipId: string,
  startTime: number,
  endTime: number
): Promise<CreateProjectResponse> => {
  let path = `/roles/statusreports/self-tape/clip/${clipId}/trim`;
  const { data } = await base.post({
    path,
    data: {
      startTime: startTime,
      endTime: endTime,
    },
    variant: "roles",
  });
  return toResponse(data);
};

/********************************************** */
// CD Status reports
/********************************************** */

export const cdStatusReportGetBadgeCounts = async (
  projectId: string
): Promise<StatusReportBadgeCounts> => {
  const path = `/roles/cdstatusreports/${projectId}/overview`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result;
};

export const cdStatusReportGetUnviewedDocs = async (
  projectId: string
): Promise<StatusReportDocument[]> => {
  const path = `/roles/cdstatusreports/${projectId}/docs`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result ?? [];
};

export const cdStatusReportGetTapesFilters = async (
  projectId: string,
  name: string
): Promise<FilterValue[]> => {
  const path = `/roles/cdstatusreports/${projectId}/v2/tapes/${name}`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result ?? [];
};

export const cdStatusReportGetTapesRow = async (
  projectId: string,
  rowId: string,
  page: number,
  pageSize: number
): Promise<StatusReportThumbnailsRow[]> => {
  const path = `/roles/cdstatusreports/${projectId}/${rowId}/v2/tapesrow?page=${page}&pageSize=${pageSize}`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result ?? [];
};

export const cdStatusReportGetFolderTapeDetails = async (
  projectId: string,
  rowId: string,
  tapeId: string
): Promise<StatusReportThumbnailsRow> => {
  const path = `/roles/cdstatusreports/${projectId}/${rowId}/tape/${tapeId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result;
};

export const cdStatusReportGetTapesV2 = async (
  projectId: string
): Promise<StatusReportThumbnailsRow[]> => {
  const path = `/roles/cdstatusreports/${projectId}/v2/tapes`;
  const { data } = await base.get({ path, variant: "roles" });
  return (data?.result ?? []).map(toStatusReportThumbnailsRow);
};

export const cdStatusReportGetFilteredTapes = async (
  projectId: string,
  filters: {
    [key: string]: string[];
  },
  page: number,
  pageSize: number
): Promise<StatusReportThumbnailsRow[]> => {
  const path = `/roles/cdstatusreports/${projectId}/v2/tapes?page=${page}&pageSize=${pageSize}`;
  console.log("cdStatusReportGetFilteredTapes", projectId, filters);
  const { data } = await base.post({ path, data: filters, variant: "roles" });
  return data?.result ?? [];
};

export const cdStatusReportGetFilteredTapePage = async (
  projectId: string,
  folderId: string,
  filters: {
    [key: string]: string[];
  },
  page: number,
  pageSize: number
): Promise<StatusReportThumbnailsRow[]> => {
  const path = `/roles/cdstatusreports/${projectId}/folder/${folderId}/tapes?page=${page}&pageSize=${pageSize}`;
  const { data } = await base.post({ path, data: filters, variant: "roles" });
  return data?.result ?? [];
};

export const cdStatusReportFolderTapesForSelectAll = async (
  projectId: string,
  folderId: string,
  filters: {
    [key: string]: string[];
  }
): Promise<StatusReportThumbnailsRow[]> => {
  const path = `/roles/cdstatusreports/${projectId}/folder/${folderId}/alltapes`;
  const { data } = await base.post({ path, data: filters, variant: "roles" });
  return data?.result ?? [];
};

export const requestThumbnailProfileImages = async (
  projectId: string,
  imageRefs: string[]
): Promise<TapeProfileImages[]> => {
  const path = `/roles/cdstatusreports/${projectId}/tapes/profileImages`;
  const { data } = await base.post({ path, data: imageRefs, variant: "roles" });
  return data?.result ?? [];
};

export const cdStatusReportGetTape = async (
  projectId: string,
  tapeId: string
): Promise<TapeResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/tapes/${tapeId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toTapeResponse(data);
};

export const cdStatusReportGetTapePanelDetails = async (
  projectId: string,
  tapeId: string
): Promise<StatusReportTapePanelDetailsResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/tape/${tapeId}/details`;
  const { data } = await base.get({ path, variant: "roles" });
  return toStatusReportTapePanelDetailsResponse(data);
};

export const channelGetTapesForExternalAccess = async (
  channelId: string,
  folderId: string,
  tapeId: string,
  forCdChannelView?: boolean
): Promise<TapeResponse> => {
  const path = `/external/channel/${channelId}/folder/${folderId}/tapes/${tapeId}/v2?forCdChannelView=${
    forCdChannelView ?? false
  }`;
  const { data } = forCdChannelView
    ? await base.get({ path, variant: "external" })
    : await base.getAnonymous({ path, variant: "external" });
  return toTapeResponse(data);
};

export const channelGetTapePanelDetailsForExternalAccess = async (
  channelId: string,
  folderId: string,
  tapeId: string
): Promise<StatusReportTapePanelDetailsResponse> => {
  const path = `/external/channel/${channelId}/folder/${folderId}/tape/${tapeId}/details`;
  const { data } = await base.getAnonymous({ path, variant: "external" });
  return toStatusReportTapePanelDetailsResponse(data);
};

export const channelTapeGetClipSourceUrl = async (
  videoId: number
): Promise<string> => {
  const path = `/external/channel/${videoId}/GetClipSourceUrl`;
  const { data } = await base.getAnonymous({ path, variant: "external" });
  return data?.result ?? "";
};

export const cdStatusReportGetTapeProfiles = async (
  projectId: string,
  tapeId: string
): Promise<ProjectSelectedArtist[]> => {
  const path = `/roles/cdstatusreports/${projectId}/tapes/${tapeId}/profiles`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result?.map(toCdStatusReportProfile) ?? [];
};

export const cdStatusReportGetTapeProfilesV2 = async (
  projectId: string,
  tapeId: string
): Promise<StatusReportTapePanelProfile[]> => {
  const path = `/roles/cdstatusreports/${projectId}/tapes/${tapeId}/v2/profiles`;
  const { data } = await base.get({ path, variant: "roles" });

  return (
    data?.result?.map(toCdStatusReportProfileWithCompletedArtistForms) ?? []
  );
};

export const cdStatusReportGetTapePanelNavigationIds = async (
  projectId: string,
  tapeId: string,
  folderId: string
): Promise<StatusReportGetTapePanelNavigation> => {
  const path = `/roles/cdstatusreports/${projectId}/tapes/${folderId}/${tapeId}/panelnavigationids`;
  const { data } = await base.get({ path, variant: "roles" });
  return {
    previousTapeId: data?.result?.previousTapeId ?? null,
    nextTapeId: data?.result?.nextTapeId ?? null,
  };
};

export const cdStatusReportGetTapePanelNavigationIdsForExternalAccess = async (
  channelId: string,
  tapeId: string,
  folderId: string
): Promise<StatusReportGetTapePanelNavigation> => {
  const path = `/external/channel/${channelId}/folder/${folderId}/tape/${tapeId}/panelnavigationids`;
  const { data } = await base.get({ path, variant: "external" });
  return {
    previousTapeId: data?.result?.previousTapeId ?? null,
    nextTapeId: data?.result?.nextTapeId ?? null,
  };
};

export const cdStatusReportGetTapePanelSharedChannels = async (
  projectId: string,
  tapeId: string
): Promise<ProfileSharedChannel[]> => {
  const path = `/roles/cdstatusreports/${projectId}/tape/${tapeId}/sharedchannels`;

  const { data } = await base.get({
    path,
    variant: "roles",
  });
  return data?.result ?? [];
};

export const cdStatusReportGetProfilesEditedKeyVitals = async (
  projectId: string,
  tapeId: string
): Promise<EditedArtistProfileInfo[]> => {
  const path = `/roles/cdstatusreports/${projectId}/tape/${tapeId}/artist/edited-key-vitals`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result ?? [];
};

export const cdStatusReportGetProfilesEditedKeyVitalsForExternalAccess = async (
  channelId: string,
  tapeId: string
): Promise<EditedArtistProfileInfo[]> => {
  const path = `/external/channel/${channelId}/tape/${tapeId}/artist/edited-key-vitals`;
  const { data } = await base.get({ path, variant: "external" });
  return data?.result ?? [];
};

export const cdStatusReportGetRoleBasedProfilesEditedKeyVitals = async (
  projectId: string,
  tapeId: string
): Promise<EditedArtistProfileInfo[]> => {
  const path = `/roles/cdstatusreports/${projectId}/tape/${tapeId}/artist/role-based-edited-key-vitals`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result ?? [];
};

export const cdStatusReportGetRoleBasedProfilesEditedKeyVitalsForExternalAccess =
  async (
    channelId: string,
    tapeId: string
  ): Promise<EditedArtistProfileInfo[]> => {
    const path = `/external/channel/${channelId}/tape/${tapeId}/artist/role-based-edited-key-vitals`;
    const { data } = await base.get({ path, variant: "external" });
    return data?.result ?? [];
  };

export const cdStatusReportSaveProfileEditedKeyVitals = async (
  projectId: string,
  tapeId: string,
  artistProfileId: string,
  editedKeyVitals: EditedArtistKeyVital[]
): Promise<boolean> => {
  const path = `/roles/cdstatusreports/${projectId}/tape/${tapeId}/artist/${artistProfileId}/alter-keyvitals`;
  const { data } = await base.post({
    path,
    data: editedKeyVitals,
    variant: "roles",
  });
  return data?.result ?? false;
};

export const cdStatusReportSaveRoleProfileEditedKeyVitals = async (
  projectId: string,
  roleId: string,
  artistProfileId: string,
  editedKeyVitals: EditedArtistKeyVital[]
): Promise<boolean> => {
  const path = `/roles/cdstatusreports/${projectId}/role/${roleId}/artist/${artistProfileId}/alter-keyvitals`;
  const { data } = await base.post({
    path,
    data: editedKeyVitals,
    variant: "roles",
  });
  return data?.result ?? false;
};

export const cdStatusReportGetTapeVideoDetails = async (
  videoId: number
): Promise<StatusReportTapeVideoDetails> => {
  const path = `/roles/cdstatusreports/tapes/video/${videoId}/details`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result ?? { src: "", tapeReady: false };
};

export const cdStatusReportGetTapeProfilesForExternalAccess = async (
  channelId: string,
  projectId: string,
  tapeId: string
): Promise<ProjectSelectedArtist[]> => {
  const path = `/external/channel/${channelId}/project/${projectId}/tapes/${tapeId}/profiles-v2`;
  const { data } = await base.getAnonymous({ path, variant: "external" });
  return data?.result?.map(toCdStatusReportProfile) ?? [];
};

export const cdStatusReportGetTapeArtistsForExternalAccess = async (
  channelId: string,
  projectId: string,
  tapeId: string
): Promise<StatusReportTapePanelProfile[]> => {
  const path = `/external/channel/${channelId}/project/${projectId}/tape/${tapeId}/artists`;
  const { data } = await base.getAnonymous({ path, variant: "external" });

  return (
    data?.result?.map(toCdStatusReportProfileWithCompletedArtistForms) ?? []
  );
};

export const getChannelFolderNames = async (
  channelId: string,
  folderId: string
): Promise<ChannelFolderNames> => {
  const path = `/external/channel/${channelId}/folder/${folderId}/info`;
  const { data } = await base.getAnonymous({ path, variant: "external" });
  return (
    data?.result ?? {
      channelId: "",
      channelName: "",
      folderId: "",
      folderName: "",
    }
  );
};

export const getAgenciesForManageStatusFilter = async (
  projectId: string
): Promise<string[]> => {
  const path = `/roles/cdstatusreports/${projectId}/managestatus/agencies`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result ?? [];
};

export const cdStatusReportGetManageStatusProfilesV2 = async (
  projectId: string,
  queryString: string
): Promise<StatusReportManageStatusRole[]> => {
  const path = `/roles/cdstatusreports/${projectId}/v2/profiles?${queryString}`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result?.map(toStatusReportManageStatusRoles) ?? [];
};

export const cdStatusReportGetManageStatusProfilesFolder = async (
  projectId: string,
  folderId: string,
  page: number,
  pageSize: number,
  queryString: string
): Promise<StatusReportProfileArtist[]> => {
  const path = `/roles/cdstatusreports/${projectId}/v2/profiles/${folderId}?${queryString}&page=${page}&pageSize=${pageSize}`;
  const { data } = await base.get({ path, variant: "roles" });
  const res = data?.result?.map(parseStatusReportProfileArtist) ?? [];
  return res;
};

export const cdStatusReportGetManageStatusFolderProfileList = async (
  projectId: string,
  folderId: string,
  queryString: string
): Promise<SubmissionInFolder[]> => {
  const path = `/roles/cdstatusreports/${projectId}/managestatus/folder/${folderId}/profilelist?${queryString}`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result ?? [];
};

export const cdStatusReportDeleteManualTapeCollectionClip = async (
  projectId: string,
  tapeId: string,
  clipId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/clips/delete/${tapeId}/${clipId}`;
  const { data } = await base.deleteFile({ path, variant: "roles" });
  return toResponse(data);
};

export const cdStatusReportGetCompletedWardrobeFormForTape = async (
  projectId: string,
  tapeId: string,
  wardrobeFormId: string
): Promise<CompletedWardrobeFormDetails> => {
  const path = `/roles/cdstatusreports/${projectId}/tapes/${tapeId}/wardrobe/${wardrobeFormId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toCompletedWardrobeFormDetails(data);
};

export const cdStatusReportGetWardrobeFormForEdit = async (
  projectId: string,
  tapeId: string | null,
  wardrobeFormId: string
): Promise<CompletedWardrobeFormDetails> => {
  const path = `/roles/cdstatusreports/${projectId}/wardrobe/${wardrobeFormId}/foredit?tapes=${tapeId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toCompletedWardrobeFormDetails(data);
};

export const cdStatusReportGetCompletedWardrobeForm = async (
  projectId: string,
  wardrobeFormId: string
): Promise<CompletedWardrobeFormDetails> => {
  const path = `/roles/cdstatusreports/${projectId}/wardrobe/${wardrobeFormId}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toCompletedWardrobeFormDetails(data);
};

export const getCompletedWardrobeFormForTapeExternalAccess = async (
  projectId: string,
  tapeId: string,
  wardrobeFormId: string
): Promise<CompletedWardrobeFormDetails> => {
  const path = `/external/channel/${projectId}/tapes/${tapeId}/wardrobe/${wardrobeFormId}`;
  const { data } = await base.getAnonymous({ path, variant: "external" });
  return toCompletedWardrobeFormDetails(data);
};

export const getCompletedWardrobeFormForExternalAccess = async (
  wardrobeFormId: string
): Promise<CompletedWardrobeFormDetails> => {
  const path = `/external/channel/wardrobe/${wardrobeFormId}`;
  const { data } = await base.getAnonymous({ path, variant: "external" });
  return toCompletedWardrobeFormDetails(data);
};

export const cdStatusReportAddMessageToStatus = async (
  projectId: string,
  submissionId: string,
  message: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/${submissionId}/messagestatus`;
  const { data } = await base.post({
    path,
    data: message,
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportAddGeneralMessageToSubmission = async (
  projectId: string,
  submissionId: string,
  isCcBriefSubmission: boolean,
  shareProjectDetailsWithCcArtist: boolean,
  message: string
): Promise<CreateProjectResponse> => {
  const path = isCcBriefSubmission
    ? `/communitycastingbrief/submission/${submissionId}/followup/message`
    : `/roles/cdstatusreports/${projectId}/${submissionId}/generalmessage`;

  const request = isCcBriefSubmission
    ? {
        message: message,
        shareProjectAndRoleDetails: shareProjectDetailsWithCcArtist,
      }
    : { message: message };
  const { data } = await base.post({
    path,
    data: request,
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportUpdateInternalNote = async (
  projectId: string,
  submissionId: string,
  note: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/castingnote/${submissionId}`;
  const { data } = await base.post({
    path,

    data: note,
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportGetProfileSharedChannels = async (
  projectId: string,
  submissionId: string
): Promise<ProfileSharedChannel[]> => {
  const path = `/roles/cdstatusreports/${projectId}/managestatus/submission/${submissionId}/sharedChannels`;
  const { data } = await base.get({
    path,
    variant: "roles",
  });
  return data?.result ?? [];
};

export const cdStatusReportAddProfileComment = async (
  projectId: string,
  submissionId: string,
  comment: CommentItem
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/submission/${submissionId}/comment`;
  const { data } = await base.post({
    path,

    data: {
      authorName: comment.authorName,
      message: comment.message,
    },
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportDeleteProfileComment = async (
  commentId: string
): Promise<string> => {
  const path = `/roles/cdstatusreports/comment/${commentId}/delete`;
  const { data } = await base.deleteFile({
    path,
    variant: "roles",
  });
  return data?.success ? data.result : null ?? null;
};

export const cdStatusReportAddProfileCommentForExternalUser = async (
  projectId: string,
  channelId: string,
  folderId: string,
  submissionId: string,
  comment: CommentItem
): Promise<CreateProjectResponse> => {
  const path = `/external/channel/${channelId}/project/${projectId}/folder/${folderId}/submission/${submissionId}/comment`;
  const { data } = await base.postAnonymous({
    path,
    data: {
      authorName: comment.authorName,
      message: comment.message,
    },
    variant: "external",
  });
  return toResponse(data);
};

export const cdStatusReportUpdateRoleStatuses = async (
  projectId: string,
  requestType: RoleStatusRequestType,
  submissionIds: string[],
  message: string | null,
  documentIds: string[],
  releaseDelayHours: number | null,
  informCcArtist: boolean,
  ccArtistMessage: string | null
): Promise<RoleStatusUpdateResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/rolestatus`;
  const { data } = await base.post({
    path,

    data: {
      requestType,
      submissionIds,
      message,
      documentIds,
      releaseDelayHours,
      informCcArtist,
      ccArtistMessage,
    },
    variant: "roles",
  });
  return data?.result ?? [];
};

export const cdStatusReportUploadManualArtistHeadshot = async (
  projectId: string,
  artistId: string,
  file: File,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<UploadedFile> => {
  const path = `/roles/cdstatusreports/${projectId}/upload/headshot/${artistId}`;
  const { data } = await base.uploadFile({
    path,
    file,
    onProgress,
    cancelSource,
    variant: "roles",
  });
  return toUploadedRoleStatusDoc(data);
};

export const cdStatusReportUploadManualTape = async (
  projectId: string,
  timeSlotId: string,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<UploadVideoResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/${timeSlotId}/upload`;
  // Because triggering the cancellation token returns a null response,
  // we cant automatically unpack the data node
  const response = await base.uploadFile({
    path,

    file,
    onProgress,
    cancelSource,
    variant: "roles",
  });
  return toUploadVideoResponse(response?.data);
};

export const cdStatusReportUploadCcBriefSubmissionTape = async (
  projectId: string,
  timeSlotId: string,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<UploadVideoResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/${timeSlotId}/ccbrief/submission/uploadclip`;
  // Because triggering the cancellation token returns a null response,
  // we cant automatically unpack the data node
  const response = await base.uploadFile({
    path,

    file,
    onProgress,
    cancelSource,
    variant: "roles",
  });
  return toUploadVideoResponse(response?.data);
};

export const cdStatusReportReserveManualTapeId = async (
  projectId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/manualtape/reserve`;
  const { data } = await base.post({
    path,
    data: null,
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportGetManualTapeCollection = async (
  projectId: string,
  tapeId: string
): Promise<ManualTapeCollectionDetails> => {
  const path = `/roles/cdstatusreports/${projectId}/${tapeId}/manualtape`;
  const { data } = await base.get({
    path,
    variant: "roles",
  });
  return toManualTapeCollectionDetails(data);
};

export const cdStatusReportGetManualArtistDetails = async (
  projectId: string,
  tapeId: string,
  artistId: string
): Promise<ManualArtistEditResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/${tapeId}/manual-artist/${artistId}`;
  const { data } = await base.get({
    path,
    variant: "roles",
  });
  return toManualArtistEditResponse(data);
};

export const cdStatusReportGetArtistsForManualTape = async (
  projectId: string
): Promise<ProjectArtist[]> => {
  const path = `/roles/cdstatusreports/${projectId}/artists`;
  const { data } = await base.get({
    path,
    variant: "roles",
  });
  return data?.result ?? [];
};

export const cdStatusReportSaveManualArtistFromEdit = async (
  projectId: string,
  tapeId: string,
  artistId: string,
  artist: any
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/${tapeId}/manual-artist/${artistId}`;
  const { data } = await base.post({
    path,
    data: artist,
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportSaveManualTapeCollectionWithExistingArtist = async (
  projectId: string,
  tapeId: string,
  manualTape: any
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/${tapeId}/manualtape/existingartists`;
  const { data } = await base.post({
    path,
    data: manualTape,
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportSaveManualTapeCollectionWithBatchArtists = async (
  projectId: string,
  tapeId: string,
  batchTapeCollection: any
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/${tapeId}/batchtape`;
  const { data } = await base.post({
    path,
    data: batchTapeCollection,
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportSaveManualTapeCollection = async (
  projectId: string,
  tapeId: string,
  manualTape: any
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/${tapeId}/manualtape`;
  const { data } = await base.post({
    path,
    data: manualTape,
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportUploadRoleStatusDoc = async (
  projectId: string,
  file: File,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<UploadedFile> => {
  const path = `/roles/cdstatusreports/${projectId}/uploadrolestatusdoc`;
  const { data } = await base.uploadFile({
    path,

    file,
    onProgress,
    cancelSource,
    variant: "roles",
  });
  return toUploadedRoleStatusDoc(data);
};

export const updateCdSetTapesAsViewed = async (
  projectId: string,
  tapeId: string,
  isViewed: boolean
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/tapes/${tapeId}/updatecdsettapesasviewed`;
  const { data } = await base.post({
    path,
    data: {
      isViewed,
    },
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportSetTapeThumbnail = async (
  projectId: string,
  tapeId: string,
  clipId: string,
  timestamp: number
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/tapes/${tapeId}`;
  const { data } = await base.post({
    path,

    data: {
      clipId,
      timestamp,
    },
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportRenameClip = async (
  projectId: string,
  tapeId: string,
  clipId: string,
  newName: string
): Promise<CreateProjectResponse> => {
  
  const path = `/roles/cdstatusreports/${projectId}/clips/rename/${tapeId}/${clipId}`;
  var request ={
    newName : newName
  }
  const { data } = await base.post({
    path,

    data: request,
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportDownloadClip = async (
  projectId: string,
  tapeId: string,
  clipId: string
): Promise<ClipDownloadDetails> => {
  const path = `/roles/cdstatusreports/${projectId}/clips/download/${tapeId}/${clipId}`;
  const { data } = await base.get({
    path,

    variant: "roles",
  });
  return data?.result ?? { clipId: "", downloadUrl: "" };
};

export const ccFollowUpDownloadClip = async (
  ccBriefSubmissionId: string,
  clipId: string
): Promise<ClipDownloadDetails> => {
  const path = `/communitycastingbrief/submission/${ccBriefSubmissionId}/${clipId}`;
  const { data } = await base.get({
    path,

    variant: "external",
  });
  return data?.result ?? { clipId: "", downloadUrl: "" };
};

export const cdStatusReportDownloadClipExternalAccess = async (
  projectId: string,
  tapeId: string,
  clipId: string
): Promise<DownloadMaterialResponse> => {
  const path = `/external/channel/${projectId}/clips/download/${tapeId}/${clipId}`;
  const { data } = await base.getAnonymous({
    path,

    variant: "external",
  });
  return toDownloadMaterialResponse(data);
};

export const cdStatusReportOrderClips = async (
  projectId: string,
  tapeId: string,
  orderedClips: TapeClipReorder[]
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/clips/order/${tapeId}`;
  const { data } = await base.post({
    path,

    data: orderedClips,
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportRequestClipTrim = async (
  projectId: string,
  tapeId: string,
  clipId: string,
  startTime: number,
  endTime: number
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/${tapeId}/clip/${clipId}/trim`;
  const { data } = await base.post({
    path,
    data: {
      startTime: startTime,
      endTime: endTime,
    },
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportRequestClipsDownload = async (
  projectId: string,
  tapeId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/clips/downloadall/${tapeId}`;
  const { data } = await base.post({
    path,

    data: null,
    variant: "roles",
  });
  return toResponse(data);
};

export const channelTapeClipsDownload = async (
  projectId: string,
  tapeId: string,
  firstName: string,
  email: string
): Promise<CreateProjectResponse> => {
  const path = `/external/channel/${projectId}/clips/downloadall/${tapeId}`;
  const { data } = await base.postAnonymous({
    path,
    data: { firstName: firstName, email: email },
    variant: "external",
  });
  return toResponse(data);
};

export const channelFolderClipsDownload = async (
  channelId: string,
  folderId: string,
  firstName: string,
  email: string
): Promise<CreateProjectResponse> => {
  const path = `/external/channel/${channelId}/clips/downloadfolder/${folderId}`;
  const { data } = await base.postAnonymous({
    path,
    data: { firstName: firstName, email: email },
    variant: "external",
  });
  return toResponse(data);
};
export const cdStatusReportDownloadDoc = async (
  projectId: string,
  fileId: string,
  isCcBriefSubmissionTape: boolean
): Promise<DownloadedFilePathResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/docs/download/${fileId}?isCcBriefSubmissionFile=${isCcBriefSubmissionTape}`;
  const { data } = await base.get({ path, variant: "roles" });
  return toDownloadedFilePathResponse(data);
};

export const cdStatusReportDownloadDocExternalAccess = async (
  projectId: string,
  fileId: string
): Promise<DownloadedFilePathResponse> => {
  const path = `/external/channel/${projectId}/docs/download/${fileId}`;
  const { data } = await base.getAnonymous({ path, variant: "external" });
  return toDownloadedFilePathResponse(data);
};

export const cdStatusReportApproveDoc = async (
  projectId: string,
  fileId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/docs/approve/${fileId}`;
  const { data } = await base.post({
    path,

    data: null,
    variant: "roles",
  });
  return toResponse(data);
};
export const getWardrobeQuestionsforFolder = async (
  folderId: string,
  projectId: string
): Promise<string[]> => {
  const path = `/roles/cdstatusreports/${projectId}/folder/${folderId}/wardrobeQuestions`;
  const { data } = await base.get({ path, variant: "roles" });
  return data.result;
};

export const getExportRunningListData = async (
  folderId: string,
  projectId: string,
  runningListFilter: RunningListFilter
): Promise<RunningListPdfData> => {
  const checkedQuestions = runningListFilter.questions
    .filter((question) => question.checked)
    .map((question) => question.label);

  // query = query.slice(0, -1);
  var filter = {
    Headshot: runningListFilter.headshot.checked,
    CulturalBackground: runningListFilter.culturalBackground.checked,
    Location: runningListFilter.location.checked,
    Height: runningListFilter.height.checked,
    Gender: runningListFilter.gender.checked,
    Agent: runningListFilter.agent.checked,
    ProNouns: runningListFilter.pronouns.checked,
    IncludeInternalComments: runningListFilter.includeInternalComments.checked,
    IncludeClientandCastingComments:
      runningListFilter.includeClientandCastingComments.checked,
    Questions: checkedQuestions,
  };
  const path = `/roles/cdstatusreports/${projectId}/folder/${folderId}/runningList`;

  const { data } = await base.post({ path, data: filter, variant: "roles" });
  return parseRunningListPdfData(data.result);
};

export const cdStatusReportRejectDoc = async (
  projectId: string,
  fileId: string,
  message: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/docs/reject/${fileId}`;
  const { data } = await base.post({
    path,

    data: {
      message: message,
    },
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportApproveAllDocs = async (
  projectId: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/docs/approveall`;
  const { data } = await base.post({
    path,

    data: null,
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportCreateMediaFolder = async (
  projectId: string,
  folderName: string
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/addMediaFolder`;
  const { data } = await base.post({
    path,
    data: folderName,
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportAddTapesToMediaFolder = async (
  projectId: string,
  folderId: string,
  tapes: AddTapesToMediaFolderRequest[]
): Promise<CreateProjectResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/addTapesToMediaFolder/${folderId}`;
  const { data } = await base.post({
    path,
    data: tapes,
    variant: "roles",
  });
  return toResponse(data);
};

export const cdStatusReportDeleteMediaFolder = async (
  projectId: string,
  folderId: string
): Promise<DeleteListResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/deleteMediaFolder/${folderId}`;

  const { data } = await base.deleteFile({
    path,
    variant: "roles",
  });

  return toApiResponse(data);
};

export const cdStatusReportDeleteTapeFromMediaFolder = async (
  projectId: string,
  folderId: string,
  tapeId: string
): Promise<DeleteListResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/mediaFolder/${folderId}/tape/${tapeId}/delete`;

  const { data } = await base.deleteFile({
    path,
    variant: "roles",
  });

  return toApiResponse(data);
};

export const cdStatusReportEditMediaFolderName = async (
  projectId: string,
  folderId: string,
  newFolderName: string
): Promise<ApiResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/mediaFolder/${folderId}/editName`;

  const { data } = await base.put({
    path,
    data: newFolderName,
    variant: "roles",
  });
  return toApiResponse(data);
};

export const cdStatusReportDownloadSelectedSubmissionForms = async (
  projectId: string,
  selectedProfiles: ProfileSelectedForUpdateRoleStatus[]
): Promise<ApiResponse> => {
  const path = `/roles/cdstatusreports/${projectId}/submission/forms/download`;

  const { data } = await base.post({
    path,
    data: selectedProfiles.map((x) => {
      return {
        submissionId: x.id,
        roleId: x.roleId,
        artistName: x.name,
        isCcBriefSubmission: x.isCcBriefArtist,
        isManualArtist: x.isManualArtist,
      };
    }),
    variant: "roles",
  });
  return toApiResponse(data);
};
/********************************************** */
// Message Agent
/********************************************** */

export const sendEmailMessageToProjectAgent = async (
  projectId: string,
  requestData: MessageAgents
): Promise<ApiResponse> => {
  const path = `/projects/${projectId}/email/agents`;
  const { data } = await base.post({
    path,

    data: requestData,
    variant: "projects",
  });
  return toApiResponse(data);
};

export const sendEmailMessageToProjectRoleAgent = async (
  projectId: string,
  roleId: string,
  requestData: MessageAgents
): Promise<ApiResponse> => {
  const path = `/projects/${projectId}/role/${roleId}/email/agents`;
  const { data } = await base.post({
    path,

    data: requestData,
    variant: "projects",
  });
  return toApiResponse(data);
};

/********************************************** */
// Stripe Subscription Management
/********************************************** */

export const manageSubscription = async (): Promise<ApiResponse> => {
  const path = `/users/account/manage-payments`;
  const { data } = await base.post({
    path,

    data: {},
    variant: "users",
  });

  return toApiResponse(data);
};

export const createSubscription = async (
  priceId: string | null,
  userType?: UserType,
  userId?: string
): Promise<ApiResponse> => {
  let path = `/users/account/create-checkout-session?priceId=${
    priceId === null ? "" : priceId
  }`;
  if (userType && userType !== null && userId && userId !== null) {
    path = path + `&userType=${userType}&userId=${userId}`;
  }
  const { data } = await base.post({
    path,
    data: {},
    variant: "users",
  });

  return toApiResponse(data);
};

export const confirmStayOnCurrentPaymentTier = async (): Promise<boolean> => {
  let path = `/users/account/confirmstayonlitetier`;

  const { data } = await base.patch({
    path,
    data: {},
    variant: "users",
  });

  return data?.result ?? false;
};

export const getPricingTableConfig =
  async (): Promise<PricingTableConfig | null> => {
    const path = `/users/account/pricingtable`;
    const { data } = await base.get({ path, variant: "users" });
    return data?.result ?? null;
  };

/********************************************** */
// Calendar
/********************************************** */

export const getCastingOfficeCalendar = async (
  startFrom: string | null
): Promise<CastingOfficeCalendar[]> => {
  let path = `/roles/sessions/casting-office/calendar`;
  if (startFrom !== null) {
    path = `${path}?startFrom=${startFrom}`;
  }
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result.map(toCastingOfficeCalendar) ?? [];
};

export const getAgencyCalendar = async (
  startFrom: string | null
): Promise<AgencyCalendar[]> => {
  let path = `/roles/sessions/agency/calendar`;
  if (startFrom !== null) {
    path = `${path}?startFrom=${startFrom}`;
  }
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result.map(toAgencyCalendar) ?? [];
};

export const getArtistCalendar = async (
  startFrom: string | null
): Promise<ArtistCalendar[]> => {
  let path = `/roles/sessions/artist/calendar`;
  if (startFrom !== null) {
    path = `${path}?startFrom=${startFrom}`;
  }
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result.map(toArtistCalendar) ?? [];
};

/********************************************** */
// Project Channels
/********************************************** */

export const createProjectChannel = async (
  newChannel: ChannelRequest
): Promise<ApiResponse> => {
  const path = `/channel/create`;
  const { data } = await base.post({
    path,

    data: newChannel,
    variant: "projects",
  });

  return toApiResponse(data);
};

export const createChannelTapeFolder = async (
  newFolder: ChannelTapeFolderRequest
): Promise<ApiResponse> => {
  const path = `/channel/create/tape-folder`;
  const { data } = await base.post({
    path,

    data: newFolder,
    variant: "projects",
  });

  return toApiResponse(data);
};

export const addTapesToFolder = async (
  folderId: string,
  tapeData: AddTapesToFolderRequest[]
): Promise<ApiResponse> => {
  const path = `/channel/tape-folder/${folderId}/add-tapes`;
  const { data } = await base.post({
    path,

    data: tapeData,
    variant: "projects",
  });

  return toApiResponse(data);
};

export const addTapesToNewFolder = async (
  projectChannelId: string,
  folderName: string,
  tapeData: AddTapesToFolderRequest[]
): Promise<ApiResponse> => {
  const path = `/channel/tape-folder/add-tapes-new-folder`;
  const { data } = await base.post({
    path,
    data: {
      projectChannelId,
      folderName,
      tapes: tapeData,
    },
    variant: "projects",
  });

  return toApiResponse(data);
};

export const addTapesToNewChannel = async (
  newChannel: ChannelRequest
): Promise<ApiResponse> => {
  const path = `/channel/tape-folder/add-tapes-new-channel`;
  const { data } = await base.post({
    path,
    data: newChannel,
    variant: "projects",
  });

  return toApiResponse(data);
};

export const updateProjectChannel = async (
  channelId: string,
  channelData: ChannelRequest
): Promise<ApiResponse> => {
  const path = `/channel/${channelId}/update`;
  const { data } = await base.put({
    path,

    data: channelData,
    variant: "projects",
  });

  return toApiResponse(data);
};

export const addArtistListToChannel = async (
  channelId: string,
  artistListId: string
): Promise<ApiResponse> => {
  const path = `/channel/${channelId}/assign-artist-list/${artistListId}`;
  const { data } = await base.put({
    path,

    data: {},
    variant: "projects",
  });

  return toApiResponse(data);
};

export const updateProjectChannelTapeFolder = async (
  folderId: string,
  request: ChannelTapeFolderRequest
): Promise<ApiResponse> => {
  const path = `/channel/tape-folder/${folderId}/update`;
  const { data } = await base.put({
    path,

    data: request,
    variant: "projects",
  });

  return toApiResponse(data);
};

export const reOrderChannelArtistList = async (
  channelId: string,
  channelArtistListId: string,
  position: number
): Promise<boolean> => {
  const path = `/channel/${channelId}/reorder-artist-list/${channelArtistListId}/position/${position}`;
  const { data } = await base.patch({
    path,

    data: {},
    variant: "projects",
  });

  return data?.result ?? false;
};

export const reOrderChannelTapeFolder = async (
  channelId: string,
  folderId: string,
  position: number
): Promise<boolean> => {
  const path = `/channel/${channelId}/reorder-tape-folder/${folderId}/position/${position}`;
  const { data } = await base.patch({
    path,

    data: {},
    variant: "projects",
  });

  return data?.result ?? false;
};

export const hideArtistInChannel = async (
  channelId: string,
  sessionId: string,
  artistHideInfo: HideArtistInChannelRequest[]
): Promise<boolean> => {
  const path = `/channel/${channelId}/tape-session/${sessionId}/hide-artists`;
  const { data } = await base.patch({
    path,
    data: artistHideInfo,
    variant: "projects",
  });

  return data?.result ?? false;
};

export const reOrderChannelTape = async (
  channelId: string,
  folderId: string,
  tapeId: string,
  position: number
): Promise<boolean> => {
  const path = `/channel/${channelId}/tape-folder/${folderId}/reorder-tape/${tapeId}/position/${position}`;
  const { data } = await base.patch({
    path,

    data: {},
    variant: "projects",
  });

  return data?.result ?? false;
};

export const removeProjectChannel = async (
  channelId: string
): Promise<ApiResponse> => {
  let path = `/channel/${channelId}/remove`;

  const { data } = await base.deleteFile({
    path,

    variant: "projects",
  });
  return toApiResponse(data);
};
export const updatePasswordChannel = async (
  channelId: string,
  password: string
): Promise<ApiResponse> => {
  let path = `/channel/${channelId}/managePassword/${password}`;

  const { data } = await base.patch({
    path,

    variant: "projects",
  });
  return toApiResponse(data);
};

export const setCommentChannelVisibity = async (
  channelId: string,
  showComment: boolean
): Promise<ApiResponse> => {
  let path = `/channel/${channelId}/setChannelCommentVisibilty/${showComment}`;

  const { data } = await base.patch({
    path,

    variant: "projects",
  });
  return toApiResponse(data);
};

export const updateExpirydateChannel = async (
  channelId: string,
  date: string | null
): Promise<ApiResponse> => {
  let queryStr = "";
  if (date !== null) {
    queryStr = `expirydate=${date}`;
  }
  let path = `/channel/${channelId}/expirydate?${queryStr}`;

  const { data } = await base.patch({
    path,
    variant: "projects",
  });
  return toApiResponse(data);
};

export const removeArtistListFromProjectChannel = async (
  channelArtistListId: string
): Promise<boolean> => {
  let path = `/channel/artist-list/${channelArtistListId}/remove`;

  const { data } = await base.deleteFile({
    path,

    variant: "projects",
  });
  return data?.result ?? false;
};

export const removeChannelTapeFolder = async (
  folderId: string
): Promise<boolean> => {
  let path = `/channel/tape-folder/${folderId}/remove`;

  const { data } = await base.deleteFile({
    path,

    variant: "projects",
  });
  return data?.result ?? false;
};

export const removeChannelTape = async (
  folderId: string,
  tapeId: string
): Promise<boolean> => {
  let path = `/channel/tape-folder/${folderId}/tape/${tapeId}/remove`;

  const { data } = await base.deleteFile({
    path,

    variant: "projects",
  });
  return data?.result ?? false;
};

export const getProjectChannel = async (
  channelId: string
): Promise<ChannelInfo> => {
  let path = `/channel/${channelId}`;

  const { data } = await base.get({ path, variant: "projects" });
  return toChannelInfo(data?.result);
};

export const getProjectChannelList = async (
  projectId: string
): Promise<ChannelInfo[]> => {
  let path = `/channel/project/${projectId}/list`;

  const { data } = await base.get({ path, variant: "projects" });
  return data?.result.map(toChannelInfo) ?? [];
};

export const getProjectChannelBasicInfoList = async (
  projectId: string
): Promise<ChannelBasicInfo[]> => {
  let path = `/channel/project/${projectId}`;

  const { data } = await base.get({ path, variant: "projects" });
  return data?.result ?? [];
};

export const copyProjectChannelFolder = async (
  folderId: string,
  destinationChannelId: string
): Promise<ApiResponse> => {
  let path = `/channel/tape-folder/${folderId}/copyTo/${destinationChannelId}`;

  const { data } = await base.put({ path, variant: "projects" });
  return toApiResponse(data);
};

export const getProjectBasicDetails = async (
  projectId: string
): Promise<ProjectBasicDetails> => {
  let path = `/projects/${projectId}/basic-details`;

  const { data } = await base.get({ path, variant: "projects" });
  return toProjectBasicDetails(data?.result);
};

export const getProjectChannelArtistLists = async (
  channelId: string
): Promise<ChannelArtistList[]> => {
  let path = `/channel/${channelId}/artist-list`;

  const { data } = await base.get({ path, variant: "projects" });
  return data?.result.map(toChannelArtistList) ?? [];
};

export const getSessionListForProjectChannel = async (
  channelId: string
): Promise<ChannelSessionInfo[]> => {
  let path = `/channel/${channelId}/session-list`;

  const { data } = await base.get({ path, variant: "projects" });
  return data?.result.map(toChannelSessionInfo) ?? [];
};

export const getSessionListForProject = async (
  projectId: string
): Promise<ChannelSessionInfo[]> => {
  let path = `/channel/project/${projectId}/session-list`;

  const { data } = await base.get({ path, variant: "projects" });
  return data?.result.map(toChannelSessionInfo) ?? [];
};

export const getChannelFolderInfos = async (
  channelId: string
): Promise<ChannelFolderInfo[]> => {
  let path = `/channel/${channelId}/tape-folders`;

  const { data } = await base.getAnonymous({ path, variant: "external" });
  return data?.result ?? [];
};

export const getChannelFolderDetails = async (
  channelId: string
): Promise<ChannelFolderDetail[]> => {
  let path = `/channel/${channelId}/tape-folders/details`;

  const { data } = await base.get({ path, variant: "projects" });
  return data?.result ?? [];
};

export const getChannelFolderDetailsForExternalUsers = async (
  channelId: string
): Promise<ChannelFolderDetail[]> => {
  let path = `/external/channel/${channelId}/tape-folders/details`;

  const { data } = await base.getAnonymous({ path, variant: "external" });
  return data?.result ?? [];
};

export const getChannelFolderListV2 = async (
  channelId: string
): Promise<ChannelFolderDetail[]> => {
  let path = `/channel/${channelId}/tape-folders/v2/list`;

  const { data } = await base.get({ path, variant: "projects" });
  return data?.result ?? [];
};

export const getChannelFolderListV2ForExternalUsers = async (
  channelId: string
): Promise<ChannelFolderDetail[]> => {
  let path = `/external/channel/${channelId}/tape-folders/v2/list`;

  const { data } = await base.get({ path, variant: "external" });
  return data?.result ?? [];
};

export const getChannelFolderDetail = async (
  channelId: string,
  folderId: string
): Promise<ChannelFolderDetail | null> => {
  let path = `/channel/${channelId}/tape-folder/${folderId}/detail`;

  const { data } = await base.get({ path, variant: "projects" });
  return data?.result ?? null;
};

export const getChannelFolderDetailForExternalUsers = async (
  channelId: string,
  folderId: string
): Promise<ChannelFolderDetail | null> => {
  let path = `/external/channel/${channelId}/tape-folder/${folderId}/detail`;

  const { data } = await base.get({ path, variant: "external" });
  return data?.result ?? null;
};

export const addSessionToChannel = async (
  channelId: string,
  sessionId: string
): Promise<ApiResponse> => {
  const path = `/channel/${channelId}/assign-session/${sessionId}`;
  const { data } = await base.put({
    path,

    data: {},
    variant: "projects",
  });

  return toApiResponse(data);
};

export const removeSessionFromProjectChannel = async (
  channelSessionId: string
): Promise<boolean> => {
  let path = `/channel/session/${channelSessionId}/remove`;

  const { data } = await base.deleteFile({
    path,

    variant: "projects",
  });
  return data?.result ?? false;
};

export const getProjectChannelForExternalAccess = async (
  channelId: string
): Promise<ChannelInfo> => {
  let path = `/external/channel/${channelId}`;

  const { data } = await base.getAnonymous({ path, variant: "external" });
  return toChannelInfo(data?.result);
};

export const authenticateProjectChannel = async (
  channelId: string,
  password: string
): Promise<boolean> => {
  let path = `/external/channel/${channelId}/authenticate/${password}`;

  const { data } = await base.getAnonymous({ path, variant: "external" });
  return data?.result;
};

export const checkAuthEnabled = async (channelId: string): Promise<boolean> => {
  let path = `/external/channel/${channelId}/checkAuthEnabled`;

  const { data } = await base.getAnonymous({ path, variant: "external" });
  return data?.result;
};

export const isExpiredChannel = async (channelId: string): Promise<boolean> => {
  let path = `/external/channel/${channelId}/isChannelExpired`;

  const { data } = await base.getAnonymous({ path, variant: "external" });
  return data?.result;
};

export const getProjectChannelArtistListsForExternalAccess = async (
  channelId: string
): Promise<ChannelArtistList[]> => {
  let path = `/external/channel/${channelId}/artist-list`;

  const { data } = await base.getAnonymous({ path, variant: "external" });
  return data?.result.map(toChannelArtistList) ?? [];
};

export const getSessionListForProjectChannelForExternalAccess = async (
  channelId: string
): Promise<ChannelSessionInfo[]> => {
  let path = `/external/channel/${channelId}/session-list`;

  const { data } = await base.getAnonymous({ path, variant: "external" });
  return data?.result.map(toChannelSessionInfo) ?? [];
};

/********************************************** */
// Home Page
/********************************************** */

export const getAgencyUserHomePageDetails = async (
  timeFrom: string | null,
  timeTo: string | null
): Promise<AgencyUserHomePageDetails> => {
  let path = `/users/account/agency/homepage-details`;
  if (timeFrom !== null) {
    path = `${path}?timeFrom=${timeFrom}${
      timeTo !== null ? `&timeTo=${timeTo}` : ""
    }`;
  }
  const { data } = await base.get({ path, variant: "users" });
  return toAgencyUserHomePageDetails(data?.result);
};

export const getArtistHomePageDetails = async (
  timeFrom: string | null,
  timeTo: string | null
): Promise<ArtistHomePageDetails> => {
  let path = `/users/account/artist/homepage-details`;
  if (timeFrom !== null) {
    path = `${path}?timeFrom=${timeFrom}${
      timeTo !== null ? `&timeTo=${timeTo}` : ""
    }`;
  }
  const { data } = await base.get({ path, variant: "users" });
  return toArtistHomePageDetails(data?.result);
};

export const getCdHomePageDetails = async (
  timeFrom: string | null,
  timeTo: string | null
): Promise<CdHomePageDetails> => {
  let path = `/users/account/cd/homepage-details`;
  if (timeFrom !== null) {
    path = `${path}?timeFrom=${timeFrom}${
      timeTo !== null ? `&timeTo=${timeTo}` : ""
    }`;
  }
  const { data } = await base.get({ path, variant: "users" });
  return toCdHomePageDetails(data?.result);
};

/********************************************** */
// Zendesk
/********************************************** */

export const getJwtLogin = async (): Promise<string> => {
  const path = `/users/account/jwtlogin`;
  const { data } = await base.get({ path, variant: "users" });
  return data?.result;
};

/********************************************** */
// Custom Token
/********************************************** */

export const getCustomLogin = async (
  tenantId: string,
  userType: UserType
): Promise<string> => {
  const path = `/users/account/custom-token/tenant/${tenantId}/user-type/${userType}`;
  const { data } = await base.get({ path, variant: "users" });
  return data?.result ?? "";
};

/********************************************** */
// Registration Summery
/********************************************** */

export const getRegistrationSummery = async (): Promise<
  RegistrationDataTableItem[]
> => {
  const path = `/users/account/registrationsummery`;
  const { data } = await base.get({ path, variant: "users" });
  return data?.result ?? [];
};

export const getArtistRegistraionDetails = async (
  filter: string,
  page: Number,
  pageSize: Number
): Promise<DirectoryArtistGridDetails> => {
  const path = `/directory/artists/registration-details?filter=${filter}&page=${page}&pageSize=${pageSize}`;
  const { data } = await base.get({
    path,

    variant: "directory",
  });
  const result: DirectoryArtistGridDetails = {
    totalRowCount: data?.result?.totalRowCount,
    pageSize: data?.result?.pageSize,
    numberOfPages: data?.result?.numberOfPages,
    currentPageNumber: data?.result?.currentPageNumber,
    data: toDirectoryArtistProfiles(data?.result?.data),
  };
  return result;
};

export const getArtistRegistraionDetailsV2 = async (
  filters: ArtistFilters | null,
  filter: string,
  page: Number,
  pageSize: Number
): Promise<DirectoryArtistGridDetails> => {
  let query: string = "";
  query = filters?.artistName ? `artistName=${filters.artistName}&` : "";
  query += filters?.agencyName ? `agencyName=${filters.agencyName}&` : "";
  query += filters?.minAge ? `minAge=${filters.minAge}&` : "";
  query += filters?.maxAge ? `maxAge=${filters.maxAge}&` : "";
  query += filters?.minHeight ? `minHeight=${filters.minHeight}&` : "";
  query += filters?.maxHeight ? `maxHeight=${filters.maxHeight}&` : "";
  query += filters?.baseLocation ? `locations=${filters.baseLocation}&` : "";
  query += filters?.skills ? `skills=${filters.skills}&` : "";
  query += filters?.gender ? `gender=${filters.gender}&` : "";
  query += filters?.representation
    ? `representation=${filters.representation}&`
    : "";
  query += filters?.nationalities
    ? `nationalities=${filters.nationalities}&`
    : "";
  query += filters?.languages ? `languages=${filters.languages}&` : "";
  query += filters?.disabilities ? `disabilities=${filters.disabilities}&` : "";
  query += filters?.accents ? `accents=${filters.accents}&` : "";
  query += filters?.eyeColours ? `eyeColours=${filters.eyeColours}&` : "";
  query += filters?.hairColours ? `hairColours=${filters.hairColours}&` : "";
  query += filters?.culturalBackgrounds
    ? `culturalBackgrounds=${filters.culturalBackgrounds}&`
    : "";

  const path = `/directory/artists/registration-details-v2?${query}filter=${filter}&page=${page}&pageSize=${pageSize}`;
  const { data } = await base.get({
    path,
    variant: "directory",
  });
  const result: DirectoryArtistGridDetails = {
    totalRowCount: data?.result?.totalRowCount,
    pageSize: data?.result?.pageSize,
    numberOfPages: data?.result?.numberOfPages,
    currentPageNumber: data?.result?.currentPageNumber,
    data: toDirectoryArtistProfiles(data?.result?.data),
  };
  return result;
};

export const getAgencyRegistraionDetails = async (
  filter: string
): Promise<AgencyBasicDetails[]> => {
  const path = `/directory/agency/registration-details?filter=${filter}`;
  const { data } = await base.get({
    path,

    variant: "directory",
  });

  return toDirectoryAgencyBasicProfiles(data.result);
};

export const getAgencyListForDropdown = async (): Promise<
  AgencyForDropdown[]
> => {
  const path = `/directory/allagencies/fordropdown`;
  const { data } = await base.get({
    path,

    variant: "directory",
  });

  return data?.result ?? [];
};

export const addUnrepresentedArtistToAgencyByAdmin = async (
  artistProfileId: string,
  agencyTenantId: string
): Promise<CreateProjectResponse> => {
  const path = `/users/invitations/altaiadmin/add-artist-to-agency`;
  const { data } = await base.post({
    path,
    data: {
      artistProfileId: artistProfileId,
      agencyTenantId: agencyTenantId,
    },
    variant: "users",
  });

  return toResponse(data);
};

export const validateArtistForDeletionByAdmin = async (
  artistProfileId: string
): Promise<boolean> => {
  const path = `/users/account/artist/${artistProfileId}/validate-for-deletion`;
  const { data } = await base.get({
    path,
    variant: "users",
  });

  return data?.result ?? false;
};

export const deleteArtistByAdmin = async (
  artistProfileId: string
): Promise<boolean> => {
  const path = `/users/account/artist/${artistProfileId}`;
  const { data } = await base.deleteFile({
    path,
    variant: "users",
  });

  return data?.result ?? false;
};

export const getCastingOfficesForRegistrationDetails = async (
  filter: string
): Promise<CastingOfficeBasicInfo[]> => {
  const path = `/directory/casting/registration-details?filter=${filter}`;
  const { data } = await base.get({
    path,

    variant: "directory",
  });

  return toDirectoryCastingOfficeBasicInfos(data.result);
};

export const emailEveryone = async (
  request: EmailEveryoneRequest
): Promise<boolean> => {
  const path = `/comms/email-everyone`;
  const { data } = await base.post({
    path,
    data: {
      recepiantUserTypes: request.recepiantUserTypes.map((x) => x.value),
      subject: request.subject,
      message: request.message,
    },
    variant: "users",
  });

  return data?.result ?? false;
};

export const bulkMessageAgencies = async (
  request: MessageAgencyRequest
): Promise<boolean> => {
  const path = `/directory/message/agency`;
  const { data } = await base.post({
    path,
    data: request,
    variant: "directory",
  });

  return data?.result ?? false;
};


export const getwardrobeRoleDetails = async (
  channelId: string,
  channelWardrobeFilters: ChannelWardrobeFilters | null
): Promise<WardrobeRoleDetail[]> => {
  const path = `/channel/${channelId}/roledetails?roleName=${
    channelWardrobeFilters?.roles ?? ""
  }&roleStatus=${channelWardrobeFilters?.roleStatus ?? ""}&auditionStatus=${
    channelWardrobeFilters?.auditionStatus ?? ""
  }&artistName=${channelWardrobeFilters?.artistName ?? ""}`;
  const { data } = await base.get({
    path,
    variant: "projects",
  });

  return data?.result ?? [];
};

export const getwardrobeSubmissionDetails = async (
  channelId: string,
  roleId: string,
  channelWardrobeFilters: ChannelWardrobeFilters | null
): Promise<WardrobeSubmissionDetail[]> => {
  const path = `/channel/${channelId}/role/${roleId}/submissionWrDetails?roleStatus=${
    channelWardrobeFilters?.roleStatus ?? ""
  }&auditionStatus=${channelWardrobeFilters?.auditionStatus ?? ""}&artistName=${
    channelWardrobeFilters?.artistName ?? ""
  }`;
  const { data } = await base.get({
    path,
    variant: "projects",
  });

  return data?.result ?? [];
};

export const getWardrobeFormFilterOptions = async (
  channelId: string
): Promise<WardrobeFormFilterOptions> => {
  const path = `/channel/${channelId}/wardrobe-filter-options`;
  const { data } = await base.get({
    path,
    variant: "projects",
  });

  return data?.result ?? { roles: [], auditionStatus: [], roleStatus: [] };
};

export const shareAllWardrobeForms = async (
  channelId: string,
  channelWardrobeFilters: ChannelWardrobeFilters | null
): Promise<WardrobeBulkActionResult[]> => {
  const path = `/channel/${channelId}/wardrobeforms/share-all`;
  const { data } = await base.patch({
    path,
    data: channelWardrobeFilters ?? {},
    variant: "projects",
  });

  return data?.result ?? [];
};

export const downloadAllWardrobeFormsExternal = async (
  channelId: string,
  firstname: string,
  email: string
): Promise<CreateProjectResponse> => {
  const path = `/external/channel/${channelId}/wardrobeforms/download-all`;
  const { data } = await base.postAnonymous({
    path,
    data: { firstName: firstname, email: email },
    variant: "external",
  });
  return toResponse(data);
};

export const downloadAllWardrobeForms = async (
  channelId: string,
  channelWardrobeFilters: ChannelWardrobeFilters | null
): Promise<CreateProjectResponse> => {
  const path = `/channel/${channelId}/wardrobeforms/download-all`;
  const { data } = await base.post({
    path,
    data:
      channelWardrobeFilters != null
        ? {
            ...channelWardrobeFilters,
            roleName: channelWardrobeFilters.roles,
          }
        : {},
    variant: "projects",
  });
  return toResponse(data);
};

export const hideAllWardrobeForms = async (
  channelId: string,
  channelWardrobeFilters: ChannelWardrobeFilters | null
): Promise<WardrobeBulkActionResult[]> => {
  const path = `/channel/${channelId}/wardrobeforms/hide-all`;
  const { data } = await base.patch({
    path,
    data: channelWardrobeFilters ?? {},
    variant: "projects",
  });

  return data?.result ?? [];
};

export const shareSelectedWardrobeForms = async (
  channelId: string,
  selectedSubmissions: selectedChannelForm[]
): Promise<WardrobeBulkActionResult[]> => {
  const path = `/channel/${channelId}/wardrobeforms/share-selected`;
  const { data } = await base.patch({
    path,
    data: selectedSubmissions,
    variant: "projects",
  });

  return data?.result ?? [];
};

export const hideSelectedWardrobeForms = async (
  channelId: string,
  selectedSubmissions: selectedChannelForm[]
): Promise<WardrobeBulkActionResult[]> => {
  const path = `/channel/${channelId}/wardrobeforms/hide-selected`;
  const { data } = await base.patch({
    path,
    data: selectedSubmissions,
    variant: "projects",
  });

  return data?.result ?? [];
};

export const shareSelectedWardrobeForm = async (
  channelId: string,
  selectedSubmissionId: string,
  isShared: boolean,
  isCcSubmission: boolean
): Promise<WardrobeBulkActionResult[]> => {
  const path = `/channel/${channelId}/wardrobeform/${selectedSubmissionId}/sharestatus`;
  const { data } = await base.patch({
    path,
    data: {
      isShared: isShared,
      isCcSubmission: isCcSubmission,
    },
    variant: "projects",
  });

  return data?.result ? [data?.result] : [];
};

export const getwardrobeRoleDetailsForExternalAccess = async (
  channelId: string
): Promise<WardrobeRoleDetail[]> => {
  const path = `/external/channel/${channelId}/roledetails`;
  const { data } = await base.getAnonymous({
    path,
    variant: "external",
  });

  return data?.result ?? [];
};

export const getwardrobeSubmissionDetailsForExternalAccess = async (
  channelId: string,
  roleId: string
): Promise<WardrobeSubmissionDetail[]> => {
  const path = `/external/channel/${channelId}/role/${roleId}/submissionWrDetails`;
  const { data } = await base.getAnonymous({
    path,
    variant: "external",
  });

  return data?.result ?? [];
};

export const getWardrobeToPdfDetails = async (
  wardrobeFormId: string
): Promise<WardrobeToPdfDetails> => {
  const path = `/external/wardrobeform/${wardrobeFormId}/downloadDetails`;
  const { data } = await base.getAnonymous({
    path,
    variant: "external",
  });

  return toWardrobeToPdfDetails(data);
};

/********************************************** */
// CC Brief
/********************************************** */

export const createNewCommunityCastingBrief = async (
  request: CommunityCastingBriefRequest
): Promise<CreateProjectResponse> => {
  const path = `/communitycastingbrief`;
  const { data } = await base.post({
    path,
    data: request,
    variant: "roles",
  });

  return toResponse(data);
};

export const updateCcBriefForm = async (
  ccBriefId: string,
  formData: EditCcBriefFormRequest,
  formFileIdsToUpdate: string[]
): Promise<boolean> => {
  const path = `/communitycastingbrief/${ccBriefId}/form/update`;

  const request = {
    ...formData,
    dueByDate:
      formData.dueByDate !== null &&
      formData.dueByTimezone !== null &&
      formData.dueByTime !== null
        ? formatSelftapeDueDate(
            formData.dueByDate!,
            formData.dueByTimezone!.value,
            formData.dueByTime!!
          )
        : null,
    dueByTime:
      formData.dueByTime !== null && formData.dueByTimezone !== null
        ? formatSelftapeDueTime(
            formData.dueByTime!!,
            formData.dueByTimezone!.value
          )
        : null,
    dueByTimezone: formData.dueByTimezone?.value ?? null,
    artistDetailFields: formData.artistDetailFields.map((x) => x.key),
    additionalRequestsAndQuestions: formData.additionalRequestsAndQuestions.map(
      (x) => {
        return {
          id: x.id.includes("question") ? null : x.id,
          questionText: x.questionText,
          answerRequired: x.answerRequired,
        };
      }
    ),
    requestForDocuments: formData.requestForDocuments.map((x) => {
      return {
        id: x.id.includes("question") ? null : x.id,
        questionText: x.questionText,
        answerRequired: x.answerRequired,
      };
    }),
    requestForPhoto:
      formData.requestForPhoto === null
        ? null
        : {
            id: formData.requestForPhoto.id.includes("question")
              ? null
              : formData.requestForPhoto.id,
            questionText: formData.requestForPhoto.questionText,
            answerRequired: formData.requestForPhoto.answerRequired,
          },
    formalAcknowledgement:
      formData.formalAcknowledgement === null
        ? null
        : {
            id: formData.formalAcknowledgement.id.includes("question")
              ? null
              : formData.formalAcknowledgement.id,
            questionText: formData.formalAcknowledgement.questionText,
            answerRequired: formData.formalAcknowledgement.answerRequired,
          },
    formFileIdsToUpdate: formFileIdsToUpdate,
  };

  const { data } = await base.put({
    path,
    data: request,
    variant: "roles",
  });

  return data?.result ?? false;
};

export const updateCcBriefSubmissionNote = async (
  ccBriefSubmissionId: string,
  note: CcBriefSubmissionNote
): Promise<boolean> => {
  const path = `/communitycastingbrief/cc-brief-submission/${ccBriefSubmissionId}/addnote`;
  const { data } = await base.patch({
    path,
    data: note,
    variant: "roles",
  });

  return data?.result ?? false;
};

export const getCommunityCastingBriefs = async (
  projectId: string
): Promise<ProjectCcBriefInfo> => {
  const path = `/communitycastingbrief/project/${projectId}/ccbriefinfos`;
  const { data } = await base.get({
    path,
    variant: "roles",
  });

  return data?.result ?? null;
};

export const deleteCommunityCastingBrief = async (
  ccBriefId: string
): Promise<boolean> => {
  const path = `/communitycastingbrief/${ccBriefId}/remove`;
  const { data } = await base.deleteFile({
    path,
    variant: "roles",
  });

  return data?.result ?? false;
};

export const saveCcBriefUrlExtension = async (
  ccBriefId: string,
  urlExtension: string
): Promise<CcBriefSaveExtensionResponse> => {
  const path = `/communitycastingbrief/${ccBriefId}/form/urlextension/update`;
  const { data } = await base.patch({
    path,
    data: urlExtension,
    variant: "roles",
  });

  return data ?? { success: false, result: false };
};

export const getCcBriefDetails = async (
  ccBriefId: string
): Promise<CcBriefFormDetails> => {
  const path = `/communitycastingbrief/${ccBriefId}/details`;
  const { data } = await base.get({
    path,
    variant: "roles",
  });
  return toCcBriefFormDetails(data);
};

export const getCcBriefDetailsForExternal = async (
  ccBriefId: string | null,
  castingOfficeName: string | null,
  urlExtension: string | null
): Promise<CcBriefFormDetails> => {
  let path = "/communitycastingbrief";

  if (ccBriefId !== null) {
    path = `${path}/${ccBriefId}/details`;
  } else if (castingOfficeName !== null && urlExtension !== null) {
    path = `${path}/castingoffice/${castingOfficeName}/urlExtension/${urlExtension}/details`;
  }
  const { data } = await base.getAnonymous({
    path,
    variant: "external",
  });
  return toCcBriefFormDetails(data);
};

export const getCcBriefProjectAndRoleDetails = async (
  ccBriefId: string
): Promise<CcBriefProjectAndRoleDetails> => {
  const path = `/communitycastingbrief/${ccBriefId}/project-and-role-details`;
  const { data } = await base.getAnonymous({
    path,
    variant: "external",
  });
  return toCcBriefProjectAndRoleDetails(data);
};

export const getCcBriefProjectAndRoleNames = async (
  ccBriefId: string | null,
  castingOfficeName: string | null,
  urlExtension: string | null
): Promise<CcBriefProjectAndRoleName> => {
  let path = "/communitycastingbrief";

  if (ccBriefId !== null) {
    path = `${path}/${ccBriefId}/projectAndRoleName`;
  } else if (castingOfficeName !== null && urlExtension !== null) {
    path = `${path}/castingoffice/${castingOfficeName}/urlExtension/${urlExtension}/projectAndRoleName`;
  }

  const { data } = await base.getAnonymous({
    path,
    variant: "external",
  });
  return (
    data?.result ?? { projectName: "", roleName: "", castingOfficeName: "" }
  );
};

export const uploadCcBriefFormFile = async (
  ccBriefId: string,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `/communitycastingbrief/${ccBriefId}/uploadrolefile`;
  const { data } = await base.uploadFile({
    path,
    file,
    onProgress,
    cancelSource,
    variant: "roles",
  });

  return toResponse(data);
};

export const deleteCcBriefFormFile = async (
  ccBriefId: string,
  fileId: string
): Promise<CreateProjectResponse> => {
  const path = `/communitycastingbrief/${ccBriefId}/removefile/${fileId}`;
  const { data } = await base.deleteFile({
    path,
    variant: "roles",
  });
  return toResponse(data);
};

export const uploadCcBriefArtistSubmissionFile = async (
  ccBriefId: string,
  isProfileImage: boolean,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `/communitycastingbrief/${ccBriefId}/asrtistsubmission/uploadfile?isProfileImage=${isProfileImage}`;
  const response = await base.uploadFileAnonymous({
    path,
    file,
    onProgress,
    cancelSource,
    variant: "external",
  });

  return toResponse(response?.data);
};

export const deleteCcBriefArtistSubmissionFile = async (
  ccBriefId: string,
  isProfileImage: boolean,
  fileId: string
): Promise<CreateProjectResponse> => {
  const path = `/communitycastingbrief/${ccBriefId}/asrtistsubmission/removefile/${fileId}?isProfileImage=${isProfileImage}`;
  const { data } = await base.deleteFileAnonymous({
    path,
    variant: "external",
  });
  return toResponse(data);
};

export const uploadCcBriefFollowUpAuditionDocument = async (
  ccBriefId: string,
  isProfileImage: boolean,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `/communitycastingbrief/submission/${ccBriefId}/followup/audition/uploaddocument`;
  const response = await base.uploadFile({
    path,
    file,
    onProgress,
    cancelSource,
    variant: "roles",
  });

  return toResponse(response?.data);
};

export const removeCcBriefFollowUpAuditionDocument = async (
  ccBriefId: string,
  isProfileImage: boolean,
  fileId: string
): Promise<CreateProjectResponse> => {
  const path = `/communitycastingbrief/submission/${ccBriefId}/followup/audition/removedocument/${fileId}`;
  const { data } = await base.deleteFile({
    path,
    variant: "roles",
  });
  return toResponse(data);
};

export const addCcSubmissionFollowUpAudition = async (
  ccSubmissionId: string,
  auditionData: CcAuditionRequestForm,
  uploadedDocumentIds: string[]
): Promise<CreateProjectResponse> => {
  const path = `/communitycastingbrief/submission/${ccSubmissionId}/audition/request`;

  const request: CcAuditionRequest = {
    ...auditionData,
    auditionDate:
      auditionData.auditionDate !== null &&
      auditionData.timezone !== null &&
      auditionData.auditionTime !== null
        ? formatSelftapeDueDate(
            auditionData.auditionDate!,
            auditionData.timezone!.value,
            auditionData.auditionTime!!
          )
        : "",
    auditionTime:
      auditionData.auditionTime !== null && auditionData.timezone !== null
        ? formatSelftapeDueTime(
            auditionData.auditionTime!!,
            auditionData.timezone!.value
          )
        : "",
    timezone: auditionData.timezone?.value ?? "",
    followUpDocumentIds: uploadedDocumentIds,
  };

  const { data } = await base.post({
    path,
    data: request,
    variant: "roles",
  });

  return toResponse(data);
};

export const saveCcBriefSubmission = async (
  ccBriefId: string,
  request: CcBriefFormSubmissionRequest
): Promise<CreateProjectResponse> => {
  const path = `/communitycastingbrief/${ccBriefId}/artist/submission`;
  const { data } = await base.postAnonymous({
    path,
    data: request,
    variant: "external",
  });
  return toResponse(data);
};

export const getListOfCitiesForCcBriefSubmission = async (
  ccBriefId: string
): Promise<City[]> => {
  const path = `/communitycastingbrief/${ccBriefId}/cities`;
  const { data } = await base.getAnonymous({
    path,
    variant: "external",
  });
  return data?.result ?? [];
};

export const getListOfCitiesForCdTenant = async (): Promise<City[]> => {
  const path = `/cdtenant/cities`;
  const { data } = await base.get({
    path,
    variant: "projects",
  });
  return data?.result ?? [];
};
export const updateCcBriefSubmission = async (
  ccBriefId: string,
  ccBriefSubmissionId: string,
  request: CcBriefFormSubmissionRequest
): Promise<CreateProjectResponse> => {
  const path = `/communitycastingbrief/${ccBriefId}/artist/submission/${ccBriefSubmissionId}/update`;
  const { data } = await base.post({
    path,
    data: request,
    variant: "roles",
  });
  return toResponse(data);
};

export const getCcBriefSubmissionDetails = async (
  projectId: string,
  ccBriefSubmissionId: string
): Promise<CcBriefSubmissionDetail> => {
  const path = `/roles/CdStatusReports/${projectId}/ccbrief/submission/${ccBriefSubmissionId}`;
  const { data } = await base.get({
    path,
    variant: "roles",
  });
  return toCcBriefSubmissionDetails(data);
};

export const getCcBriefSubmissionDetailsForExternalView = async (
  ccBriefSubmissionId: string
): Promise<CcBriefSubmissionDetail> => {
  const path = `/CommunityCastingBrief/submission/${ccBriefSubmissionId}/details`;
  const { data } = await base.getAnonymous({
    path,
    variant: "external",
  });
  return toCcBriefSubmissionDetails(data);
};

export const getCcBriefSubmissionDetailsForDownload = async (
  ccBriefSubmissionId: string
): Promise<CcBriefSubmissionPdfDetail> => {
  const path = `/CommunityCastingBrief/submission/${ccBriefSubmissionId}`;
  const { data } = await base.getAnonymous({
    path,
    variant: "external",
  });
  return toCcBriefSubmissionPdfDetail(data);
};

export const uploadCcSubmissionFollowUpClip = async (
  ccBriefSubmissionId: string,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CcFollowupTemporaryClip | null> => {
  const path = `/communitycastingbrief/submission/${ccBriefSubmissionId}/followup/uploadclip`;
  // Because triggering the cancellation token returns a null response,
  // we cant automatically unpack the data node
  const response = await base.uploadFile({
    path,

    file,
    onProgress,
    cancelSource,
    variant: "external",
  });
  return response?.data?.result ?? null;
};

export const getCcSubmissionFollowUpTemporaryClips = async (
  ccBriefSubmissionId: string
): Promise<CcFollowupTemporaryClip[]> => {
  const path = `/communitycastingbrief/submission/${ccBriefSubmissionId}/followup/clips`;
  const { data } = await base.getAnonymous({
    path,
    variant: "external",
  });
  return data?.result ?? [];
};

export const getCcSubmissionFollowUpMessages = async (
  ccBriefSubmissionId: string
): Promise<CcSubmissionFollowUpMessage[]> => {
  const path = `/communitycastingbrief/submission/${ccBriefSubmissionId}/followup/messages`;
  const { data } = await base.getAnonymous({
    path,
    variant: "external",
  });
  return data?.result?.map((x: any) => toCcSubmissionFollowUpMessage(x)) ?? [];
};

export const addCcSubmissionFollowUpMessage = async (
  ccBriefSubmissionId: string,
  message: string,
  followUpUploadedFileIds: string[]
): Promise<CreateProjectResponse> => {
  const path = `/communitycastingbrief/submission/${ccBriefSubmissionId}/followup/message`;
  const { data } = await base.postAnonymous({
    path,
    data: {
      message: message,
      followUpUploadedFileIds: followUpUploadedFileIds,
    },
    variant: "external",
  });
  return toResponse(data);
};

export const addCcSubmissionFollowUpAuditionResponse = async (
  ccBriefSubmissionId: string,
  ccAuditionId: string,
  message: string,
  userConfirmed: boolean,
  followUpUploadedFileIds: string[]
): Promise<CreateProjectResponse> => {
  const path = `/communitycastingbrief/submission/${ccBriefSubmissionId}/followup/audition/${ccAuditionId}/respond`;
  const { data } = await base.postAnonymous({
    path,
    data: {
      message: message,
      auditionConfirmed: userConfirmed,
      followUpUploadedFileIds: followUpUploadedFileIds,
    },
    variant: "external",
  });
  return toResponse(data);
};

export const addCcSubmissionFollowUpRoleStatusResponse = async (
  ccBriefSubmissionId: string,
  message: string,
  userConfirmed: boolean,
  followUpUploadedFileIds: string[]
): Promise<CreateProjectResponse> => {
  const path = `/communitycastingbrief/submission/${ccBriefSubmissionId}/followup/rolestatus/respond`;
  const { data } = await base.postAnonymous({
    path,
    data: {
      message: message,
      roleStatusConfirmed: userConfirmed,
      followUpUploadedFileIds: followUpUploadedFileIds,
    },
    variant: "external",
  });
  return toResponse(data);
};

export const removeCcSubmissionFollowUpTemporaryClip = async (
  ccBriefSubmissionId: string,
  clipId: string
): Promise<boolean> => {
  const path = `/communitycastingbrief/submission/${ccBriefSubmissionId}/followup/clip/${clipId}/removeclip`;
  const { data } = await base.deleteFileAnonymous({
    path,
    variant: "external",
  });
  return data?.result ?? false;
};

export const renameCcSubmissionFollowUpTemporaryClip = async (
  ccBriefSubmissionId: string,
  clipId: string,
  newName: string
): Promise<boolean> => {
  const path = `/communitycastingbrief/submission/${ccBriefSubmissionId}/followup/clip/${clipId}/rename`;
  const { data } = await base.putAnonymous({
    path,
    data: newName,
    variant: "external",
  });
  return data?.result ?? false;
};

export const submitCcSubmissionFollowUpClipsToCastingOffice = async (
  ccBriefSubmissionId: string
): Promise<boolean> => {
  const path = `/communitycastingbrief/submission/${ccBriefSubmissionId}/followup/submitclipstocastingoffice`;
  const { data } = await base.postAnonymous({
    path,
    variant: "external",
  });
  return data?.result ?? false;
};

export const downloadAgencyAndArtistReport = async (): Promise<boolean> => {
  const path = `/users/account/artist/agency-artist-report`;
  const { data } = await base.get({
    path,
    variant: "users",
  });
  return data?.result ?? false;
};

export const uploadArtistBulkImportCsvFile = async (
  bulkJobId: string | null,
  file: any,
  onProgress: (percent: number) => void,
  cancelSource?: CancelTokenSource
): Promise<CreateProjectResponse> => {
  const path = `/bulkImport/agencyRoster/initValidation${
    bulkJobId !== "" && bulkJobId !== null ? "?jobId=" + bulkJobId : ""
  }`;
  const { data } = await base.uploadFile({
    path,
    file,
    onProgress,
    cancelSource,
    variant: "users",
  });

  return toResponse(data);
};

export const getBulkImportJobDetail = async (
  bulkJobId: string
): Promise<BulkImportJobDetail | null> => {
  const path = `/bulkImport/${bulkJobId}`;
  const { data } = await base.get({
    path,
    variant: "users",
  });
  return data?.result;
};

export const getBulkImportJobValidationMessages = async (
  bulkJobId: string
): Promise<BulkImportJobValidationMessage[]> => {
  const path = `/bulkImport/${bulkJobId}/validation/messages`;
  const { data } = await base.get({
    path,
    variant: "users",
  });
  return (
    data?.result?.map((x: BulkImportJobValidationMessage) => {
      return {
        ...x,
        id: x.reference,
      };
    }) ?? []
  );
};

export const startImportArtists = async (
  bulkJobId: string,
  agencyId: string
): Promise<BulkImportJobDetail | null> => {
  const path = `/bulkImport/${bulkJobId}/agencyRoster/${agencyId}/initImport`;
  const { data } = await base.post({
    path,
    data: {},
    variant: "users",
  });
  return data?.result;
};

export const getBulkImportAraHeadshotRequest = async (
  bulkJobId: string
): Promise<BulkImportHeadshotDetail | null> => {
  const path = `/bulkImport/${bulkJobId}/agencyRoster/headshotRequest`;
  const { data } = await base.get({
    path,
    variant: "users",
  });
  return data?.result ?? null;
};

export const removeBulkImportPostAction = async (
  bulkJobId: string,
  postActionId: string
): Promise<boolean> => {
  const path = `/bulkImport/${bulkJobId}/postAction/${postActionId}`;
  const { data } = await base.deleteFile({
    path,
    variant: "users",
  });
  return data?.result ?? false;
};

export const removeBulkImportJob = async (
  bulkJobId: string
): Promise<boolean> => {
  const path = `/bulkImport/${bulkJobId}`;
  const { data } = await base.deleteFile({
    path,
    variant: "users",
  });
  return data?.result ?? false;
};

export const chaseSelfTapeForRoles = async (
  projectId: string,
  roleIds: string[],
  message: string
): Promise<ListResponse> => {
  const path = `/roles/sessions/${projectId}/self-tape/chaseSelfTape`;
  const { data } = await base.post({
    path,
    data: { message: message, selfTaperequestIds: roleIds },
    variant: "roles",
  });
  return toListResponse(data);
};

export const nudgeRequestSelfTapeForRoles = async (
  projectId: string,
  roleIds: string[],
  message: string
): Promise<ListResponse> => {
  const path = `/roles/sessions/${projectId}/self-tape/nudgeRequest`;
  const { data } = await base.post({
    path,
    data: { message: message, selfTaperequestIds: roleIds },
    variant: "roles",
  });
  return toListResponse(data);
};

export const messageAgentsSelfTapeForRoles = async (
  projectId: string,
  roleIds: string[],
  message: string,
  messageFilter: SelfTapeMessageFilter
): Promise<ListResponse> => {
  const path = `/roles/sessions/${projectId}/self-tape/messageAgents`;

  var messageRequest = {
    Message: message,
    SelfTaperequestIds: roleIds,
    SelfTapeMessageFilter: {
      Requested: messageFilter.requested.checked,
      Confirm: messageFilter.confirmed.checked,
      Recieved: messageFilter.recieved.checked,
      Decline: messageFilter.decline.checked,
    },
  };
  const { data } = await base.post({
    path,
    data: messageRequest,
    variant: "roles",
  });
  return toListResponse(data);
};

export const chaseIndividualTape = async (
  projectId: string,
  selfTapeParticipantId: string,
  message: string
): Promise<ListResponse> => {
  const path = `/roles/sessions/${projectId}/self-tape/chaseSelfTapeIndividual`;
  const { data } = await base.post({
    path,
    data: { message: message, selfTapeParticipantId: selfTapeParticipantId },
    variant: "roles",
  });
  return toListResponse(data);
};


export const getEnableSessionType = async (
  projectId: string,
  roleId:string,
  artistProfileId:string
): Promise<DirectoryArtistSelftapeStatus> => {
  const path = `/roles/sessions/${projectId}/role/${roleId}/artist/${artistProfileId}/self-tape/submissionAndSessionType`;
  const { data } = await base.get({
    path,
    variant: "roles",
  });

  return toRoleSubmissionsForRoleArtist(data?.result);
};



export const showHideArtistCredit = async (
  profileId: string | null,
  creditId: string,
  showCredit: boolean
): Promise<Credit | null> => {
  const path = `/users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/credit/${creditId}/show/${showCredit}`;
  const { data } = await base.patch({
    path,
    variant: "users",
  });
  return data?.result;
};

export const getSelfTapeArtists = async (
  projectId: string,
  selfTapeRequestId: string,
  status: string,
  page: number,
  pageSize: number
): Promise<SelfTapePanelAsgnedArtist> => {
  const path = `/roles/sessions/${projectId}/self-tape/${selfTapeRequestId}/selfTapeArtists/${status}?page=${page}&pageSize=${pageSize}`;
  const { data } = await base.get({
    path,
    variant: "roles",
  });
  const result: SelfTapePanelAsgnedArtist = {
    totalRowCount: data?.result?.totalRowCount,
    pageSize: data?.result?.pageSize,
    numberOfPages: data?.result?.numberOfPages,
    currentPageNumber: data?.result?.currentPageNumber,
    selfTapeArtists: data.result.data.map(toProjectSelfTapeRequestArtist),
  };
  return result;
};

export const getArtistsHeadShots = async (
  projectId: string,
  roleIds: string,
  profileId: string
): Promise<ArtistProfileImage[]> => {
  const path = `users/artistprofile${
    profileId != null ? `/${profileId}` : ""
  }/headShots`;
  const { data } = await base.get({
    path,
    variant: "users",
  });
  return data?.result;
};

export const saveAlternativeHeadShot = async (
  projectId: string,
  roleIds: string,
  profileId: string
): Promise<ArtistProfileImage[]> => {
  const path = `users/artistprofile${profileId != null ? `/${profileId}` : ""}${
    roleIds != null ? `/${roleIds}` : ""
  }/alternativeHeadShots`;
  const { data } = await base.post({
    path,
    variant: "users",
  });
  return data?.result;
};

/********************************************** */
// wardrobe template
/********************************************** */
export const createwarDrobeTemplateSave = async (
  values: any
): Promise<CreateProjectResponse> => {
  const path = `wardrobeTemplate/save`;
  const { data } = await base.post({
    path,

    data: values,
    variant: "roles",
  });
  return toResponse(data);
};

export const removeWarDrobeTemplate = async (
  wardrobeTemplateID: string
): Promise<CreateProjectResponse> => {
  const path = `wardrobeTemplate/${wardrobeTemplateID}/delete`;
  const { data } = await base.post({
    path,

    variant: "roles",
  });
  return toResponse(data);
};
export const duplicateWarDrobeTemplate = async (
  wardrobeTemplateID: string
): Promise<CreateProjectResponse> => {
  const path = `wardrobeTemplate/${wardrobeTemplateID}/duplicate`;
  const { data } = await base.post({
    path,

    variant: "roles",
  });
  return toResponse(data);
};

export const setDefaultWardrobeTemplate = async (
  wardrobeTemplateID: string
): Promise<CreateProjectResponse> => {
  const path = `wardrobeTemplate/${wardrobeTemplateID}/setDefault`;
  const { data } = await base.post({
    path,

    variant: "roles",
  });
  return toResponse(data);
};

export const copyWardrobeFormTemplate = async (
  projectId: string,
  wardrobeTemplateId: string
): Promise<WardrobeForm | null> => {
  const path = `wardrobeTemplate/${wardrobeTemplateId}/copy`;
  const { data } = await base.get({ path, variant: "roles" });
  return data?.result ?? null;
};

export const getWarDrobeTemplates = async (
  cdTeanantId: string
): Promise<WardrobeTemplateResponse> => {
  const path = `wardrobeTemplate/cdTenant`;
  const { data } = await base.get({ path, variant: "roles" });

  return toWarDrobeTemplateResponse(data);
};

export const getWarDrobeTemplate = async (
  wardrobeTemplateId: string
): Promise<WardrobeTemplateResponse> => {
  console.log("Get WR Templates");

  const path = `wardrobeTemplate/${wardrobeTemplateId}`;
  const { data } = await base.get({ path, variant: "roles" });

  console.log("data", data);

  return toWarDrobeTemplateResponse(data);
};

export const getDefaultWardrobeTemplates =
  async (): Promise<RoleWardrobeForm> => {
    const path = `wardrobeTemplate/default`;
    const { data } = await base.get({ path, variant: "roles" });

    return data?.result ?? null;
  };

export const getWardrobeTemplate = async (
  wardrobeTemplateId: string
): Promise<WardrobeformTemplateByIdResponse> => {
  const path = `wardrobeTemplate/${wardrobeTemplateId}`;
  const { data } = await base.get({ path, variant: "roles" });

  console.log("data", data);

  return toWarDrobeTemplateByIdResponse(data);
};
